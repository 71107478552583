import { createStore } from 'vuex';

const store = createStore({
  state: {
    previousRoute: null, // String to store the previous page name
    routeOrigin: null, // String to store the starting page of the route
    applicationJSON: {}, // Object to store Application JSON (resume, cover letter, match etc) for each applicationId
    applicationList: {}, // Object to store application list on application history screen
    resumePreferences: {}, //Object to store resume presentation config
    userDetails: {}, //Object to store user details
    viewedRecommendations: true, //Boolean to store whether the user has viewed recommendations tab; true by default unless initialized to false
  },
  mutations: {
    //Set the previous page name
    setPreviousRoute(state, routeName) {
      state.previousRoute = routeName;
    },
    //Set the route origin page name
    setRouteOrigin(state, routeName) {
      state.routeOrigin = routeName;
    },
    //Store the application details viz resume, match, cover letter
    setApplicationJSON(state, { version, response }) {
      // Create application ID entry if it doesn't exist
      console.log('VueX Store: Inside setApplicationJSON: Version: ' + version + '\n' + JSON.stringify(response));
      if (!state.applicationJSON[version]) {
        console.log('Application version not present. Creating store for application ID');
        state.applicationJSON[version] = {};
      }
      // Set the api Response for the specific version
      state.applicationJSON[version] = response;
    },
    //Store the list of applications
    setApplicationList(state, { application_list }) {
      // Create application ID entry if it doesn't exist
      console.log('VueX Store: Inside set application list: ' + JSON.stringify(application_list));
      if (!state.applicationList) {
        console.log('Application version not present. Creating store for application ID');
        state.applicationList = {};
      }
      // Set the api Response for the specific version
      state.applicationList = application_list;
    },
    //Update resume
    updateResume(state, { version, resume }) {
      console.log('VueX Store: Updating resume ' + version + 'to:\n' + JSON.stringify(resume));
      // Set the api Response for the specific version
      state.applicationJSON[version].resume = resume;
    },
    //Update cover letter
    updateCoverLetter(state, { version, cover_letter }) {
      // Set the api Response for the specific version
      console.log('VueX Store: Updating cover letter ' + version + 'to:\n' + JSON.stringify(cover_letter));
      state.applicationJSON[version].cover_letter = cover_letter;
      console.log('Vue store..application json after updating cover letter:\n' + JSON.stringify(state.applicationJSON[version]));
    },
    // Delete application details
    deleteApplicationJSON(state) {
      console.log('VueX Store: delete application JSON');
      state.applicationJSON = {};
    },
    // Store the details from the response viz
    // is_authenticated, existing_user,user_id, user_email, user_first_name, 
    // user_last_name, check_terms, pending_terms, terms_content, terms_id, new_user_terms_summary, existing_user_terms_summary
    setUserDetails(state, { userDetails }) {
      console.log('VueX Store: Setting User Details: ' + JSON.stringify(userDetails));
      state.userDetails = userDetails;
    },
    //Update user details based on available data
    updateUserDetails(state, { userDetails }) {
      console.log('VueX updating user details using the following JSON: ' + JSON.stringify(userDetails));
      if (userDetails.first_name) {
        state.userDetails.user_first_name = userDetails.user_first_name;
      }
      if (userDetails.last_name) {
        state.userDetails.user_last_name = userDetails.user_last_name;
      }
      if (userDetails.hasOwnProperty('existing_user')) {
        state.userDetails.existing_user = userDetails.existing_user;
      }
      if (userDetails.hasOwnProperty('pending_terms')) {
        state.userDetails.pending_terms = userDetails.pending_terms;
        //Additionally save the terms details if pending_terms =true
        if (userDetails.pending_terms) {
          state.userDetails.terms_id = userDetails.terms_id;
          state.userDetails.terms_content = userDetails.terms_content;
          state.userDetails.new_user_terms_summary = userDetails.new_user_terms_summary;
          state.userDetails.existing_user_terms_summary = userDetails.existing_user_terms_summary;
        }
      }
      if (userDetails.hasOwnProperty('check_terms')) {
        state.userDetails.check_terms = userDetails.check_terms;
      }
    },

    //Set whether the user has previously generated an application
    updateUserApplicationHistory(state, { hasGeneratedApplication }) {
      console.log('VueX: User application history updated to ' + hasGeneratedApplication);
      state.userDetails.has_generated_application = hasGeneratedApplication;
    },
    // ------------Viewed Recommendations----------
    updateViewedRecommendations(state, viewed) {
      console.log('VueX: Viewed Recommendations updated to ' + viewed);
      state.viewedRecommendations = viewed;
    },
    // ------- Resume Preferences ----------
    //Save the user's resume preferences
    setResumePreferences(state, { config }) {
      // Create resume preferences entry if it doesn't exist
      console.log('VueX Store: setting resume preferences to: ' + JSON.stringify(config));
      if (!state.resumePreferences) {
        console.log('Resume preferences not found in Vue store. Crating one..');
        state.resumePreferences = {};
      }
      // Set the resume config
      state.resumePreferences = config;
    },
    // Update the user's resume template preferences only
    updateTemplatePreference(state, template) {
      console.log('VueX store: update template preference to: ' + template);
      state.resumePreferences.template = template;
    },
    // Update the user's resume color preferences only
    updateColorPreference(state, color) {
      console.log('VueX store: update color preference to: ' + color);
      if (state.resumePreferences) {
        state.resumePreferences.color = color;
      } else {
        console.log('Resume preferences not found in Vue store');
      }
    },
    // Update the user's resume layout preferences only
    updateLayoutPreference(state, layout) {
      console.log('VueX store: update layout preference to: ' + layout);
      if (state.resumePreferences) {
        state.resumePreferences.layout = layout;
      }
    },
    // Update the user's resume icon display preferences only
    updateIconPreference(state, showIcon) {
      console.log('VueX store: update icon preference to: ' + showIcon);
      if (state.resumePreferences) {
        state.resumePreferences.showIcon = showIcon;
      }
    },
    // Update the user's resume font preferences only
    updateFontPreference(state, font) {
      console.log('VueX store: update font preference to: ' + font);
      if (state.resumePreferences) {
        state.resumePreferences.font = font;
      }
    },
    // Update the user's resume font size preferences only
    updateFontSizePreference(state, fontSize) {
      console.log('VueX store: update font size preference to: ' + fontSize);
      if (state.resumePreferences) {
        state.resumePreferences.fontSize = fontSize;
      }
    },
    // Update the user's resume density preferences only
    updateDensityPreference(state, density) {
      console.log('VueX store: update density preference to: ' + density);
      if (state.resumePreferences) {
        state.resumePreferences.density = density;
      }
    },
    // Update the user's section column count preferences only
    updateColumnPreference(state, columns) {
      console.log('VueX store: update column count preference to: ' + columns);
      if (state.resumePreferences) {
        state.resumePreferences.columns = columns;
      }
    },
    // Update the user's resume page size preferences only
    updatePageSizePreference(state, pageSize) {
      console.log('VueX store: update page size preference to: ' + pageSize);
      if (state.resumePreferences) {
        state.resumePreferences.pageSize = pageSize;
      }
    },
    // Update the user's section name preferences only
    updateSectionNamePreference(state, sectionNames) {
      console.log('VueX store: updating section name preference to: ' + JSON.stringify(sectionNames));
      if (state.resumePreferences) {
        state.resumePreferences.sectionNames = sectionNames;
      }
    },
  },
  getters: {
    //return the previous page name
    previousRoute(state) {
      return state.previousRoute;
    },
    //return the route origin page name
    routeOrigin(state) {
      return state.routeOrigin;
    },
    hasViewedRecommendations(state) {
      console.log('VueX: Has viewed recommendations: ' + state.viewedRecommendations);
      return state.viewedRecommendations;
    },
    getApplicationJSONByVersion: (state) => (version) => {
      return state.applicationJSON[version];
    },
    getApplicationList: (state) => {
      return state.applicationList;
    },
    getResumePreferences: (state) => {
      return state.resumePreferences;
    },
    //get all user details except access token
    getUserDetails: (state) => {
      console.log('VueX: Getting user details for user id: ' + state.userDetails.user_id);
      return {
        existing_user: state.userDetails.existing_user,
        oauth_provider: state.userDetails.oauth_provider,
        user_id: state.userDetails.user_id,
        user_email: state.userDetails.user_email,
        user_first_name: state.userDetails.user_first_name,
        user_last_name: state.userDetails.user_last_name,
        pending_terms: state.userDetails.pending_terms,
        terms_id: state.userDetails.terms_id,
        terms_content: state.userDetails.terms_content,
        new_user_terms_summary: state.userDetails.new_user_terms_summary,
        existing_user_terms_summary: state.userDetails.existing_user_terms_summary,
        check_terms: state.userDetails.check_terms,
      };
    },
  },
});

export default store;   