<template>
  <div class="Scaffold CoverLetterWidth">
    <div class="Background">
      <div class="ContentContainer">
        <div class="EditorPanel">
          <div class="EditorScaffold">
            <!-- Page Title -->
            <div class="StickyContainer BorderBottom">
              <div class="TitleBar">
                <div class="TextHeading1">{{ LABEL_APPLICATION_HISTORY }}</div>
              </div>
            </div>
            <!-- Filter Panel -->
            <div class="FilterPanel">
              <!-- Header Row -->
              <div class="FilterHeaderRow">
                <!-- Filter Panel toggle button -->
                <div class="FilterRowButton">
                  <themed-button componentId="filter-toggle" button-type="secondary" width="fit"
                  :label = BUTTON_LABEL_FILTERS
                  @buttonClick="toggleFilterPanel"/>
                </div>
                <!-- Filter Pill: Status -->
                <div class="FilterPill" v-if="filters.status!==undefined && filters.status!==''"> 
                  <div>{{ LABEL_STATUS + ': ' + filters.status?.toUpperCase() }}</div>
                  <div class="DescriptionRowIconButton">
                    <themed-button componentId="status" button-type="icon" 
                      icon="remove"
                      tooltip-text="Clear Filter"
                      @buttonClick="clearFilter"/>
                  </div>
                </div>
                <!-- Filter Pill: Bookmarked -->
                <div class="FilterPill" v-if="filters.bookmarked!==undefined && filters.bookmarked!==''"> 
                  <div>{{ LABEL_BOOKMARK + ': ' + ( filters.bookmarked?.toUpperCase() ) }}</div>
                  <div class="DescriptionRowIconButton">
                    <themed-button componentId="bookmarked" button-type="icon" 
                      icon="remove"
                      tooltip-text="Clear Filter"
                      @buttonClick="clearFilter"/>
                  </div>
                </div>
                <!-- Filter Pill: Company -->
                <div class="FilterPill" v-if="filters.company!==undefined && filters.company!==''"> 
                  <div>{{ LABEL_COMPANY + ': ' + filters.company.toUpperCase() }}</div>
                  <div class="DescriptionRowIconButton">
                    <themed-button componentId="company" button-type="icon" 
                      icon="remove"
                      tooltip-text="Clear Filter"
                      @buttonClick="clearFilter"/>
                  </div>
                </div>
                <!-- Filter Pill: Position -->
                <div class="FilterPill" v-if="filters.position!==undefined && filters.position!==''"> 
                  <div>{{ LABEL_POSITION + ': ' + filters.position.toUpperCase()  }}</div>
                  <div class="DescriptionRowIconButton">
                    <themed-button componentId="position" button-type="icon" 
                      icon="remove"
                      tooltip-text="Clear Filter"
                      @buttonClick="clearFilter"/>
                  </div>
                </div>
                <!-- Filter Pill: Date From -->
                <div class="FilterPill" v-if="filters.date_from!==undefined && filters.date_from!==''"> 
                  <div>{{ LABEL_DATE_FROM + ': ' + formatDate(filters.date_from) }}</div>
                  <div class="DescriptionRowIconButton">
                    <themed-button componentId="date_from" button-type="icon" 
                      icon="remove"
                      tooltip-text="Clear Filter"
                      @buttonClick="clearFilter"/>
                  </div>
                </div>
                <!-- Filter Pill: Date To -->
                <div class="FilterPill" v-if="filters.date_to!==undefined && filters.date_to!==''"> 
                  <div>{{ LABEL_DATE_TO + ': ' + formatDate(filters.date_to) }}</div>
                  <div class="DescriptionRowIconButton">
                    <themed-button componentId="date_to" button-type="icon" 
                      icon="remove"
                      tooltip-text="Clear Filter"
                      @buttonClick="clearFilter"/>
                  </div>
                </div>
                <!-- No Filter Message -->
                 <div class="FilterRowButton">
                   <div class="TextMini DescriptionColor" v-if="!filtersApplied"> {{ MESSAGE_NO_FILTERS }}</div>
                 </div>
              </div>
              <!-- Filters Section -->
              <div class="FilterSection" :class="{'hidden': !filterPanelExpanded}">
                <div class="FilterRowLine JustifyRight">
                  <div class="InputRowIconButton">
                    <themed-button componentId="filter-toggle" button-type="icon" 
                      icon="remove"
                      :tooltip-text=BUTTON_LABEL_HIDE_FILTER
                      @buttonClick="toggleFilterPanel"/>
                  </div>
                </div>
                <!-- Row 1 -->
                <div class="FilterRow">
                  <!-- Status Filter -->
                  <div class="FilterRowColumn">
                    <div class="FilterRowLine">
                        <div class="HorizontalGroup NoHorizontalSpace">
                          <label class="TextMini DescriptionColor" for="filter_status">{{LABEL_STATUS.toUpperCase()}}</label>
                      </div>
                    </div>
                    <div class="FilterRowLine">
                      <Selector 
                          :default-value=defaultApplicationStatus
                          :options="statusFilterOptions" type="text"
                          :tooltip-text="TOOLTIP_UPDATE_STATUS"
                          :show-outline=true
                          component-id="filter_status"
                          @option-selected="updateStatusFilter" />
                    </div>
                  </div>
                  <!-- Bookmark Filter -->
                  <div class="FilterRowColumn">
                    <div class="FilterRowLine">
                        <div class="HorizontalGroup NoHorizontalSpace">
                          <label class="TextMini DescriptionColor" for="filter_bookmark">{{LABEL_BOOKMARK.toUpperCase()}}</label>
                      </div>
                    </div>
                    <div class="FilterRowLine">
                      <Selector 
                          :default-value=defaultBookmarkedStatus 
                          :options="bookmarkOptions" 
                          type="text"
                          :tooltip-text="TOOLTIP_UPDATE_BOOKMARK"
                          :show-outline=true
                          component-id="filter_bookmark"
                          @option-selected="updateBookmarkFilter" />
                    </div>
                  </div>
                </div>
                <!-- Row 2 -->
                <div class="FilterRow" >
                  <!-- Position Filter -->
                  <div class="FilterRowColumn">
                    <div class="FilterRowLine">
                        <div class="HorizontalGroup NoHorizontalSpace">
                          <label class="TextMini DescriptionColor" for="filter_position">{{LABEL_POSITION.toUpperCase()}}</label>
                      </div>
                    </div> 
                    <div class="FilterRowLine">
                      <input class="ThemedInput" type="text" id="filter_position" 
                        :disabled="loading" 
                        v-model="filters.position" 
                        :placeHolder="PLACE_HOLDER_FILTER_POSITION"
                        @input="debounceFilter">
                    </div> 
                  </div> 
                  <!-- Company Filter -->
                  <div class="FilterRowColumn">
                    <div class="FilterRowLine">
                        <div class="HorizontalGroup NoHorizontalSpace">
                          <label class="TextMini DescriptionColor" for="filter_position">{{LABEL_COMPANY.toUpperCase()}}</label>
                      </div>
                    </div> 
                    <div class="FilterRowLine">
                      <input class="ThemedInput" type="text" id="filter_position" 
                        :disabled="loading" 
                        v-model="filters.company" 
                        :placeHolder="PLACE_HOLDER_FILTER_COMPANY"
                        @input="debounceFilter">
                    </div> 
                  </div>         
                </div>
                <!-- Row 3 -->
                <div class="FilterRow">
                  <!-- Date From Filter -->
                  <div class="FilterRowColumn">
                    <div class="FilterRowLine">
                        <div class="HorizontalGroup NoHorizontalSpace">
                          <label class="TextMini DescriptionColor" for="filter_date_from">{{LABEL_DATE_FROM.toUpperCase()}}</label>
                      </div>
                    </div> 
                    <div class="FilterRowLine">
                      <input class="ThemedInput" type="date" id="filter_date_from" 
                        :disabled="loading" 
                        v-model="filters.date_from" 
                        @input="handleDateChange">
                    </div> 
                  </div> 
                  <!-- Date ToFilter -->
                  <div class="FilterRowColumn">
                    <div class="FilterRowLine">
                        <div class="HorizontalGroup NoHorizontalSpace">
                          <label class="TextMini DescriptionColor" for="filter_date_to">{{LABEL_DATE_TO.toUpperCase()}}</label>
                      </div>
                    </div> 
                    <div class="FilterRowLine">
                      <input class="ThemedInput" type="date" id="filter_date_to" 
                        :disabled="loading" 
                        v-model="filters.date_to" 
                        @input="handleDateChange">
                    </div> 
                  </div> 
                </div>

              </div> 
            </div>
            <!-- Applications List -->
            <div class="PanelContainer">
              
                <!-- No Applications -->
                <div class="EmptyResultPanel" v-if="noApplicationsFound">
                  <div class="TextDescription MarginBottom">{{ MESSAGE_NO_APPLICATION_HISTORY }}</div>
                  <div class="TextDescription">{{ MESSAGE_GET_STARTED }}</div>
                </div>
                <!-- No Results -->
                <div class="EmptyResultPanel" v-else-if="applications.length === 0 && !loading && filtersApplied">{{ MESSAGE_NO_MATCHING_APPLICATIONS }}</div>
                <!-- Loading State -->
                <div v-else-if="applications.length === 0 && loading">{{ MESSAGE_LOADING }}</div>
                <!-- Results Available -->
                <div v-else
                  v-for="application in applications"
                  :key="application.id"
                  class="TableRowCard"
                  :class="{ active: selectedApplication?.id === application.id }"
                  @click="selectApplication(application)"
                >
                  <div class="TablePanel">
                    <div class="TablePanelSection BorderRight" :class="application.status">
                      <div class="HorizontalGroup">
                        <!-- Bookmark Icon -->
                        <div class="TableRowIcon">
                          <themed-button componentId="bookmark" button-type="icon" 
                            :icon="application.bookmarked ? 'bookmark-filled' : 'bookmark-empty'"
                            :tooltip-text = "application.bookmarked ? TOOLTIP_UNBOOKMARK : TOOLTIP_BOOKMARK"
                            align="left"
                            @buttonClick="bookmarkApplication(application.id)"/>
                        </div>
                        <div class="TextParagraph TableRowWidth TextTruncate">{{ application.position_applied }}</div>
                      </div>
                      <div class="HorizontalGroup" v-if="application.company_applied">
                        <div class="TableRowIcon"></div>
                        <div class="TextParagraph TableRowWidth TextTruncate">{{ application.company_applied }}</div>
                      </div>
                      <div class="HorizontalGroup">
                        <div class="TableRowIcon"></div>
                        <div class="TextMini DescriptionColor">{{ formatDate(application.created_at) }}</div>
                      </div>
                    </div>
                    <div class="TableActionSection">
                      <Selector 
                        :default-value="application.status" 
                        :options="statusOptions" type="text"
                        :tooltip-text="TOOLTIP_UPDATE_STATUS"
                        tooltip-alignment="right"
                        :show-outline=true
                        :component-id="'status-' + application.id"
                        @option-selected="updateApplicationStatus" />
                    </div>
                  </div>
                </div>
                <!-- Pagination -->
                <div class="pagination" v-if="totalCount > 0">
                  <div class="IconContainerMini">
                    <themed-button componentId="previous" button-type="icon"
                    icon = "previous"
                    :tooltip-text = BUTTON_LABEL_PREVIOUS
                    :is-disabled="currentPage === 1"
                    @buttonClick="changePage(currentPage - 1)"/>
                  </div>
                  <span>{{ LABEL_PAGE + ' ' + currentPage + ' ' + LABEL_OF + ' ' + totalPages }}</span>
                  <div class="IconContainerMini">
                    <themed-button componentId="next" button-type="icon"
                    icon = "next"
                    :tooltip-text = BUTTON_LABEL_NEXT
                    :is-disabled="!hasMore"
                    @buttonClick="changePage(currentPage + 1)"/>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
        <!-- Application Preview -->
        <div class="PreviewPanel" >
          <div class="TopMenuContainer BorderBottom" ref="viewResumeButton">
            <!-- Top Menu Row -->
            <div class="TopMenuRow">
              <!-- View Resume Button -->
              <div class="PageHeaderTextButton">
                <themed-button componentId="new" button-type="secondary" width="fit"
                :label = BUTTON_LABEL_NEW_APPLICATION
                @buttonClick="navigateHome"/>
              </div>
              <div class="PageHeaderTextButton">
                <themed-button componentId="view_resume" button-type="primary" width="fit"
                :label = BUTTON_LABEL_VIEW_RESUME
                :is-disabled=!selectedApplicationDetails
                @buttonClick="handleButtonClick"/>
              </div>
            </div>
          </div>
          <job-match-preview v-if="selectedApplicationDetails"
            :job_title = selectedApplicationDetails.position_applied
            :company_name = selectedApplicationDetails.company_applied
            :job_description= selectedApplicationDetails.job_description_applied
            :application_json= selectedApplicationDetails
            :current_tab= current_tab
            parent_screen="history"
            @buttonClick="handleButtonClick"
          />
          <!-- Empty State -->
          <div class="EmptyStateContainer" v-else>
            <div class="EmptyState">
              <Icon iconName="sorry"/>
              <div class="TextDescription">{{ noApplicationsFound ? MESSAGE_GET_STARTED : MESSAGE_EMPTY_STATE }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import axios from 'axios';
  import debounce from 'lodash/debounce';
  import JobMatchPreview from './content_components/JobMatchPreview.vue'; 
  import ThemedButton from './ui_controls/ThemedButton.vue';
  import Selector from './ui_controls/Selector.vue';
  import Bar from './ui_controls/Bar.vue';
  import Icon from './ui_controls/Icon.vue';
  import { userAuthentication } from './composables/userAuthentication';
  import { toastHandler } from './composables/toastHandler';
  
  export default {
    name: 'ApplicationHistory',
    components: {
      JobMatchPreview,
      ThemedButton,
      Bar,
      Selector,
      Icon
    },
    data() {
      const { userDetails, checkAuthentication } = userAuthentication();
      const { showToast, removeToast } = toastHandler();
      return {
        auth: { userDetails, checkAuthentication }, // for handling authentication
        toast: { showToast, removeToast }, // for handling toast messages
        applications: [],
        selectedApplication: null,
        selectedApplicationDetails: null,
        loading: false, // whether the application list is being loaded
        loadingToast: null, //the id of the loading toast message
        //pagination related
        currentPage: 1,
        current_tab: 'job_preview',
        totalCount: 0,
        hasMore: false,
        //filters
        filters: {
          position: '',
          company: '',
          status: '',
          bookmarked: '',
          date_from: '',
          date_to: '',
        },
        statusOptions: [
          {label: 'Applied', value: 'applied'},
          {label: 'Interviewing', value: 'interviewing'},
          {label: 'Offer', value: 'hired'},
          {label: 'Rejected', value: 'rejected'},
        ],
        defaultApplicationStatus: '',
        bookmarkOptions: [
          {label: 'Any Status', value: ''},
          {label: 'Yes', value: 'true'},
          {label: 'No', value: 'false'},
        ],
        defaultBookmarkedStatus: '',
        // Filter Panel
        filterPanelExpanded: false,
        //constants
        LABEL_APPLICATION_HISTORY: 'Application History',
        LABEL_PAGE: 'Page',
        LABEL_OF: 'of',
        LABEL_COMPANY: 'Company',
        LABEL_POSITION: 'Position',
        LABEL_STATUS: 'Status',
        LABEL_DATE_FROM: 'From',
        LABEL_DATE_TO: 'To',
        LABEL_BOOKMARK: 'Bookmarked',
        BUTTON_LABEL_VIEW_RESUME: 'View Resume',
        BUTTON_LABEL_NEW_APPLICATION: 'New Application',
        BUTTON_LABEL_PREVIOUS: 'Previous',
        BUTTON_LABEL_NEXT: 'Next',
        BUTTON_LABEL_FILTERS: 'Filters',
        BUTTON_LABEL_HIDE_FILTER: 'Hide Filters',
        PLACE_HOLDER_FILTER_POSITION: 'Filter by position',
        PLACE_HOLDER_FILTER_COMPANY: 'Filter by company',
        TOOLTIP_BOOKMARK: 'Bookmark Resume',
        TOOLTIP_UNBOOKMARK: 'Remove Bookmark',
        TOOLTIP_UPDATE_STATUS: 'Filter By Application Status',
        TOOLTIP_UPDATE_BOOKMARK: 'Filter By Bookmark Status',
        MESSAGE_NO_FILTERS: 'No filters applied',
        MESSAGE_LOADING: 'Fetching your application history...',
        MESSAGE_SAVED: 'Application successfully updated',
        MESSAGE_UPDATE_FAILED: 'We are sorry! We could not update your application at this time',
        MESSAGE_NO_APPLICATION_HISTORY: "You have not tailored any resumes yet.",
        MESSAGE_GET_STARTED: "Click 'New Application' to get started.",
        MESSAGE_NO_MATCHING_APPLICATIONS: 'No applications match these criteria. Please try adjusting your filters.',
        MESSAGE_EMPTY_STATE: 'Select an application to view its details',
      };
    },
  
    computed: {
      ...mapGetters(['getUserDetails']),
      ...mapGetters(['getApplicationList']), //get application list
      ...mapGetters(['getApplicationJSONByVersion']),
      userDetails() {
        console.log('Inside Map Getters: Getting User Details');
        return this.getUserDetails;
      },
  
      totalPages() {
        return Math.ceil(this.totalCount / 25);
      },
      /**
       * Return true if any of the filters are applied.
       * This is used to enable the "Clear Filters" button.
       * @returns {boolean}
       */
      filtersApplied() {
        return (this.filters.status!==undefined && this.filters.status!=='') ||
          (this.filters.bookmarked!==undefined && this.filters.bookmarked!=='') ||
          this.filters.position!=='' || 
          this.filters.company!=='' || 
          this.filters.date_from!=='' || 
          this.filters.date_to!=='';
      },
      statusFilterOptions() {
        return [
          {label: 'Any Status', value: ''},
          ...this.statusOptions
        ]
      },
      noApplicationsFound() {
        return this.applications.length === 0 && !this.loading && !this.filtersApplied;
      }
    },
    async created() {
      this.debouncedFetch = debounce(this.fetchApplications, 1200);
      console.log('ApplicationHistory.vue navigated from page: ' + this.previousRoute);
      // Check if the application list is already available in the Vue store
      if (this.getApplicationList?.applications) {
        console.log('Application list present in Vue store..');
        this.applications = this.getApplicationList.applications;
        this.hasMore = this.getApplicationList.has_more;
        this.totalCount = this.getApplicationList.total_count;
        //Set the selected application
        this.selectedApplicationDetails = this.getApplicationJSONByVersion(1);
        if (this.selectedApplicationDetails) this.selectedApplication = { id: this.selectedApplicationDetails.id};
        // Update the current tab based on the previous route
        if (this.previousRoute === 'Resume' || this.previousRoute === 'Cover Letter') this.current_tab = 'match_preview';
      } else {
        console.log('Application list not present in Vue store..');
        if (!this.userDetails?.user_id) {
          //If the user details aren't available fetch them from the server
          const isAuthenticated = await this.auth.checkAuthentication();
          if (!isAuthenticated) {
            this.navigateHome() // If the user isn't authenticated, return the user to Home screen
          }
        }
        this.fetchApplications();
      }
      this.$store.commit('setRouteOrigin', this.$route.name); //save the origin route name in Vue store
    },
  
    methods: {
      handleButtonClick(id){
        console.log('handleButtonClick: ' + id);
        if (id==='job_preview' || id=='match_preview') this.changePreviewTab(id);
        else if (id === 'view_resume') this.navigateTo('Resume');
      },
      //Change preview tab
      changePreviewTab(tabId){
        console.log('ApplicationHistory component: Changing preview tab to: '+tabId);
        if (this.current_tab !=tabId) {
          this.current_tab=tabId;
        }
      },
      navigateHome(){
        this.$store.commit('setPreviousRoute', this.$route.name); //save the previous route name in Vue store
        this.$router.push({ name: 'Home' });
      },
      navigateTo(destination){
        this.$store.commit('setPreviousRoute', this.$route.name); //save the previous route name in Vue store
        this.$router.push({ name: destination, query: { application_id: this.selectedApplicationDetails.id } });
      },
      async fetchApplications() {
        console.log('Fetching applications from server...for user: ' + this.userDetails?.user_id);
        this.loading = true;
        this.loadingToast = this.toast.showToast('Wait', this.MESSAGE_LOADING);
        try {
          const response = await axios.get('/api/v1/application_history', {
            params: {
              user_id: this.userDetails.user_id,
              page: this.currentPage,
              ...this.filters
            }
          });
          this.applications = response.data.applications;
          this.hasMore = response.data.has_more;
          this.totalCount = response.data.total_count;
          const application_list = {
            applications: this.applications,
            has_more: this.hasMore,
            total_count: this.totalCount,
          }
          this.$store.commit('setApplicationList', { application_list: application_list });
        } catch (error) {
          console.error('Error fetching applications:', error);
        } finally {
          this.loading = false;
          this.toast.removeToast(this.loadingToast);
        }
      },
      async selectApplication(application) {
        if (this.selectedApplication?.id === application?.id) return; //return if the selected application is already selected
        console.log('Selecting application with id: ' + application.id);
        this.selectedApplication = application;
        try {
          // Fetch full application details from user_applications endpoint
          const response = await axios.get(`/api/v1/user_applications/${application.id}`);
          this.selectedApplicationDetails = response.data;
          this.$store.commit('setApplicationJSON', { version: 1, response: response.data });
          console.log('Selected application details:', JSON.stringify(this.selectedApplicationDetails));
          this.current_tab = 'job_preview';
        } catch (error) {
          console.error('Error fetching application details:', error);
        }
      },
      handleDateChange() {
        // Reset to first page when date filter changes
        this.currentPage = 1;
        
        // If 'to' date is before 'from' date, clear 'to' date
        if (this.filters.date_from && this.filters.date_to) {
          if (new Date(this.filters.date_to) < new Date(this.filters.date_from)) {
            this.filters.date_to = '';
          }
        }
        this.fetchApplications();
      },
      updateStatusFilter(id, option) {
        console.log('Updating filter: ' + id + ' to value: ' + option);
        this.filters.status = option.value;
        this.fetchApplications();
      },
      updateBookmarkFilter(id, option) {
        console.log('Updating filter: ' + id + ' to value: ' + option);
        this.filters.bookmarked = option.value;
        this.fetchApplications();
      },
      async saveApplicationToServer(application_id, param_name, param_value) {
        console.log('Saving application to server');
        try {
          const response = await axios.patch(`/api/v1/user_applications/${application_id}`, {
            user_application: {
              [param_name]: param_value, //save the param
            },
          },);
          console.log('UserApplication Update Status: ' + response.status);  
          this.toast.showToast('Success',this.MESSAGE_SAVED); //show a success message
          return true; 
        } catch (error) {
          // In case there's an error making any of the API calls
          console.error('Error updating user application:', error);
          if (error.response?.status === 422) {
            // Extract the error message from the response
            const errorMessages = error.response.data[param_name];
            if (errorMessages && errorMessages.length > 0) {
              this.toast.showToast('Error', errorMessages[0]); // Show first error message
              return false;
            }
          }
          this.toast.showToast('Error', this.MESSAGE_UPDATE_FAILED); // Show generic error message
          return false;
        }
      },
      async bookmarkApplication(id) {
        console.log('Bookmarking application with id: ' + id);
        // find the application record in the applications array with matching id and toggle the value of the 'is_bookmarked' field
        const application = this.applications.find(app => app.id === id);
        const new_value = !application.bookmarked;
        const update_success = await this.saveApplicationToServer(id, 'bookmarked', new_value);
        if (update_success) application.bookmarked = new_value;
        this.fetchApplications();
      },
      async updateApplicationStatus(id, option) {
        const application_id = id.replace('status-', '');  // Extract the application ID by removing the 'status-' prefix
        console.log('Updating application status with id: ' + application_id + ' to value: ' + option.value);
        const update_success = await this.saveApplicationToServer(application_id, 'status', option.value);
        if (update_success) {
          const application = this.applications.find(app => app.id === application_id);
          if (application) application.status = option.value;
          this.fetchApplications();
        }
      },
      changePage(page) {
        this.currentPage = page;
        this.fetchApplications();
      },
      async clearFilter(filter) {
        console.log('Removing filter: ' + filter); 
        delete this.filters[filter];  // directly remove the filter
        if (filter === 'status') {
          this.defaultApplicationStatus = ' '; //reset the selected status
          console.log('Clearing status filter to'+this.defaultApplicationStatus);
        } else if (filter === 'bookmarked') {
          this.defaultBookmarkedStatus = ' '; //reset the selected bookmark status
        }
        this.currentPage = 1; // Reset to first page when filtering
        this.fetchApplications();
      },
      debounceFilter() {
        this.currentPage = 1; // Reset to first page when filtering
        this.debouncedFetch();
      },
      toggleFilterPanel() {
        this.filterPanelExpanded = !this.filterPanelExpanded;
      },
      formatDate(date) {
        return new Date(date).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .EmptyStateContainer {
    width: 100%;
    height: 100%;   
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .EmptyState {
    position: relative;
    width: 60%;
    top: 20%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    display: flex;
  }
  .EmptyResultPanel {
    position: relative;
    top: 5rem;
    align-self: center;
  }
  .FilterPanel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    position: sticky;
    background-color: var(--color-background-canvas);
    z-index: var(--z-index-nav);
  }

  .FilterHeaderRow {
    display: flex;
    width: 100%;
    column-gap: 0.625rem;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.125rem 0.625rem;
    padding-bottom: calc(0.125rem - var(--border-thickness-normal));
    border-bottom: var(--border-thickness-normal) solid var(--color-card-border);
    line-height: 2.25rem;
    height: fit-content;
  }
  .FilterPill {
    display: flex;
    align-items: center;
    height: 1.75rem;
    gap: 0.25rem;
    background-color: var(--color-input-disabled);
    padding: 0 0.5rem;
    border-radius: var(--pill-border-radius);
    font-size: var(--size-xx-small);
    font-weight: var(--weight-normal);
    color: var(--color-text-paragraph);
    flex-shrink: 0;
    margin: 0.125rem 0;
  }
  .FilterRowButton {
    height: 1.75rem;
    margin: 0.125rem 0;
    display: flex;
    align-items: center;
  }
  .FilterSection {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    padding-top: 0.4rem;
    border-bottom: var(--border-thickness-normal) solid var(--color-card-border);
    box-shadow: var(--shadow-elevation-one);
    width: 100%;
    max-height: 1000rem;
    transition: max-height var(--transition-time) ease-in-out, padding var(--transition-time) ease-in-out;
    transform-origin: top left;
    overflow: hidden;
  }
  .FilterSection.hidden {
    max-height: 0;
    padding-top: 0;
    border: none;
  }
  .FilterRow {
    display: flex;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    gap: 1.5rem;
  }
  .FilterRowColumn{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 50%;
  }
  .FilterRowLine {
    display: flex;
    width: 100%;
  }

  .BorderRight.hired {
    border-width: var(--border-thickness-selected);
    border-color: var(--success-500);
  }
  .BorderRight.interviewing{
    border-width: var(--border-thickness-selected);
    border-color: var(--warning-400);
  }
  .BorderRight.rejected{
    border-width: var(--border-thickness-selected);
    border-color: var(--danger-700);
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    margin: 0.5rem 0;
  }

  </style>