<template>
    <div class="Scaffold" :style="{ width: content_container_width  }">
      <div class="Background">
        <!-- If Content is Available -->
        <div class="ContentContainer" >
          <!-- Editor Panel -->
          <div class="EditorPanel" :style="{ width: editor_width  }" v-if="resume" >
             <div class="EditorScaffold" :class ="is_editor_expanded? 'PanelWidth':'LeftNavWidth'">
               <!-- Editor Collapsed/ Left Nav -->
              <ResumeLeftNav v-if="resume && !is_editor_expanded"
                :user-edited-resume="userEditedResume"
                @buttonClick="handleButtonClick"
              />
              <!-- Editor Expanded - Top Menu -->
              <div class="StickyContainer BorderBottom" v-if="is_editor_expanded">
                <div class="PanelToggleContainer">
                  <themed-button componentId="editor_toggle" button-type="icon" icon="menu" :rotate=true
                  :tooltip-text = "is_editor_expanded? TOOLTIP_HIDE_EDITOR : TOOLTIP_EXPAND_EDITOR" align="left"
                  @buttonClick="handleButtonClick"/>
                </div>
                <div class="TopMenuScaffold JustifyCenter">
                  <stepper-button componentId="input" :isComplete="true" :hasRightSibling="true" 
                  :isSelected="false" :isDisabled="false" :text="getLabelHome"
                  @stepperClick="handleButtonClick"/>
                  <stepper-button componentId="resume" :isComplete="true" :hasRightSibling="true" :isSelected="true" :isDisabled="false" :text="TOOLTIP_RESUME"/>
                  <stepper-button componentId="cover_letter" :isComplete="true" :hasRightSibling="false" 
                  :isSelected="false" :isDisabled="false" :text="LABEL_COVER_LETTER"
                  @stepperClick="handleButtonClick"/>
                </div>
              </div>
              <!-- Tab Menu -->
              <div class="EditorPanelHeader" v-if="is_editor_expanded">
                <div class="EditorPanelHeaderText">{{ LABEL_EDITOR }}</div>
                <!-- Additional Section Reset Button -->
                <div class="IconContainerMini">
                  <themed-button componentId="hide-on-editor" button-type="icon" icon="reset"
                  :tooltip-text =TOOLTIP_REMOVE_SECTIONS align="left"
                  @buttonClick="initializeShowOnEditorSettings"/>
                </div>
                <!-- Add Section Menu Button -->
                 <div class="EditorPanelHaderButton">
                  <menu-button componentId="show-on-editor"
                    :isDisabled="!availableSections"
                    :label = "LABEL_ADD_SECTION"
                    :options = "availableSections"
                    :aria-label = "TOOLTIP_ADD_SECTION"
                    :tooltip-text = "TOOLTIP_ADD_SECTION"
                    @buttonClick="showSectionOnEditor"/>
                  </div>
              </div>

              <!-- Editor Expanded - Editor Panel -->
              <div class="PanelContainer" v-if="is_editor_expanded">
                <!----------------------- Profile Accordion-->
                <div class="RegularCard" ref="profile_card">
                  <div class="CardHeaderRow">
                    <div class="CardHeaderIcon">
                      <icon icon-name="phone" :icon-stroke=1.5></icon>
                    </div>
                    <div class="CardHeaderHorizontalGroup">
                      <div class="TextHeading1 TextTruncate">{{ LABEL_PROFILE }}</div>
                    </div>
                    <div class="CardToggleButton"></div> 
                    <!-- Card Expand/ Collapse Button -->
                    <div class="CardIconButton">
                      <themed-button componentId="toggle_profile_card" button-type="icon" :is-disabled="false" 
                      icon="circle-up-arrow" :rotate="true" :toggle-state="is_profile_expanded"
                      :tooltip-text="is_profile_expanded? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                      @buttonClick="toggleProfileAccordion"/>
                    </div>
                  </div>
                  <!-- Card Body -->
                  <div class="RegularCardSection" :class="is_profile_expanded?'':'collapsed'">
                    <!-- Section Instructions -->
                    <div class="CardButtonRow MarginBottom">
                      <div class="TextDescription">{{INSTRUCTIONS_CONTACT_CARD }}</div>
                    </div>
                    <!--Edit Name-->
                    <div class="RowAlignedLeft MarginTop" >
                      <div class="ChildCardRow">
                        <input class="ThemedInput" type="text" id="name" :placeHolder="PLACE_HOLDER_NAME"
                        :disabled="resume_in_progress" v-model="userEditedResume.name" required> 
                        <div class="InputLabel">
                            <div class="HorizontalGroup NoHorizontalSpace">
                              <div for="name">{{LABEL_NAME.toUpperCase()}}</div>
                              <div class="ErrorColor">*</div>
                            </div>
                        </div>
                      </div>         
                    </div>
                    <!--Edit Email-->
                    <div class="RowAlignedLeft MarginTop" v-if="userEditedResume.contact_information">
                      <div class="ChildCardRow">
                        <input class="ThemedInput" type="text" id="email" :placeHolder="PLACE_HOLDER_EMAIL"
                        :disabled="resume_in_progress" v-model="userEditedResume.contact_information.email" required> 
                        <div class="InputLabel">
                            <div class="HorizontalGroup NoHorizontalSpace">
                              <div for="email">{{LABEL_EMAIL.toUpperCase()}}</div>
                              <div class="ErrorColor">*</div>
                            </div>
                        </div>
                      </div>         
                    </div>
                    <!--Edit Phone-->
                    <div class="RowAlignedLeft MarginTop"  v-if="userEditedResume.contact_information">
                      <div class="ChildCardRow">
                        <input class="ThemedInput" type="text" id="phone" :placeHolder="PLACE_HOLDER_PHONE"
                        :disabled="resume_in_progress" v-model="userEditedResume.contact_information.phone_number" required>  
                        <div class="InputLabel">
                            <div class="HorizontalGroup NoHorizontalSpace">
                              <div for="phone">{{LABEL_PHONE.toUpperCase()}}</div>
                              <div class="ErrorColor">*</div>
                            </div>
                        </div> 
                      </div>       
                    </div>
                    <!--Edit City-->
                    <div class="RowAlignedLeft MarginTop"  v-if="userEditedResume.contact_information">
                      <div class="ChildCardRow">
                        <input class="ThemedInput" type="text" id="city" :placeHolder="PLACE_HOLDER_CITY"
                        :disabled="resume_in_progress" v-model="userEditedResume.contact_information.city" required>
                        <div class="InputLabel">
                            <div class="HorizontalGroup NoHorizontalSpace">
                              <div for="name">{{LABEL_CITY.toUpperCase()}}</div>
                              <div class="ErrorColor">*</div>
                            </div>
                        </div>
                      </div>          
                    </div>
                    <!--Edit LinkedIn-->
                    <div class="RowAlignedLeft MarginTop"  v-if="userEditedResume.contact_information">
                      <div class="ChildCardRow">
                        <input class="ThemedInput" type="text" id="linkedin" :placeHolder="PLACE_HOLDER_LINKEDIN"
                        :disabled="resume_in_progress" v-model="userEditedResume.contact_information.linkedin_id"> 
                        <label class="InputLabel" for="linkedin">{{ combinedLabel("LINKEDIN", LABEL_SOCIAL_PROFILE, false).toUpperCase() }}</label> 
                      </div>        
                    </div>
                    <!--Edit Website-->
                    <div class="RowAlignedLeft MarginTop"  v-if="userEditedResume.contact_information">
                      <div class="ChildCardRow">
                        <input class="ThemedInput" type="text" id="website" :placeHolder="PLACE_HOLDER_WEBSITE"
                        :disabled="resume_in_progress" v-model="userEditedResume.contact_information.website">  
                        <label class="InputLabel" for="website">{{ LABEL_WEBSITE.toUpperCase()}}</label> 
                      </div>
                    </div>
                    <!--Website Label-->
                    <div class="RowAlignedLeft MarginTop"  v-if="userEditedResume.contact_information">
                      <div class="ChildCardRow">
                        <input class="ThemedInput" type="text" id="website" :placeHolder="PLACE_HOLDER_WEBSITE_LABEL"
                        :disabled="resume_in_progress" v-model="userEditedResume.contact_information.website_label">  
                        <label class="InputLabel" for="website">{{ LABEL_WEBSITE_LABEL.toUpperCase()}}</label> 
                      </div>
                    </div>                    
                    <!--Edit GitHub-->
                    <div class="RowAlignedLeft MarginTop"  v-if="userEditedResume.contact_information">
                      <div class="ChildCardRow">
                        <input class="ThemedInput" type="text" id="github" :placeHolder="PLACE_HOLDER_GITHUB"
                        :disabled="resume_in_progress" v-model="userEditedResume.contact_information.github">
                        <label class="InputLabel" for="github">{{ combinedLabel("GITHUB", LABEL_SOCIAL_PROFILE, false).toUpperCase() }}</label>  
                      </div>         
                    </div>
                    <!--Edit Other Social Media-->
                    <div class="RowAlignedLeft MarginTop"  v-if="userEditedResume.contact_information">
                      <div class="ChildCardRow">
                        <input class="ThemedInput" type="text" id="social" :placeHolder="PLACE_HOLDER_SOCIAL"
                        :disabled="resume_in_progress" v-model="userEditedResume.contact_information.other_social_media">
                        <label class="InputLabel" for="social">{{ LABEL_OTHER_CONTACT.toUpperCase()}}</label> 
                      </div>          
                    </div>
                    <!--Edit Other Social Media Label-->
                    <div class="RowAlignedLeft MarginTop"  v-if="userEditedResume.contact_information">
                      <div class="ChildCardRow">
                        <input class="ThemedInput" type="text" id="social" :placeHolder="PLACE_HOLDER_SOCIAL_LABEL"
                        :disabled="resume_in_progress" v-model="userEditedResume.contact_information.other_social_media_label">
                        <label class="InputLabel" for="social">{{ LABEL_OTHER_CONTACT_LABEL.toUpperCase()}}</label> 
                      </div>          
                    </div>

                  </div>
                </div>
                <!-- Sortable Sections -->
                <draggable v-model="userEditedResume.section_order.items_array" 
                  :item-key="element => element" 
                  :group="{ name: 'items' }"
                  handle=".CardHeaderIndicator"
                  :animation="200"
                  @change="updateSectionOrderPreferences">
                  <template #item="{ element: sectionKey }">
                    <div class="CustomSectionPanel" :class="{'hide': !section_data[sectionKey].showOnEditor}">
                      <!----------------------- Professional Summary Accordion-->
                      <div class="RegularCard" :ref="sectionKey"
                        v-if="sectionKey === 'professional_summary' && section_data[sectionKey].showOnEditor">
                        <!-- Header: View State -->
                        <div class="CardHeaderRow DraggableHeader" :class="{'hide': section_edit_state[sectionKey]}">
                          <!-- Draggable Indicator -->
                          <div class="CardHeaderIndicator">
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <div class="CardHeaderIcon">
                            <icon icon-name="summary" :icon-stroke=1.5></icon>
                          </div>
                          <div class="CardHeaderHorizontalGroup">
                            <div class="TextHeading1 TextTruncate"> {{ userEditedResume[sectionKey]?.name || section_names_plural[sectionKey] }}</div>
                            <!-- Show Edit Button When Expanded -->
                            <div class="CardHeaderIcon" v-if="section_data[sectionKey].expanded">
                              <themed-button :componentId="'section-name-edit-' + sectionKey" button-type="icon" icon="edit"
                                :is-disabled="download_in_progress || !section_data[sectionKey].expanded" :tooltip-text="TOOLTIP_EDIT_NAME"
                                @buttonClick="handleButtonClick"/>
                            </div>
                          </div>
                          <!-- Show/ Hide Toggle Button -->
                          <div class="CardToggleButton">
                            <themed-button :componentId="'section-hide-' + sectionKey" button-type="switch" 
                              :toggle-state="!userEditedResume[sectionKey].hide"
                              :tooltip-text = "userEditedResume[sectionKey]?.hide ? section_data[sectionKey].showLabel : section_data[sectionKey].hideLabel" 
                              :is-disabled="resume_in_progress"
                              @buttonClick="toggleSectionVisibility(sectionKey)"/>
                          </div>  
                          <!-- Expand / Collapse Button -->
                          <div class="CardIconButton">
                            <themed-button :componentId="'section-toggle-' + sectionKey" button-type="icon" :is-disabled="false" 
                              icon="circle-up-arrow" :rotate="true" 
                              :toggle-state="section_data[sectionKey].expanded"
                              :tooltip-text="section_data[sectionKey].expanded ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                              @buttonClick="toggleSectionAccordion(sectionKey)"/>
                          </div>     
                        </div>
                        <!-- Header: Edit State -->
                        <div class="CardHeaderRow DraggableHeader" :class="{'hide': !section_edit_state[sectionKey], 'edit': section_edit_state[sectionKey]}">
                          <div class="CardHeaderIndicator">
                            <!-- Draggable Indicator -->
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <!-- Section Name Input -->
                          <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="section_name_temp[sectionKey]" required>
                          <!-- Cancel Button -->
                          <div class="CardHeaderEditorConfirmation MarginLeft">
                              <themed-button :componentId="'section-name-cancel-' + sectionKey" button-type="secondary" icon="remove"
                                :tooltip-text="TOOLTIP_CANCEL"
                                @buttonClick="handleButtonClick"/>
                            </div>
                            <!-- Done Button -->
                          <div class="CardHeaderEditorConfirmation">
                            <themed-button :componentId="'section-name-save-' + sectionKey" button-type="primary" icon="check"
                            :tooltip-text="TOOLTIP_DONE" :is-disabled="section_name_temp[sectionKey]==''"
                            @buttonClick="handleButtonClick"/>
                          </div>
                        </div>
                        <!-- Card Body -->
                        <div class="RegularCardSection" :class="{ collapsed: !section_data[sectionKey].expanded }">
                          <div class="CardButtonRow">
                            <div class="TextDescription">{{INSTRUCTIONS_SUMMARY_CARD }}</div>
                          </div>
                          <!--Edit Professional Summary-->
                          <div class="RowAlignedLeft MarginTop" v-if="userEditedResume.professional_summary">
                            <div class="ChildCardRow">
                              <textarea class="ThemedInput" id="professional_summary" v-model="flattenedSummary" 
                                :disabled="resume_in_progress" :placeholder="PLACE_HOLDER_SUMMARY"
                                rows="10" required 
                                @input="updateContentStatus(userEditedResume.professional_summary.id, userEditedResume.professional_summary.alt_content, userEditedResume.professional_summary.comments, userEditedResume.professional_summary.summary)"></textarea>
                                <div class="InputLabel">
                                  <div class="HorizontalGroup NoHorizontalSpace">
                                    <div for="professional_summary">{{LABEL_PROFESSIONAL_SUMMARY.toUpperCase()}}</div>
                                    <div class="ErrorColor">*</div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      <!----------------------- Education Accordion-->
                      <div class="RegularCard" :ref="sectionKey"
                        v-else-if="sectionKey === 'educational_qualifications'">
                        <!-- Header View State -->
                        <div class="CardHeaderRow DraggableHeader" :class="{'hide': section_edit_state[sectionKey]}" >
                          <!-- Draggable Indicator -->
                          <div class="CardHeaderIndicator">
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <div class="CardHeaderIcon">
                            <icon icon-name="education" :icon-stroke=1.5></icon>
                          </div>
                          <div class="CardHeaderHorizontalGroup">
                            <div class="TextHeading1 TextTruncate">{{ userEditedResume[sectionKey]?.name || LABEL_EDUCATION_SINGULAR }}</div>
                            <!-- Show Count of Educations when Collapsed -->
                             <template v-if="!section_data[sectionKey].expanded">
                              <!-- Counts -->
                              <tooltip 
                                :text="getCountTooltip(itemCounts(sectionKey).total, section_names_singular[sectionKey], section_names_plural[sectionKey], false)" 
                                horizontal-align="left">
                                <div class="CountBubble">
                                  {{ itemCounts(sectionKey).total }}</div>
                              </tooltip>
                              <tooltip v-if="itemCounts(sectionKey).hidden > 0 && userEditedResume[sectionKey].hide !== false" 
                                :text="getCountTooltip(itemCounts(sectionKey).hidden, section_names_singular[sectionKey], section_names_plural[sectionKey], true)"  
                                horizontal-align="left">
                                <div class="CountBubble AlertBackground ContrastColor"
                                  :class="{'Clickable': sectionRecommendations(sectionKey)?.length > 0}"
                                  @click="scrollToComment('reco_' + sectionKey)">
                                  {{ itemCounts(sectionKey).hidden }}</div>
                              </tooltip>
                             </template>
                            <!-- Show Edit Button When Expanded -->
                            <div class="CardHeaderIcon" v-else>
                              <themed-button :componentId="'section-name-edit-' + sectionKey" button-type="icon" icon="edit"
                                :is-disabled="download_in_progress || !section_data[sectionKey].expanded" :tooltip-text="TOOLTIP_EDIT_NAME"
                                @buttonClick="handleButtonClick"/>
                            </div>
                          </div>
                          <!-- Show/ Hide Date Button -->
                          <div class="CardIconButton">
                            <themed-button :componentId="'date-toggle-' + sectionKey" button-type="icon" 
                              :icon="!userEditedResume[sectionKey].hide_date ? 'date-show' : 'date-hide'" 
                              :tooltip-text="!userEditedResume[sectionKey].hide_date ? TOOLTIP_HIDE_ALL_DATES : TOOLTIP_SHOW_ALL_DATES"
                              @buttonClick="toggleDateVisbility"/>
                          </div> 
                          <!-- Show/ Hide Toggle Button -->
                          <div class="CardToggleButton">
                            <themed-button :componentId="'section-hide-' + sectionKey" button-type="switch" 
                              :toggle-state="!userEditedResume[sectionKey].hide"
                              :tooltip-text = "!userEditedResume[sectionKey]?.hide ? section_data[sectionKey].hideLabel : section_data[sectionKey].showLabel" 
                              :is-disabled="resume_in_progress"
                              @buttonClick="toggleSectionVisibility(sectionKey)"/>
                          </div> 
                          <!-- Expand / Collapse Button -->
                          <div class="CardIconButton">
                            <themed-button :componentId="'section-toggle-' + sectionKey" button-type="icon" 
                              icon="circle-up-arrow" :rotate="true" 
                              :toggle-state="section_data[sectionKey].expanded"
                              :tooltip-text="section_data[sectionKey].expanded ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                              @buttonClick="toggleSectionAccordion(sectionKey)"/>
                          </div> 
                        </div>
                        <!-- Header: Edit State -->
                        <div class="CardHeaderRow DraggableHeader" :class="{'hide': !section_edit_state[sectionKey], 'edit': section_edit_state[sectionKey]}">
                          <div class="CardHeaderIndicator">
                            <!-- Draggable Indicator -->
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <!-- Section Name Input -->
                          <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="section_name_temp[sectionKey]" required>
                          <!-- Cancel Button -->
                          <div class="CardHeaderEditorConfirmation MarginLeft">
                              <themed-button :componentId="'section-name-cancel-' + sectionKey" button-type="secondary" icon="remove"
                                :tooltip-text="TOOLTIP_CANCEL"
                                @buttonClick="handleButtonClick"/>
                            </div>
                            <!-- Done Button -->
                          <div class="CardHeaderEditorConfirmation">
                            <themed-button :componentId="'section-name-save-' + sectionKey" button-type="primary" icon="check"
                            :tooltip-text="TOOLTIP_DONE" :is-disabled="section_name_temp[sectionKey]==''"
                            @buttonClick="handleButtonClick"/>
                          </div>
                        </div>
                        <!--List of Educations-->
                        <div class="RegularCardSection" :class="{ collapsed: !section_data[sectionKey].expanded }">
                          <!-- Add Education Button -->
                          <div class="CardButtonRow">
                            <div class="TextDescription">{{ combinedLabel(BUTTON_LABEL_UPDATE, LABEL_EDUCATION_PLURAL.toLowerCase(), false) }}</div>
                            <themed-button componentId="add_education0" button-type="secondary"
                            :label = "combinedLabel(BUTTON_LABEL_ADD, LABEL_EDUCATION_SINGULAR, true)"  width="fit"
                            :is-disabled="resume_in_progress"
                            @buttonClick="addEducation(0)"/>
                          </div>
                          <ul class="CardList">
                            <draggable 
                              v-model="userEditedResume.educational_qualifications.educations"
                              :item-key="(education) => education.id" 
                              :group="{ name: 'items' }"
                              handle=".CardHeaderIndicator"
                              :animation="200">
                              <template #item="{ element: education, index }">
                                <li class="ChildCard">
                                  <!-- School Card -->
                                  <div class="CardHeaderRow DraggableHeader">
                                    <!-- Draggable Indicator -->
                                    <div class="CardHeaderIndicator">
                                      <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                                        <icon icon-name="draggable"></icon>
                                      </div>
                                    </div>
                                    <div class="CardHeaderHorizontalGroup DegreeCardHeaderWidth">
                                      <div class="TextParagraph ParagraphColor FontBold TextTruncate"> {{ education.school}} </div>
                                    </div>
                                    <!-- Show/ Hide Date Button -->
                                    <div class="ChildCardIconButton">
                                      <themed-button :componentId="'degree-date-toggle-' + index" button-type="icon" 
                                        :icon="!education.hide_date ? 'date-show' : 'date-hide'" 
                                        :tooltip-text="!education.hide_date ? TOOLTIP_HIDE+ ' ' + LABEL_DATE_SINGULAR: TOOLTIP_SHOW + ' ' + LABEL_DATE_SINGULAR"
                                        @buttonClick="toggleDegreeDateVisbility(index)"/>
                                    </div>
                                    <!-- School Show/ Hide toggle -->
                                    <div class="ChildCardToggleButton">
                                      <themed-button 
                                        :componentId="'degree-hide-' + index + '-' + index" 
                                        button-type="switch" 
                                        :toggle-state="!education?.hide"
                                        :tooltip-text="education?.hide ? TOOLTIP_SHOW : TOOLTIP_HIDE"
                                        :is-disabled="resume_in_progress"
                                        @buttonClick="toggleDegreeVisibility(index)"
                                      />
                                    </div> 
                                    <!-- School Delete Button -->
                                    <div class="ChildCardIconButton">
                                      <themed-button :componentId="'delete_education'+(index)" button-type="icon" icon="delete"
                                        :tooltip-text = "combinedLabel(BUTTON_LABEL_DELETE, LABEL_EDUCATION_SINGULAR, false) "
                                        @buttonClick="deleteEducation(index)"/>
                                    </div>
                                    <!-- School Expand/ Collapse Button -->
                                    <div class="ChildCardIconButton">
                                      <themed-button :componentId="'toggle_school_card_' + index" button-type="icon" :is-disabled="false" 
                                      icon="up-arrow" :rotate="true" :toggle-state="is_school_expanded[index]"
                                      :tooltip-text="is_school_expanded && is_school_expanded[index] ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                                      @buttonClick="handleButtonClick"/>
                                    </div>
                                  </div>
                                  <!-- School Details -->
                                  <div class="RegularCardSection" :class="is_school_expanded[index] ?'':'collapsed'">
                                    <!-- School Name -->
                                    <div class="RowAlignedLeft MarginTop" >
                                      <div class="ChildCardRow">
                                        <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="education.school"
                                        :placeholder="combinedLabel(LABEL_ADJECTIVE_SCHOOL, LABEL_SCHOOL, false)"
                                        :id="'school_name_'+index" required> 
                                        <div class="InputLabel">
                                          <div class="HorizontalGroup NoHorizontalSpace">
                                            <div :for="'school_name_'+index">{{LABEL_SCHOOL.toUpperCase()}}</div>
                                            <div class="ErrorColor">*</div>
                                          </div>
                                        </div>
                                      </div>         
                                    </div>
                                    <!-- Degree -->
                                    <div class="RowAlignedLeft MarginTop" >
                                      <div class="ChildCardRow">
                                        <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="education.degree"
                                        :placeholder="combinedLabel(LABEL_ADJECTIVE_DEGREE, LABEL_DEGREE, false)"
                                        :id="'school_degree_'+index" required>   
                                        <div class="InputLabel">
                                          <div class="HorizontalGroup NoHorizontalSpace">
                                            <div :for="'school_degree_'+index">{{LABEL_DEGREE.toUpperCase()}}</div>
                                            <div class="ErrorColor">*</div>
                                          </div>
                                        </div>  
                                      </div>     
                                    </div>
                                    <!-- Start Date -->
                                    <div class="RowAlignedLeft MarginTop" >
                                      <div class="ChildCardRow">
                                        <input class="ThemedInput" type="text" :disabled="resume_in_progress" 
                                        :placeholder="PLACE_HOLDER_DATE" v-model="education.school_start_date"
                                        :id="'school_start_'+index"> 
                                        <div class="InputLabel" :for="'school_start_'+index">{{LABEL_START_DATE.toUpperCase()}}</div>
                                      </div>         
                                    </div>
                                    <!-- End Date -->
                                    <div class="RowAlignedLeft MarginTop" >
                                      <div class="ChildCardRow">
                                        <input class="ThemedInput" type="text" :disabled="resume_in_progress" 
                                        :placeholder="PLACE_HOLDER_END_DATE" v-model="education.school_end_date"
                                        :id="'school_end_'+index">   
                                        <div class="InputLabel" :for="'school_end_'+index">{{LABEL_END_DATE.toUpperCase()}}</div>
                                      </div>       
                                    </div>
                                    <!-- GPA -->
                                    <div class="RowAlignedLeft MarginTop" >
                                      <div class="ChildCardRow">
                                        <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="education.gpa"
                                        :placeholder="PLACE_HOLDER_GPA" :id="'school_gpa_'+index">      
                                        <div class="InputLabel" :for="'school_gpa_'+index">{{LABEL_GPA.toUpperCase()}}</div>
                                      </div>    
                                    </div>
                                  </div>
                                </li>
                              </template>
                            </draggable>
                          </ul>
                        </div>
                      </div>
                      <!------------------------ Experience Accordion -->
                      <div class="RegularCard" ref="experience_card"
                        v-else-if="sectionKey === 'experiences'">
                        <div class="CardHeaderRow DraggableHeader">
                           <!-- Draggable Indicator -->
                           <div class="CardHeaderIndicator">
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <!-- Card Icon -->
                          <div class="CardHeaderIcon">
                            <icon icon-name="experience" :icon-stroke=1.5></icon>
                          </div>
                          <div class="CardHeaderHorizontalGroup">
                            <div class="TextHeading1 TextTruncate">{{ LABEL_EXPERIENCE }}</div>
                            <!-- <div class="CountBubble" v-if="userEditedResume.experiences" >{{ userEditedResume.experiences?.length }}</div> -->
                            <!-- Position Counts -->
                            <tooltip 
                              :text="getCountTooltip(experienceCounts.total, LABEL_POSITION_SINGULAR, LABEL_POSITION_PLURAL, false)" 
                              horizontal-align="left">
                              <div class="CountBubble">
                                {{ experienceCounts.total }}</div>
                            </tooltip>
                            <tooltip v-if="experienceCounts.hidden > 0 && userEditedResume.hide_experiences" 
                              :text="getCountTooltip(experienceCounts.hidden, LABEL_POSITION_SINGULAR, LABEL_POSITION_PLURAL, true)"  
                              horizontal-align="left">
                              <div class="CountBubble AlertBackground ContrastColor" 
                                :class="{'Clickable': sectionRecommendations(sectionKey)?.length > 0}"
                                @click="scrollToComment('reco_'+sectionKey)">
                                {{ experienceCounts.hidden }}</div>
                            </tooltip>
                          </div>
                          <!-- Show/ Hide Toggle Button -->
                          <div class="CardToggleButton">
                            <themed-button :componentId="'section-hide-' + sectionKey" button-type="switch" 
                              :toggle-state="!userEditedResume.hide_experiences"
                              :tooltip-text = "!userEditedResume.hide_experiences? section_data[sectionKey].hideLabel : section_data[sectionKey].showLabel" 
                              :is-disabled="resume_in_progress"
                              @buttonClick="toggleExperienceVisbility"/>
                          </div> 
                          <!-- Professional Experience Card Expand/ Collapse Button -->
                          <div class="CardIconButton">
                            <themed-button componentId="toggle_experience_card" button-type="icon" :is-disabled="false" 
                            icon="circle-up-arrow" :rotate="true" :toggle-state="is_experience_expanded"
                            :tooltip-text="is_experience_expanded? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                            @buttonClick="toggleExperienceAccordion"/>
                          </div>
                        </div>
                        <!-- List of Experiences/ Organizations -->
                        <div class="RegularCardSection" :class="is_experience_expanded?'':'collapsed'">
                          <!-- Add Company Button -->
                          <div class="CardButtonRow">
                            <div class="TextDescription">{{ combinedLabel(BUTTON_LABEL_UPDATE, LABEL_ORGANIZATION_PLURAL.toLowerCase()), false}}</div>
                            <themed-button componentId="add_company0" button-type="secondary"
                            :label = "combinedLabel(BUTTON_LABEL_ADD, LABEL_ORGANIZATION_SINGULAR, true)"  width="fit"
                            :is-disabled="resume_in_progress"
                            @buttonClick="addCompany(0)"/>
                          </div>
                          <!-- Reorderable Organization List -->
                          <draggable class="CardList"
                            v-model="userEditedResume.experiences" 
                            :item-key="(index) => index"
                            :group="{ name: 'items' }"
                            handle=".CardHeaderIndicator"
                            :animation="200">
                            <template #item="{ element: experience, index }">
                              <div class="ChildCard">
                                <!-- Organization Card -->
                                <div class="CardHeaderRow DraggableHeader">
                                  <!-- Draggable Indicator -->
                                  <div class="CardHeaderIndicator">
                                    <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                                      <icon icon-name="draggable"></icon>
                                    </div>
                                  </div>
                                  <div class="CardHeaderHorizontalGroup ChildCardHeaderWidth">
                                    <!-- Organization Name -->
                                    <label class="TextParagraph ParagraphColor FontBold TextTruncate">{{ experience.company_name }}</label>
                                    <!-- Position Counts -->
                                    <tooltip  
                                      :text="getCountTooltip(experiencePositionCounts(index).total, LABEL_POSITION_SINGULAR, LABEL_POSITION_PLURAL, false)" 
                                      horizontal-align="left">
                                      <div class="CountBubble">
                                        {{ experiencePositionCounts(index).total }}</div>
                                    </tooltip>
                                    <tooltip v-if="experiencePositionCounts(index).hidden > 0 && userEditedResume.hide_experiences" 
                                      :text="getCountTooltip(experiencePositionCounts(index).hidden, LABEL_POSITION_SINGULAR, LABEL_POSITION_PLURAL, true)"  
                                      horizontal-align="left">
                                      <div class="CountBubble AlertBackground ContrastColor" @click="toggleCompanyAccordion(index)">
                                        {{ experiencePositionCounts(index).hidden }}</div>
                                    </tooltip>
                                  </div>
                                  <!-- Organization Delete Button -->  
                                  <div class="ChildCardIconButton MarginRight">
                                    <themed-button :componentId="'delete_company'+(index)" button-type="icon" icon="delete"
                                      :tooltip-text = "combinedLabel(BUTTON_LABEL_DELETE, LABEL_EXPERIENCE, false) "
                                      @buttonClick="deleteCompany(index)"/>
                                  </div>
                                  <!-- Organization Expand/ Collapse Button -->
                                  <div class="ChildCardIconButton">
                                    <themed-button :componentId="'toggle_organization_card_' + index" button-type="icon" :is-disabled="false" 
                                    icon="up-arrow" :rotate="true" :toggle-state="is_organization_expanded[index]"
                                    :tooltip-text="is_organization_expanded && is_organization_expanded[index] ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                                    @buttonClick="toggleCompanyAccordion(index)"/>
                                  </div>
                                </div>
                                <!-- Organization Details -->
                                <div class="RegularCardSection" :class="is_organization_expanded[index] ?'':'collapsed'">
                                  <!-- Organization Name -->
                                  <div class="RowAlignedLeft MarginTop" >
                                    <div class="ChildCardRow">
                                      <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="experience.company_name"
                                      :placeholder="combinedLabel(LABEL_ADJECTIVE_ORGANIZATION, LABEL_ORGANIZATION_SINGULAR, false)"
                                      :id="'organization_name_'+index" required>
                                      <div class="InputLabel">
                                        <div class="HorizontalGroup NoHorizontalSpace">
                                          <div :for="'organization_name_'+index">{{LABEL_ORGANIZATION_SINGULAR.toUpperCase()}}</div>
                                          <div class="ErrorColor">*</div>
                                        </div>
                                      </div>   
                                    </div>       
                                  </div>
                                  <!-- Organization Description -->
                                  <div class="RowAlignedLeft MarginTop" >
                                    <div class="ChildCardRow">
                                      <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="experience.company_description"
                                      :placeholder="PLACE_HOLDER_ORGANIZATION_DESCRIPTION" :id="'organization_description_'+index">   
                                      <div class="InputLabel" :for="'organization_description_'+index">{{ LABEL_ORGANIZATION_DESCRIPTION.toUpperCase() }}</div>                 
                                    </div>       
                                  </div>
                                  <!--List of Positions Card-->
                                  <div class="GrandChildCard" >
                                    <div class="CardHeaderRow">
                                      <div class="CardHeaderHorizontalGroup ChildCardHeaderWidth">
                                        <label class="TextHeading2 FontBold TextTruncate">{{ LABEL_POSITION_PLURAL}}</label>
                                        <div class="CountBubble" v-if="experience.positions" >{{ experience.positions?.length }}</div>
                                      </div>
                                      <!-- Org Position Card Expand/ Collapse Button -->
                                      <div class="ChildCardIconButton">
                                        <themed-button :componentId="'toggle_org_position_card_' + index" button-type="icon" :is-disabled="false" 
                                        icon="up-arrow" :rotate="true" :toggle-state="is_org_position_card_expanded[index]"
                                        :tooltip-text="is_org_position_card_expanded && is_org_position_card_expanded[index] ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                                        @buttonClick="handleButtonClick"/>
                                      </div>
                                    </div>
                                    <!-- List of positions -->
                                    <div class="RegularCardSection" :class="is_org_position_card_expanded[index] ? '':'collapsed'">
                                      <!-- Add Position Button -->
                                      <div class="CardButtonRow">
                                        <div class="TextDescription">{{ combinedLabel(BUTTON_LABEL_UPDATE, LABEL_POSITION_PLURAL.toLowerCase()), false }}</div>
                                        <themed-button :componentId="'add_position_'+index+'_0'" button-type="secondary"
                                        :label = "combinedLabel(BUTTON_LABEL_ADD, LABEL_POSITION_SINGULAR, true)"  width="fit"
                                        :is-disabled="resume_in_progress"
                                        @buttonClick="addPosition(index, 0)"/>
                                      </div>
                                      <ul class="CardList">
                                        <draggable 
                                          v-model="experience.positions"
                                          :item-key="(position) => position.id" 
                                          :group="{ name: 'items' }"
                                          handle=".CardHeaderIndicator"
                                          :animation="200">
                                          <template #item="{ element: position, index: positionIndex }">
                                            <li class="ChildCard">
                                              <!-- Poistion Card -->
                                              <div class="CardHeaderRow DraggableHeader">
                                                <!-- Draggable Indicator -->
                                                <div class="CardHeaderIndicator">
                                                  <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                                                    <icon icon-name="draggable"></icon>
                                                  </div>
                                                </div>
                                                <!-- Position Title -->
                                                <div class="CardHeaderHorizontalGroup">
                                                  <label class="TextDescription FontBold TextTruncate"> {{ position.job_title }}</label>
                                                </div>
                                                <!-- Position Show/ Hide toggle -->
                                                <div class="ChildCardToggleButton">
                                                  <themed-button 
                                                    :componentId="'position-hide-' + index + '-' + positionIndex" 
                                                    button-type="switch" 
                                                    :toggle-state="!position?.hide"
                                                    :tooltip-text="position?.hide ? TOOLTIP_SHOW : TOOLTIP_HIDE"
                                                    :is-disabled="resume_in_progress"
                                                    @buttonClick="togglePositionVisibility(index, positionIndex)"
                                                  />
                                                </div>
                                                <!-- Position Delete -->
                                                <div class="ChildCardIconButton">
                                                  <themed-button :componentId="'delete_position_' + index + '_' + positionIndex" button-type="icon" icon="delete"
                                                    :tooltip-text = "combinedLabel(BUTTON_LABEL_DELETE, LABEL_POSITION_SINGULAR, false) "
                                                    @buttonClick="deletePosition(index, positionIndex)"/>
                                                </div>
                                                <!-- Position Expand/ Collapse Button -->
                                                <div class="ChildCardIconButton">
                                                  <themed-button :componentId="'toggle_position_card_' + index + '_' + positionIndex" button-type="icon" :is-disabled="false" 
                                                  icon="up-arrow" :rotate="true" :toggle-state="is_position_expanded[index]?.[positionIndex]"
                                                  :tooltip-text="is_position_expanded && is_position_expanded[index]?.[positionIndex] ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                                                  @buttonClick="togglePositionAccordion(index, positionIndex)"/>
                                                </div>
                                              </div>
                                              <!-- Position Details -->
                                              <div class="RegularCardSection" :class="is_position_expanded[index]?.[positionIndex] ? '':'collapsed'">
                                                <!-- Job Title -->
                                                <div class="RowAlignedLeft MarginTop" >
                                                  <div class="ChildCardRow">
                                                    <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="position.job_title"
                                                    :placeholder="combinedLabel(LABEL_ADJECTIVE_POSITION, LABEL_POSITION_SINGULAR, false)"
                                                    :id="'organization_'+index+'_position_title_'+positionIndex" required>  
                                                    <div class="InputLabel">
                                                      <div class="HorizontalGroup NoHorizontalSpace">
                                                        <div :for="'organization_'+index+'_position_title_'+positionIndex">{{LABEL_TITLE.toUpperCase()}}</div>
                                                        <div class="ErrorColor">*</div>
                                                      </div>
                                                    </div>   
                                                  </div>     
                                                </div>
                                                <!-- Start Date -->
                                                <div class="RowAlignedLeft MarginTop" >
                                                  <div class="ChildCardRow">
                                                    <input class="ThemedInput" type="text" :disabled="resume_in_progress" 
                                                    :placeholder="PLACE_HOLDER_DATE" v-model="position.job_start_date"
                                                    :id="'organization_'+index+'_position_start_'+positionIndex" required>   
                                                    <div class="InputLabel">
                                                      <div class="HorizontalGroup NoHorizontalSpace">
                                                        <div :for="'organization_'+index+'_position_start_'+positionIndex">{{LABEL_START_DATE.toUpperCase()}}</div>
                                                        <div class="ErrorColor">*</div>
                                                      </div>
                                                    </div>   
                                                  </div>    
                                                </div>
                                                <!-- End Date -->
                                                <div class="RowAlignedLeft MarginTop" >
                                                  <div class="ChildCardRow">
                                                    <input class="ThemedInput" type="text" :disabled="resume_in_progress" 
                                                    :placeholder="PLACE_HOLDER_END_DATE" v-model="position.job_end_date"
                                                    :id="'organization_'+index+'_position_end_'+positionIndex" required>  
                                                    <div class="InputLabel">
                                                      <div class="HorizontalGroup NoHorizontalSpace">
                                                        <div :for="'organization_'+index+'_position_end_'+positionIndex">{{LABEL_END_DATE.toUpperCase()}}</div>
                                                        <div class="ErrorColor">*</div>
                                                      </div>
                                                    </div>  
                                                  </div>      
                                                </div>
                                                <!-- Responsibilities -->
                                                <div class="RowAlignedLeft">
                                                  <label class="TextParagraph">{{ LABEL_RESPONSIBILITIES }}</label>
                                                </div>
                                                <div class="RowAlignedLeft MarginTop" >
                                                  <div class="ChildCardRow">
                                                    <textarea class="ThemedInput" :value="responsibilitiesText(index, positionIndex)" :disabled="resume_in_progress"
                                                    :placeholder="PLACE_HOLDER_RESPONSIBILITIES"
                                                    :id="'organization_'+index+'_position_role_'+positionIndex"
                                                    @input="updateResponsibilities(position.id, position.alt_content, position.comments, $event.target.value)" 
                                                    rows="5" required></textarea> 
                                                    <div class="InputLabel">
                                                      <div class="HorizontalGroup NoHorizontalSpace">
                                                        <div :for="'organization_'+index+'_position_role_'+positionIndex">{{LABEL_RESPONSIBILITIES.toUpperCase()}}</div>
                                                        <div class="ErrorColor">*</div>
                                                      </div>
                                                    </div> 
                                                  </div>      
                                              </div>
                                              </div>
                                            </li>
                                          </template>
                                        </draggable>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </draggable>
                        </div>
                      </div>
                      <!------------------------- Projects Accordion-->
                      <div class="RegularCard" :ref="sectionKey"
                        v-else-if="sectionKey === 'projects_and_assignments'">
                        <!-- Header: View State -->
                        <div class="CardHeaderRow DraggableHeader" :class="{'hide': section_edit_state[sectionKey]}" >
                          <div class="CardHeaderIndicator">
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <div class="CardHeaderIcon">
                            <icon :icon-name="sectionKey" :icon-stroke=1.5></icon>
                          </div>
                          <!-- Section Name -->
                          <div class="CardHeaderHorizontalGroup">
                            <label class="TextHeading1 TextTruncate">{{ userEditedResume[sectionKey]?.name || section_names_plural[sectionKey]}}</label>
                            <!-- Show Project Count When Collapsed -->   
                            <template v-if="!section_data[sectionKey].expanded">
                              <!-- Counts -->
                              <tooltip 
                                :text="getCountTooltip(itemCounts(sectionKey).total, section_names_singular[sectionKey], section_names_plural[sectionKey], false)" 
                                horizontal-align="left">
                                <div class="CountBubble">
                                  {{ itemCounts(sectionKey).total }}</div>
                              </tooltip>
                              <tooltip v-if="itemCounts(sectionKey).hidden > 0 && userEditedResume[sectionKey].hide" 
                                :text="getCountTooltip(itemCounts(sectionKey).hidden, section_names_singular[sectionKey], section_names_plural[sectionKey], true)"  
                                horizontal-align="left">
                                <div class="CountBubble AlertBackground ContrastColor" 
                                  :class="{'Clickable': sectionRecommendations(sectionKey)?.length > 0}"
                                  @click="scrollToComment('reco_' + sectionKey)">
                                  {{ itemCounts(sectionKey).hidden }}</div>
                              </tooltip>
                            </template>
                            <!-- Show Edit Button When Expanded -->
                            <div class="CardHeaderIcon" v-else>
                              <themed-button :componentId="'section-name-edit-' + sectionKey" button-type="icon" icon="edit"
                                :is-disabled="download_in_progress || !section_data[sectionKey].expanded" :tooltip-text="TOOLTIP_EDIT_NAME"
                                @buttonClick="handleButtonClick"/>
                            </div>
                          </div>
                          <!-- Show/ Hide Toggle Button -->
                          <div class="CardToggleButton">
                            <themed-button :componentId="'section-hide-' + sectionKey" button-type="switch" 
                              :toggle-state="!userEditedResume[sectionKey].hide"
                              :tooltip-text = "!userEditedResume[sectionKey]?.hide ? section_data[sectionKey].hideLabel : section_data[sectionKey].showLabel" 
                              :is-disabled="resume_in_progress"
                              @buttonClick="toggleSectionVisibility(sectionKey)"/>
                          </div> 
                          <!-- Expand / Collapse Button -->
                          <div class="CardIconButton">
                            <themed-button :componentId="'section-toggle-' + sectionKey" button-type="icon" :is-disabled="false" 
                              icon="circle-up-arrow" :rotate="true" 
                              :toggle-state="section_data[sectionKey].expanded"
                              :tooltip-text="section_data[sectionKey].expanded ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                              @buttonClick="toggleSectionAccordion(sectionKey)"/>
                          </div> 
                        </div>
                        <!-- Header: Edit State -->
                        <div class="CardHeaderRow DraggableHeader" :class="{'hide': !section_edit_state[sectionKey], 'edit': section_edit_state[sectionKey]}">
                          <div class="CardHeaderIndicator">
                            <!-- Draggable Indicator -->
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <!-- Section Name Input -->
                          <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="section_name_temp[sectionKey]" required>
                          <!-- Cancel Button -->
                          <div class="CardHeaderEditorConfirmation MarginLeft">
                              <themed-button :componentId="'section-name-cancel-' + sectionKey" button-type="secondary" icon="remove"
                                :tooltip-text="TOOLTIP_CANCEL"
                                @buttonClick="handleButtonClick"/>
                            </div>
                            <!-- Done Button -->
                          <div class="CardHeaderEditorConfirmation">
                            <themed-button :componentId="'section-name-save-' + sectionKey" button-type="primary" icon="check"
                            :tooltip-text="TOOLTIP_DONE" :is-disabled="section_name_temp[sectionKey]==''"
                            @buttonClick="handleButtonClick"/>
                          </div>
                        </div>
                        <!-- Prompts -->
                        <div class="RegularCardSection" :class="{ collapsed: !section_data[sectionKey].expanded }">
                          <div class="CardButtonRow">
                            <div class="TextDescription" >{{ section_data[sectionKey].generalInstructions }}</div>
                            <themed-button :componentId="'section-add-' + sectionKey + '-0'" button-type="secondary"
                              :label = "combinedLabel(BUTTON_LABEL_ADD, section_names_singular[sectionKey], true)"  width="fit"
                              :is-disabled="resume_in_progress"
                              @buttonClick="addProject(0)"/>
                          </div>
                          <!-- Sortable List of Projects -->
                          <ul class="CardList">
                            <draggable 
                              v-model="userEditedResume[sectionKey].projects"
                              :item-key="(project) => project.id" 
                              :group="{ name: 'items' }"
                              handle=".CardHeaderIndicator"
                              :animation="200">
                              <template #item="{ element: project, index: index }">
                                <li class="ChildCard">
                                  <!-- Project Card -->
                                  <div class="CardHeaderRow DraggableHeader">
                                    <!-- Draggable Indicator -->
                                    <div class="CardHeaderIndicator">
                                      <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                                        <icon icon-name="draggable"></icon>
                                      </div>
                                    </div>
                                    <!-- Project Title -->
                                    <div class="CardHeaderHorizontalGroup">
                                      <label class="TextParagraph ParagraphColor FontBold TextTruncate"> {{ project.title }}</label>
                                    </div>
                                    <!-- Project Show/ Hide toggle -->
                                    <div class="ChildCardToggleButton">
                                      <themed-button 
                                        :componentId="'project-hide-' + index"
                                        button-type="switch" 
                                        :toggle-state="!project.hide"
                                        :tooltip-text="project.hide ? TOOLTIP_SHOW : TOOLTIP_HIDE"
                                        :is-disabled="resume_in_progress"
                                        @buttonClick="toggleProjectVisibility(index)"
                                      />
                                    </div>
                                    <!-- Project Delete -->
                                    <div class="ChildCardIconButton">
                                      <themed-button :componentId="'delete_project' + index" button-type="icon" icon="delete"
                                        :tooltip-text = "combinedLabel(BUTTON_LABEL_DELETE, section_names_singular[sectionKey], false) "
                                        @buttonClick="deleteProject(index)"/>
                                    </div>
                                    <!-- Project Expand/ Collapse Button -->
                                    <div class="ChildCardIconButton">
                                      <themed-button :componentId="'toggle_project_card_' + index" button-type="icon" :is-disabled="false" 
                                      icon="up-arrow" :rotate="true" :toggle-state="is_project_expanded[index]"
                                      :tooltip-text="is_project_expanded && is_project_expanded[index] ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                                      @buttonClick="toggleProjectCard(index)"/>
                                    </div>
                                  </div>
                                  <!-- Project Details -->
                                  <div class="RegularCardSection" :class="is_project_expanded[index] ? '':'collapsed'">
                                    <!-- Project Title -->
                                    <div class="RowAlignedLeft MarginTop" >
                                      <div class="ChildCardRow">
                                        <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="project.title"
                                        :placeholder="combinedLabel(LABEL_ADJECTIVE_PROJECT, section_names_singular[sectionKey], false)"
                                        :id="'project_'+index" required>  
                                        <div class="InputLabel">
                                          <div class="HorizontalGroup NoHorizontalSpace">
                                            <div :for="'project_'+index">{{LABEL_PROJECT_TITLE.toUpperCase()}}</div>
                                            <div class="ErrorColor">*</div>
                                          </div>
                                        </div>   
                                      </div>     
                                    </div>
                                    <!-- Project Details -->
                                    <div class="RowAlignedLeft MarginTop" >
                                      <div class="ChildCardRow">
                                        <textarea class="ThemedInput" :value="projectText(index)" :disabled="resume_in_progress"
                                        :placeholder="PLACE_HOLDER_PROJECT_DETAILS"
                                        :id="'project_'+index"
                                        @input="updateProjectDetails(project.id, project.alt_content, project.comments, $event.target.value)" 
                                        rows="5" required></textarea> 
                                        <div class="InputLabel">
                                          <div class="HorizontalGroup NoHorizontalSpace">
                                            <div :for="'project_'+index">{{LABEL_PROJECT_DETAILS.toUpperCase()}}</div>
                                            <div class="ErrorColor">*</div>
                                          </div>
                                        </div> 
                                      </div>      
                                  </div>
                                  </div>
                                </li>
                              </template>
                            </draggable>
                          </ul>
                        </div>
                                  
                      </div>
                      <!------------------------- Professional Skills Accordion -->
                      <div class="RegularCard":ref="sectionKey"
                        v-else-if="sectionKey === 'professional_skills'">
                        <!-- Header: View State -->
                        <div class="CardHeaderRow DraggableHeader" :class="{'hide': section_edit_state[sectionKey]}" >
                          <div class="CardHeaderIndicator">
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <div class="CardHeaderIcon">
                            <icon :icon-name="sectionKey" :icon-stroke=1.5></icon>
                          </div>
                          <!-- Section Name -->
                          <div class="CardHeaderHorizontalGroup">
                            <label class="TextHeading1 TextTruncate">{{ userEditedResume[sectionKey]?.name || section_names_plural[sectionKey]}}</label>
                            <!-- Show Skill Group Count When Collapsed -->
                            <div class="CountBubble" v-if="!section_data[sectionKey].expanded && userEditedResume[sectionKey]?.skill_groups" >{{ userEditedResume[sectionKey]?.skill_groups.length }}</div>
                            <!-- Show Edit Button When Expanded -->
                            <div class="CardHeaderIcon" v-else-if="section_data[sectionKey].expanded">
                              <themed-button :componentId="'section-name-edit-' + sectionKey" button-type="icon" icon="edit"
                                :is-disabled="download_in_progress || !section_data[sectionKey].expanded" :tooltip-text="TOOLTIP_EDIT_NAME"
                                @buttonClick="handleButtonClick"/>
                            </div>
                          </div>
                          <!-- Show/ Hide Toggle Button-->
                          <div class="CardToggleButton">
                            <themed-button :componentId="'section-hide-' + sectionKey" button-type="switch" 
                              :toggle-state="!userEditedResume[sectionKey].hide"
                              :tooltip-text = "userEditedResume[sectionKey]?.hide ? section_data[sectionKey].showLabel : section_data[sectionKey].hideLabel" 
                              :is-disabled="resume_in_progress"
                              @buttonClick="toggleSectionVisibility(sectionKey)"/>
                          </div>
                          <!-- Expand / Collapse Button -->
                          <div class="CardIconButton">
                            <themed-button :componentId="'section-toggle-' + sectionKey" button-type="icon" :is-disabled="false" 
                              icon="circle-up-arrow" :rotate="true" 
                              :toggle-state="section_data[sectionKey].expanded"
                              :tooltip-text="section_data[sectionKey].expanded ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                              @buttonClick="toggleSectionAccordion(sectionKey)"/>
                          </div> 
                        </div>
                        <!-- Header: Edit State -->
                        <div class="CardHeaderRow DraggableHeader" :class="{'hide': !section_edit_state[sectionKey], 'edit': section_edit_state[sectionKey]}">
                          <div class="CardHeaderIndicator">
                            <!-- Draggable Indicator -->
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <!-- Section Name Input -->
                          <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="section_name_temp[sectionKey]" required>
                          <!-- Cancel Button -->
                          <div class="CardHeaderEditorConfirmation MarginLeft">
                              <themed-button :componentId="'section-name-cancel-' + sectionKey" button-type="secondary" icon="remove"
                                :tooltip-text="TOOLTIP_CANCEL"
                                @buttonClick="handleButtonClick"/>
                            </div>
                            <!-- Done Button -->
                          <div class="CardHeaderEditorConfirmation">
                            <themed-button :componentId="'section-name-save-' + sectionKey" button-type="primary" icon="check"
                            :tooltip-text="TOOLTIP_DONE" :is-disabled="section_name_temp[sectionKey]==''"
                            @buttonClick="handleButtonClick"/>
                          </div>
                        </div>
                        <!-- Prompts -->
                        <div class="RegularCardSection" :class="{ collapsed: !section_data[sectionKey].expanded }">
                          <div class="CardButtonRow">
                            <div class="TextDescription" >{{ section_data[sectionKey].generalInstructions }}</div>
                            <themed-button :componentId="'section-add-' + sectionKey + '-0'" button-type="secondary"
                              :label = "combinedLabel(BUTTON_LABEL_ADD, section_names_singular[sectionKey], true)"  width="fit"
                              :is-disabled="resume_in_progress"
                              @buttonClick="addSkillGroup(0)"/>
                          </div>
                          <!-- Sortable List of Skill Groups -->
                          <ul class="CardList">
                            <draggable 
                              v-model="userEditedResume[sectionKey].skill_groups"
                              :item-key="(skill_group) => index" 
                              handle=".CardHeaderIndicator"
                              :animation="200">
                              <template #item="{ element: skill_group, index: index }">
                                <li class="ChildCard">
                                  <!-- Skill Group Card -->
                                  <div class="CardHeaderRow DraggableHeader">
                                    <!-- Draggable Indicator -->
                                    <div class="CardHeaderIndicator">
                                      <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                                        <icon icon-name="draggable"></icon>
                                      </div>
                                    </div>
                                    <!-- Skill Group Title -->
                                    <div class="CardHeaderHorizontalGroup">
                                      <label class="TextParagraph ParagraphColor FontBold TextTruncate"> {{ skill_group.title }}</label>
                                    </div>
                                    <div class="ChildCardToggleButton">
                                    </div>
                                    <!-- Skill Group Delete -->
                                    <div class="ChildCardIconButton">
                                      <themed-button :componentId="'delete_skill_group' + index" button-type="icon" icon="delete"
                                        :tooltip-text = "combinedLabel(BUTTON_LABEL_DELETE, section_names_singular[sectionKey], false) "
                                        @buttonClick="deleteSkillGroup(index)"/>
                                    </div>
                                    <!-- Skill Group Expand/ Collapse Button -->
                                    <div class="ChildCardIconButton">
                                      <themed-button :componentId="'toggle_skill_group_card_' + index" button-type="icon" :is-disabled="false" 
                                      icon="up-arrow" :rotate="true" :toggle-state="is_skill_group_expanded[index]"
                                      :tooltip-text="is_skill_group_expanded && is_skill_group_expanded[index] ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                                      @buttonClick="toggleSkillGroupCard(index)"/>
                                    </div>
                                  </div>
                                  <!-- Skill Group Details -->
                                  <div class="RegularCardSection" :class="is_skill_group_expanded[index] ? '':'collapsed'">
                                    <!-- Skill Group Title -->
                                    <div class="RowAlignedLeft MarginTop" >
                                      <div class="ChildCardRow">
                                        <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="skill_group.title"
                                        :placeholder="combinedLabel(LABEL_ADJECTIVE_SKILL_GROUP, section_names_singular[sectionKey], false)"
                                        :id="'skill_group_'+index" required>  
                                        <div class="InputLabel">
                                          <div class="HorizontalGroup NoHorizontalSpace">
                                            <div :for="'skill_group_'+index">{{LABEL_PROJECT_TITLE.toUpperCase()}}</div>
                                            <div class="ErrorColor">*</div>
                                          </div>
                                        </div>   
                                      </div>     
                                    </div>
                                    <!-- List of Skills Card -->
                                    <div class="GrandChildCard" >
                                      <div class="CardHeaderRow">
                                        <div class="CardHeaderHorizontalGroup ChildCardHeaderWidth">
                                          <label class="TextHeading2 FontBold TextTruncate">{{ LABEL_SKILL_PLURAL}}</label>
                                          <div class="CountBubble" v-if="skill_group.items_array" >{{ skill_group.items_array.length }}</div>
                                        </div>
                                        <!-- Skill Group Skills Card Expand/ Collapse Button -->
                                        <div class="ChildCardIconButton">
                                          <themed-button :componentId="'toggle_skill_group_skills_card_' + index" button-type="icon" :is-disabled="false" 
                                          icon="up-arrow" :rotate="true" :toggle-state="is_skill_group_skills_card_expanded[index]"
                                          :tooltip-text="is_skill_group_skills_card_expanded && is_skill_group_skills_card_expanded[index] ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                                          @buttonClick="toggleSkillGroupSkillsCard(index)"/>
                                        </div>
                                      </div>
                                      <!-- List of skills -->
                                      <div class="RegularCardSection" :class="is_skill_group_skills_card_expanded[index] ? '':'collapsed'">
                                        <!-- Add Skill Button -->
                                        <div class="CardButtonRow">
                                          <div class="TextDescription">{{ combinedLabel(BUTTON_LABEL_UPDATE, LABEL_SKILL_PLURAL.toLowerCase()), false }}</div>
                                          <themed-button :componentId="'add_skill_'+index+'_0'" button-type="secondary"
                                          :label = "combinedLabel(BUTTON_LABEL_ADD, LABEL_SKILL_SINGULAR, true)"  width="fit"
                                          :is-disabled="resume_in_progress"
                                          @buttonClick="addSkill(index, 0)"/>
                                        </div>
                                        <!-- Draggable List -->
                                        <draggable class="List MarginBottom"
                                          v-if="skill_group.items_array?.length >0"
                                          v-model="skill_group.items_array" 
                                          :item-key="(skillIndex) => skillIndex" 
                                          handle=".CardHeaderIndicator"
                                          :animation="200">
                                            <template #item="{ index: skillIndex }">
                                              <li class="RowAlignedLeft NoVerticalPadding">
                                                <!-- Draggable Indicator -->
                                                <div class="CardHeaderIndicator NoMargin">
                                                  <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                                                    <icon icon-name="draggable"></icon>
                                                  </div>
                                                </div>
                                                <!-- String-->
                                                <div class="HorizontalGroup" >
                                                  <input class="ThemedInput" type="text" :placeholder="combinedLabel(LABEL_ADJECTIVE_GENERIC, LABEL_SKILL_SINGULAR, false)"
                                                    :disabled="resume_in_progress" v-model="skill_group.items_array[skillIndex]">
                                                </div>
                                                <!-- Delete Button -->
                                                <div class="ChildCardIconButton">
                                                  <themed-button :componentId="'skill-delete-' + index + '-' + (skillIndex)" button-type="icon" icon="delete"
                                                    :tooltip-text = "combinedLabel(BUTTON_LABEL_DELETE, LABEL_SKILL_SINGULAR, false) "
                                                    :is-disabled="resume_in_progress"
                                                    @buttonClick="deleteSkill(index, skillIndex)"/>
                                                </div>
                                              </li>
                                            </template>
                                        </draggable>
                                      </div>
                                    </div>

                                  </div>
                                </li>
                              </template>
                            </draggable>
                          </ul>
                        </div>                             
                      </div>
                      <!-- Generic Section Accordion -->
                      <div class="RegularCard" :ref="sectionKey" v-else>
                        <!-- Header - View State -->
                        <div class="CardHeaderRow DraggableHeader" :class="{'hide': section_edit_state[sectionKey]}" >
                          <!-- Draggable Indicator -->
                          <div class="CardHeaderIndicator">
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <div class="CardHeaderIcon">
                            <icon :icon-name="sectionKey" :icon-stroke=1.5></icon>
                          </div>
                          <div class="CardHeaderHorizontalGroup">
                            <div class="TextHeading1 TextTruncate"> 
                              {{ userEditedResume[sectionKey]?.name || section_names_plural[sectionKey] }}
                            </div>
                            <!-- Show Item Counts When Collapsed-->
                             <!-- non Date Section -->
                            <tooltip 
                              v-if="!section_data[sectionKey].expanded && !isDateSection(sectionKey) && userEditedResume[sectionKey]?.items_array"
                              :text = "getCountTooltip(userEditedResume[sectionKey]?.items_array?.length, section_names_singular[sectionKey], section_names_plural[sectionKey], false)">
                              <div class="CountBubble">
                                {{ userEditedResume[sectionKey]?.items_array?.length }}</div>
                            </tooltip>
                            <!-- date section -->
                            <template v-else-if="!section_data[sectionKey].expanded && isDateSection(sectionKey)">
                              <!-- Counts -->
                              <tooltip 
                                :text="getCountTooltip(itemCounts(sectionKey).total, section_names_singular[sectionKey], section_names_plural[sectionKey], false)" 
                                horizontal-align="left">
                                <div class="CountBubble">
                                  {{ itemCounts(sectionKey).total }}</div>
                              </tooltip>
                              <tooltip v-if="itemCounts(sectionKey).hidden > 0 && userEditedResume[sectionKey].hide" 
                                :text="getCountTooltip(itemCounts(sectionKey).hidden, section_names_singular[sectionKey], section_names_plural[sectionKey], true)"  
                                horizontal-align="left">
                                <div class="CountBubble AlertBackground ContrastColor"
                                :class="{'Clickable': sectionRecommendations(sectionKey)?.length > 0}" 
                                  @click="scrollToComment('reco_' + sectionKey)">
                                  {{ itemCounts(sectionKey).hidden }}</div>
                              </tooltip>
                            </template>
                            <!-- Show Edit Button When Expanded -->
                            <div class="CardHeaderIcon" v-else-if="section_data[sectionKey].expanded">
                              <themed-button :componentId="'section-name-edit-' + sectionKey" button-type="icon" icon="edit"
                                :is-disabled="download_in_progress || !section_data[sectionKey].expanded" :tooltip-text="TOOLTIP_EDIT_NAME"
                                @buttonClick="handleButtonClick"/>
                            </div>
                          </div>
                           <!-- Show/ Hide Toggle Button -->
                           <div class="CardToggleButton">
                            <themed-button :componentId="'section-hide-' + sectionKey" button-type="switch" 
                              :toggle-state="!userEditedResume[sectionKey].hide"
                              :tooltip-text = "!userEditedResume[sectionKey].hide ? section_data[sectionKey].hideLabel : section_data[sectionKey].showLabel" 
                              :is-disabled="resume_in_progress"
                              @buttonClick="toggleSectionVisibility(sectionKey)"/>
                          </div> 
                          <!-- Expand / Collapse Button -->
                          <div class="CardIconButton">
                            <themed-button :componentId="'section-toggle-' + sectionKey" button-type="icon" :is-disabled="false" 
                              icon="circle-up-arrow" :rotate="true" 
                              :toggle-state="section_data[sectionKey].expanded"
                              :tooltip-text="section_data[sectionKey].expanded ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                              @buttonClick="toggleSectionAccordion(sectionKey)"/>
                          </div>               
                        </div>
                        <!-- Header: Edit State -->
                        <div class="CardHeaderRow DraggableHeader" :class="{'hide': !section_edit_state[sectionKey], 'edit': section_edit_state[sectionKey]}">
                          <div class="CardHeaderIndicator">
                            <!-- Draggable Indicator -->
                            <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                              <icon icon-name="draggable"></icon>
                            </div>
                          </div>
                          <!-- Section Name Input -->
                          <input class="ThemedInput" type="text" :disabled="resume_in_progress" v-model="section_name_temp[sectionKey]" required>
                          <!-- Cancel Button -->
                          <div class="CardHeaderEditorConfirmation MarginLeft">
                              <themed-button :componentId="'section-name-cancel-' + sectionKey" button-type="secondary" icon="remove"
                                :tooltip-text="TOOLTIP_CANCEL"
                                @buttonClick="handleButtonClick"/>
                            </div>
                            <!-- Done Button -->
                          <div class="CardHeaderEditorConfirmation">
                            <themed-button :componentId="'section-name-save-' + sectionKey" button-type="primary" icon="check"
                            :tooltip-text="TOOLTIP_DONE" :is-disabled="section_name_temp[sectionKey]==''"
                            @buttonClick="handleButtonClick"/>
                          </div>
                        </div>
                      <!-- Prompts -->
                      <div class="RegularCardSection" :class="{ collapsed: !section_data[sectionKey].expanded }">
                        <div class="CardButtonRow">
                          <div class="TextDescription" >{{ section_data[sectionKey].generalInstructions }}</div>
                          <themed-button :componentId="'section-add-' + sectionKey + '-0'" button-type="secondary"
                            :label = "combinedLabel(BUTTON_LABEL_ADD, section_names_singular[sectionKey], true)"  width="fit"
                            :is-disabled="resume_in_progress"
                            @buttonClick="addSectionItem(sectionKey,0)"/>
                          </div>
                          <!-- Draggable List of Items-->
                          <draggable class="List MarginBottom"
                            v-if="userEditedResume[sectionKey]?.items_array?.length >0"
                            v-model="userEditedResume[sectionKey].items_array" 
                            :group="{ name: 'items' }"
                            :item-key="index => `${sectionKey}-${index}`" 
                            handle=".CardHeaderIndicator"
                            :animation="200">
                              <template #item="{ element, index }">
                                <div class="RowAlignedLeft NoVerticalPadding">
                                  <!-- Draggable Indicator -->
                                  <div class="CardHeaderIndicator NoMargin">
                                    <div class="tooltip-trigger" :tooltip-text="INSTRUCTIONS_REORDER">
                                      <icon icon-name="draggable"></icon>
                                    </div>
                                  </div>
                                  <!-- Section with simple strings-->
                                  <div class="HorizontalGroup" v-if="!isDateSection(sectionKey)" >
                                    <input class="ThemedInput" type="text" :placeholder="combinedLabel(LABEL_ADJECTIVE_GENERIC, section_names_singular[sectionKey], false)"
                                      :disabled="resume_in_progress" v-model="element.item_name">
                                  </div>
                                  <!-- Section with dates -->
                                  <div class="HorizontalGroup" v-else>                           
                                    <div class="SectionNameField">
                                      <input class="ThemedInput" type="text" :placeholder="combinedLabel(INSTRUCTIONS_INPUT, section_names_singular[sectionKey], false)"
                                        :disabled="resume_in_progress" v-model="element.item_name">
                                    </div>
                                    <div class="SectionDateField">
                                      <input class="ThemedInput" type="text" :placeholder="PLACE_HOLDER_DATE"
                                        :disabled="resume_in_progress" v-model="element.date">
                                    </div>
                                    <!-- Show/ Hide Toggle Button for Date Sections Only-->
                                    <div class="ChildCardToggleButton">
                                      <themed-button :componentId="'section-item-hide-' + sectionKey + '-' + (index)" button-type="switch" 
                                        :toggle-state="!element.hide"
                                        :tooltip-text = "element.hide ? TOOLTIP_SHOW : TOOLTIP_HIDE" 
                                        :is-disabled="resume_in_progress"
                                        @buttonClick="toggleSectionItemVisibility(sectionKey, index)"/>
                                    </div>
                                  </div>
                                  <!-- Delete Button -->
                                  <div class="ChildCardIconButton">
                                    <themed-button :componentId="'section-delete-' + sectionKey + '-' + (index)" button-type="icon" icon="delete"
                                      :tooltip-text = "section_data[sectionKey].deleteLabel"
                                      :is-disabled="resume_in_progress"
                                      @buttonClick="deleteSectionItem(sectionKey,index)"/>
                                  </div>
                                </div>
                              </template>
                          </draggable>
                        </div>
                      </div>
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
          <!-- Preview Panel -->
          <div class="PreviewPanel" v-if="resume">
            <!-- Top Menu -->     
            <div class="StickyContainer BorderBottom ColumnDirection">
              <!-- Page Top Nav -->
              <div class="PageHeaderNav" v-if="resume">
                <!-- Cover Letter Button -->
                <div class="PageHeaderTextButton">
                  <themed-button componentId="cover_letter"
                    v-if="!is_editor_expanded"
                    button-type="secondary"
                    :label=LABEL_COVER_LETTER :is-disabled=download_in_progress width="fit"
                    @buttonClick="handleButtonClick"/>
                </div>
                <!-- Download Button -->
                <div class="PageHeaderTextButton">
                  <themed-button componentId="download_pdf" button-type="primary"
                      :label=TOOLTIP_DOWNLOAD :is-disabled=download_in_progress width="fit"
                    @buttonClick="handleButtonClick"/>
                </div>
              </div>
              <!-- Menu Buttons -->
              <div class="MenuTray" v-if="resume">
                <!-- Template Selector -->
                <Selector :default-value="selectedTemplate" :options="templateOptions" type="icon-text"
                :tooltip-text="TOOLTIP_TEMPLATE_SELECTOR" :icon-width="3" :icon-height="3"
                header-icon="theme-editor"
                component-id="template_selector"
                @option-selected="handleOptionSelected" />
                <!-- Color Selector -->
                <Selector :default-value="selectedColor" :options="colorOptions" type="color"
                header-icon="color-palette"
                :tooltip-text="TOOLTIP_COLOR_SELECTOR"
                component-id="color_selector"
                @option-selected="handleOptionSelected" />
                <!-- Layout Selector -->
                <Selector :default-value="selectedLayout" :options="layoutOptions" type="icon-text"
                :tooltip-text="TOOLTIP_LAYOUT_SELECTOR" :icon-width="3" :icon-height="3"
                header-icon="layout-editor"
                component-id="layout_selector"
                @option-selected="handleOptionSelected" />
                <!-- Icon Preference Selector -->
                <Selector :default-value="selectedIconOption" :options="iconOptions" type="icon-text"
                :tooltip-text="TOOLTIP_ICON_PREFERENCES" :icon-width="3" :icon-height="3"
                component-id="icon_selector" :is-disabled="!iconAvailable"
                @option-selected="handleOptionSelected" />
                <!-- Separator -->
                <div class="VerticalSeparator"></div>
                <!-- Font Selector -->
                <Selector :default-value="selectedFont" :options="fontOptions" type="font"
                :tooltip-text="TOOLTIP_FONT_SELECTOR"
                component-id="font_selector"
                @option-selected="handleOptionSelected" />
                <!-- Font Size Selector -->
                <Selector :default-value="selectedFontSize" :options="fontSizeOptions" type="number"
                :tooltip-text="TOOLTIP_FONT_SIZE_SELECTOR"
                component-id="font_size_selector"
                @option-selected="handleOptionSelected" /> 
                <!-- Density Selector -->
                <Selector :default-value="selectedDensity" :options="densityOptions" type="icon-text"
                :tooltip-text="TOOLTIP_DENSITY_SELECTOR"
                component-id="density_selector"
                @option-selected="handleOptionSelected" /> 
                <!-- Section Column Selector -->
                <Selector :default-value="selectedColumns" :options="columnOptions" type="number"
                :tooltip-text="TOOLTIP_COLUMN_SELECTOR" :is-disabled="selectedLayout === 'Center' ? false : true"
                component-id="column_selector"
                @option-selected="handleOptionSelected" /> 
                <!-- Separator -->
                <div class="VerticalSeparator"></div>
                <!-- Page Size Selector -->
                <Selector :default-value="selectedPageSize" :options="pageSizeOptions" type="icon-text"
                :tooltip-text="TOOLTIP_PAPER_SIZE_SELECTOR" :icon-width="3" :icon-height="3"
                component-id="page_size_selector"
                @option-selected="handleOptionSelected" /> 
                <!-- Zoom Selector -->
                <Selector :default-value="selectedZoom" :options="zoomOptions" type="percentage"
                :tooltip-text="TOOLTIP_ZOOM_SELECTOR"
                component-id="zoom_selector"
                @option-selected="handleOptionSelected" /> 
                <!-- Separator -->
                <div class="VerticalSeparator"></div>
                <!-- Right Aligned Buttons -->
                <div class="SubMenuRightButtonPanel">
                  <div class="VerticalSeparator"></div>
                  <!-- Home Button -->
                  <div class="IconContainerMini">
                    <themed-button componentId="input" button-type="icon" icon="previous"
                    :tooltip-text = "TOOLTIP_HOME" :is-disabled=download_in_progress align="right"
                    color="var(--gray-900)" @buttonClick="handleButtonClick"/>
                  </div>
                  <!-- Cover Letter Button -->
                  <div class="IconContainerMini">
                    <themed-button componentId="cover_letter" button-type="icon" icon="next"
                    :tooltip-text = "TOOLTIP_COVER_LETTER" :is-disabled=download_in_progress align="right"
                    color="var(--gray-900)" @buttonClick="handleButtonClick"/>
                  </div>
                  <!-- Print Preivew Button -->
                  <div class="IconContainerMini">
                    <themed-button componentId="print_preview" button-type="icon" :icon="show_comment_indicators ? 'printer' : 'chat-multi-line'"
                      :tooltip-text="show_comment_indicators ? TOOLTIP_PRINT_PREVIEW: TOOLTIP_SHOW_COMMENTS" :is-disabled=download_in_progress align="right"
                      color="var(--gray-900)" @buttonClick="handleButtonClick"/>
                  </div>
                  <!-- Save Button -->
                  <div class="IconContainerMini">
                    <themed-button componentId="save" button-type="icon" icon="save"
                      :tooltip-text=TOOLTIP_SAVE :is-disabled=download_in_progress align="right"
                      color="var(--gray-900)" @buttonClick="handleButtonClick"/>
                  </div>
                </div>
              </div>
            </div>
            <!-- Resume Preview + Comments -->
            <div class="ResumeAndCommentWrapper">
              <div class="ResumeAndCommentPanel">
                <!-- <div class="ResumeAndCommentScaffold" :style="{ 'width': `${650*scaleFactor*selectedZoom + 360 + 2*10}px` }"> -->
                <div class="ResumeAndCommentScaffold">
                  <!-- Resume -->
                  <div class="ResumePanel PageFormatting" ref="panelContainer">
                    <!-- <div class="ResumeContainer PageFormatting" :style="{ height:`${numPages* scaledPageHeight*scaleFactor*selectedZoom+ (numPages+1)*10}px` }"> -->
                    <div class="ResumeContainer" :style="{ 
                        'height': `${ scaleFactor * selectedZoom * (scaledPageHeight + 10) * numPages}px`, 
                        }">
                      <div class="_wrapper_1axvs_1" 
                      :style="{ 
                        'transform': `scale(${scaleFactor * selectedZoom})`, 
                        }">
                        <div class="_scaled_container">
                          <div class="_container_1axvs_20" 
                          :style="{
                            'height': `${( scaledPageHeight + 10 )* numPages }px`, //10px gap between pages
                            '--scale-factor': `${650 / selectedPageDimensions.width}`,
                            '--template-padding': `${templatePadding}px`,
                            '--color-primary': selectedColor,
                            '--density-factor': selectedDensity,
                            '--grid-density-factor':gridDensityFactor,
                            '--font-theme': `'${selectedFont}'`,
                            '--page-height': `${selectedPageDimensions.height}px`,
                            '--page-width': `${selectedPageDimensions.width}px`,
                            '--left-column': `${selectedLayoutSettings.left}px`,
                            '--right-column': `${selectedLayoutSettings.right}px`,
                            '--sidebar-background-width': selectedLayoutSettings.sidebar_bg_width,
                            '--sidebar-background-height': selectedLayoutSettings.sidebar_bg_height,
                            '--sidebar-background-font-alignment': selectedLayoutSettings.align_items,
                            '--key-left': keywordsPosition.left, 
                            '--key-right': keywordsPosition.right,
                            '--key-top': keywordsPosition.top,
                            '--key-width': keywordsPosition.width,
                            '--font-factor':selectedFontSize/12,
                          }">
                            <component
                              :is="selectedTemplateComponent" 
                              :user-edited-resume="userEditedResume" 
                              :page-layout="selectedLayout" 
                              :show-icon="showIcon"
                              :show-comment-indicators="show_comment_indicators"
                              :comments="comments"
                              :recommendations="recommendations"
                              :has-visible-organization= "hasVisibleOrganization"
                              :is-section-visible="isSectionVisible"
                              :is-next-section-visible="isNextSectionVisible"
                              :is-section-item-visible="isSectionItemVisible"
                              :section-recommendations="sectionRecommendations"
                              :overall-recommendation-contents="overallRecommendationContents"
                              :has-visible-skill-group="hasVisibleSkillGroup"
                              :get-skills="getSkills"
                              :section-has-comments="sectionHasComments"
                              :is-date-section="isDateSection"
                              :is-non-date-section="isNonDateSection"
                              ref="selectedTemplateComponent" />
                          </div> 
                        </div>           
                      </div>
                    </div>
                  </div>
                  <!-- Comments Panel - Show only if there are comments -->
                  <div class="CommentPanel" :class="!comment_panel_expanded? 'CommentCollapsed' : ''" ref="comment_panel"
                    v-if="comments.length>0 || recommendations.length>0">
                      <div class="CommentPanelHeader">
                        <!-- Tabs -->
                        <div class="CommentTabContainer">
                          <tab-button componentId="comment_panel" 
                            :hasRightSibling=true 
                            :isSelected= comment_tab
                            :isDisabled="comments.length ===0" 
                            :label=LABEL_COMMENTS
                            @tabClick="focusCommentTab(true)" />
                          <tab-button componentId="recommendation_panel" 
                            :hasRightSibling=false 
                            :isSelected="!comment_tab" 
                            :isDisabled="recommendations.length ===0"  
                            :label=LABEL_RECOMMENDATIONS 
                            @tabClick="focusCommentTab(false)" />
                          <!-- Notification Icon -->
                          <div class="NotificationBubble" v-if="!hasViewedRecommendations">
                            <icon icon-name="blinking-animation"/>
                          </div>
                        </div>
                        <!-- Toggle Button -->
                        <div class="CardIconButton NoRightMargin">
                            <themed-button componentId="toggle_comment_panel" button-type="icon" :is-disabled="false" 
                            icon="circle-down-arrow" :rotate="true" :toggle-state="comment_panel_expanded"
                            :tooltip-text="comment_panel_expanded? TOOLTIP_COLLAPSE : null"
                            @buttonClick="handleButtonClick"/>
                        </div>
                      </div>
                    <div class="CommentContainer" ref="comment_container">
                      <!-- Comments Tab -->
                      <div class="CommentScaffold" :class="comment_tab? 'DisplayOn' : 'DisplayOff'">
                          <!-- Overall Resume Changes -->
                          <div class="CommentCardWrapper" :class="resume_comment_classes" v-if="userEditedResume.id" 
                          :ref="userEditedResume.id">
                            <comment-card :title="LABEL_RESUME_CHANGES" :content="get_ats_comments" 
                              :componentId= "userEditedResume.id" type="ats_score"
                              :isVisible="getShowStatus(userEditedResume.id)"
                              :onFocus="getFocusStatus(userEditedResume.id)"
                              @buttonClick="handleButtonClick"/>
                          </div>
                          <!-- Organize Comments in the same order as section order -->
                           <!-- Show Comments only if the section is visible on the editor panel -->
                          <template v-for="(section) in userEditedResume.section_order.items_array" :key="section">
                            <!-- Position Comments -->
                            <template v-if="section === 'experiences'">
                              <template v-for="(experience, experienceIndex) in userEditedResume.experiences" :key="`experience-${experienceIndex}`"
                                v-if="userEditedResume.experiences?.length>0">
                                <template v-for="(position, positionIndex) in experience.positions" :key="`position-${positionIndex}`">
                                    <div class="CommentCardWrapper" v-if="position.id && position.comments?.length>0"
                                      :ref="position.id">
                                      <comment-card :title="position.job_title" :subTitle="experience.company_name"
                                      :content="getCommentContents(position)" 
                                      :componentId= "position.id"
                                      :isVisible="getShowStatus(position.id)"
                                      :onFocus="getFocusStatus(position.id)"
                                      :contentStatus ="getContentStatus(position.id)"
                                      @buttonClick="handleButtonClick"/>
                                  </div>
                                </template>
                              </template>
                            </template>
                            <!-- Project Comments -->
                            <template v-else-if="section === 'projects_and_assignments'">
                              <template v-for="(project,index) in userEditedResume.projects_and_assignments?.projects" :key="project.id"
                                v-if="userEditedResume.projects_and_assignments?.projects?.length>0">
                                <div class="CommentCardWrapper" v-if="project.comments?.length>0" :ref="project.id">
                                    <comment-card :title="project.title"
                                      :content="getCommentContents(project)" 
                                      :componentId= "project.id"
                                      :isVisible="getShowStatus(project.id)"
                                      :onFocus="getFocusStatus(project.id)"
                                      :contentStatus ="getContentStatus(project.id)"
                                      @buttonClick="handleButtonClick"/>
                                </div>
                              </template>
                            </template>
                            <!-- All Other Generic Section Comments -->
                            <template v-else-if="sections_with_comments.includes(section)">
                              <div class="CommentCardWrapper" v-if="getCommentContents(userEditedResume[section]) 
                                && (userEditedResume[section].items_array?.length > 0 || userEditedResume[section].skill_groups?.length > 0 || section === 'professional_summary')"
                                :ref="userEditedResume[section].id" >
                                <comment-card 
                                  :title="userEditedResume[section].name" 
                                  :content="getCommentContents(userEditedResume[section])" 
                                  :componentId="userEditedResume[section].id"
                                  :isVisible="getShowStatus(userEditedResume[section].id)"
                                  :onFocus="getFocusStatus(userEditedResume[section].id)"
                                  :contentStatus ="getContentStatus(userEditedResume[section].id)"
                                  @buttonClick="handleButtonClick"/>
                              </div>
                            </template>           
                          </template>
                          <!-- Section Order Comments -->
                          <div class="CommentCardWrapper" v-if="userEditedResume.section_order?.change_reason?.length>0" 
                            :ref="userEditedResume.section_order.id">
                            <comment-card :title="LABEL_RESUME_ORDER_CHANGES" 
                              :content="getCommentContents(userEditedResume.section_order)" 
                              :componentId= "userEditedResume.section_order.id" 
                              :isVisible="getShowStatus(userEditedResume.section_order.id)"
                              :onFocus="getFocusStatus(userEditedResume.section_order.id)"
                              @buttonClick="handleButtonClick"/>
                          </div>
                      </div>
                      <!-- Recommendations Tab -->
                      <div class="CommentScaffold" :class="comment_tab? 'DisplayOff' : 'DisplayOn'">
                        <template v-if="hasVisibleRecommendations || getOverallRecommendationContents"> 
                          <!-- Overall Recommendations -->
                          <div class="CommentCardWrapper" v-if="getOverallRecommendationContents" 
                              ref="reco_resume">
                              <comment-card 
                                :title=LABEL_ADDITTIONAL_RECOMMENDATIONS
                                componentId= "reco_resume" 
                                :content="getOverallRecommendationContents" 
                                type="recommendation"
                                recommendationKey="resume"
                                :isVisible="getShowStatus('reco_resume')"
                                :onFocus="getFocusStatus('reco_resume')"
                                @buttonClick="handleButtonClick"/>
                            </div>
                          <!-- Section Level Recommendations -->
                          <template v-for="(section) in userEditedResume.section_order.items_array" :key="section" >
                            <div class="CommentCardWrapper" 
                              v-if="sectionRecommendations(section)?.length>0 && getRecommendationById('reco_'+section)" 
                              :ref="'reco_' + section">
                              <comment-card 
                                :title="userEditedResume[section].name? userEditedResume[section].name: section_names_plural[section]"
                                :componentId= "'reco_' + section" 
                                :content="sectionRecommendations(section)" 
                                type="recommendation"
                                :recommendationKey=section
                                :sectionHideStatus = "section === 'experiences' ?userEditedResume.hide_experiences: userEditedResume[section].hide"
                                :isVisible="getShowStatus('reco_' + section)"
                                :onFocus="getFocusStatus('reco_' + section)"
                                @buttonClick="handleButtonClick"
                                @recommendationAction="handleRecommendationAction"/>
                            </div>
                          </template>
                        </template>
                        <div class="CommentPanelEmpty" v-else>
                          <div class="CommentPanelEmptyIcon">
                            <icon iconName="circle-check" iconStroke=0.5></icon>
                          </div>
                          <div class="TextHeading2"> {{ MESSAGE_RECOMMENDATIONS_EMPTY }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- Error State: If there's no content to display -->
          <div class="ErrorStateContainer"  :class="resume?'Hide':''">
            <div class="ErrorState">
              <div class="TextHeading2 FontBold">{{ resume_in_progress? LOADING_STATE_LINE1: ERROR_STATE_LINE1 }}</div>
              <Icon iconName="sorry"/>
              <div class="TextParagraph">{{ resume_in_progress? LOADING_STATE_LINE2: ERROR_STATE_LINE2 }}.</div>
                <themed-button componentId="home" button-type="secondary"
                :label = BUTTON_LABEL_HOME :isDisabled="false" width="fit"
                @buttonClick="handleButtonClick"/>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import SanFranciscoResumeTemplate from './resume_templates/SanFranciscoResumeTemplate.vue';
import NewYorkResumeTemplate from './resume_templates/NewYorkResumeTemplate.vue';
import LondonResumeTemplate from './resume_templates/LondonResumeTemplate.vue';
import BerlinResumeTemplate from './resume_templates/BerlinResumeTemplate.vue';
import TokyoResumeTemplate from './resume_templates/TokyoResumeTemplate.vue';
import './resume_templates/styles/SanFranciscoResumeStyles.css'; //Import the style sheet specific to this resume template 
import './resume_templates/styles/NewYorkResumeStyles.css'; //Import the style sheet specific to this resume template 
import './resume_templates/styles/LondonResumeStyles.css'; //Import the style sheet specific to this resume template 
import './resume_templates/styles/BerlinResumeStyles.css'; //Import the style sheet specific to this resume template 
import './resume_templates/styles/TokyoResumeStyles.css'; //Import the style sheet specific to this resume template 
import { mapGetters } from 'vuex';
import axios from 'axios';
import _ from 'lodash';
import draggable from 'vuedraggable';
import StepperButton from './ui_controls/StepperButton.vue'; 
import Icon from './ui_controls/Icon.vue'; 
import ThemedButton from './ui_controls/ThemedButton.vue'; 
import MenuButton from './ui_controls/MenuButton.vue';
import Tooltip from './ui_controls/Tooltip.vue';
import ToastMessage from './ui_controls/ToastMessage.vue';
import { toastHandler } from './composables/toastHandler';
import Selector from './ui_controls/Selector.vue'; 
import CommentCard from './content_components/CommentCard.vue'; 
import ResumeLeftNav from './ui_controls/ResumeLeftNav.vue';
import TabButton from './ui_controls/TabButton.vue';
import { ref } from 'vue'
import { resumeSectionProperties } from './composables/resumeSectionProperties';

export default{
  data() {
    const { showToast, removeToast } = toastHandler();
    return {
      toast: { showToast, removeToast }, // for handling toast messages
      userEditedResume: {}, // Local data property to store user-edited content
      generatedHideSettings: {}, //Hide settings for level sections as suggested by the LLM
      sectionProperties: null, // Resume section property composable functions
      resumePreferences: {}, // user's resume preferences
      initializing_preferences: false, // are resume preferences being initialized
      scaleFactor: 1.23076923, //The scale factor for the div class "_wrapper_1axvs_1"; assuming a min PreviewPanel width=820px => (820-20)/650=1.23076923
      windowWidth: window.innerWidth,
      //  ---------- TEMPLATE DESIGN ----------
      //The following variables relate to template design
      selectedTemplate: 'London', // Default selected template
      templateOptions: [  
        { label: 'Berlin', value: 'Berlin', icon: 'berlin', description: 'A classic layout with a creative twist' },
        { label: 'London', value: 'London', icon: 'london', description: 'A classic layout for all industries' },
        { label: 'New York', value: 'NewYork', icon: 'new-york', description: 'A modern layout with a contrast panel' },
        { label: 'San Francisco', value: 'SanFrancisco', icon: 'san-francisco', description: 'A clean, modern layout with an attitude' },
        { label: 'Tokyo', value: 'Tokyo', icon: 'tokyo', description: 'A modern layout with an accent panel' },
      ],
      selectedLayout: 'Center', //Whether it's a 2-column display and whether the column is on the left or right
      layoutOptions: [  
        { label: 'Left Panel', value: 'Left', icon: 'layout-left', description: 'Two column layout with a left panel' },
        { label: 'Right Panel', value: 'Right', icon: 'layout-right', description: 'Two column layout with a right panel' },
        { label: 'Single Column', value: 'Center', icon: 'layout-center', description: 'Classic one column layout' },
      ],
      hasSideBarBackground: false, //whether there's a sidebar with a contrasting background color
      templatePadding: 70, // the page level padding for the selected template
      selectedColor: '#04549d', // Default theme color
      colorOptions: [
        {label: 'Aquamarine', value: '#04549d'},
        {label: 'Amethyst', value: '#540964'},
        {label: 'Emerald', value: '#07571b'},
        {label: 'Ruby', value: '#a10639'},
        {label: 'Hematite', value: 'var(--gray-800)'},
        {label: 'Topaz', value: '#8B4513'},
      ],
      selectedFont: 'Lora', // Default theme font
      fontOptions: [
        {label: 'Arial', value: 'Arimo'},
        {label: 'Fira Sans', value: 'Fira Sans'},
        {label: 'Garamond', value: 'EB Garamond'},
        {label: 'Inter', value: 'Inter'},
        {label: 'Lora', value: 'Lora'},
        {label: 'Monsterrat', value: 'Monsterrat'},
        {label: 'Open Sans', value: 'Open Sans'},
        {label: 'Raleway', value: 'Raleway'},
        {label: 'Roboto', value: 'Roboto'},
      ],
      selectedFontSize: 10, //Default font size for resumes
      fontSizeOptions: [
        {label: '8', value: 8},
        {label: '9', value: 9},
        {label: '10', value: 10},
        {label: '11', value: 11},
        {label: '12', value: 12},
      ],
      selectedDensity: 0.8, //Default density or compactness factor
      densityOptions: [
        {label: 'Loose', value: 1.0, icon: 'density-loose'},
        {label: 'Regular', value: 0.9, icon: 'density-regular'},
        {label: 'Compact', value: 0.8, icon: 'density-compact'},
      ],
      selectedColumns: 2, //Default number of columns for resume sections
      columnOptions: [
        {label: '1', value: 1},
        {label: '2', value: 2},
        {label: '3', value: 3},
        {label: '4', value: 4},
      ],
      selectedPageSize: 'Letter', // Default print preview page size
      pageSizeOptions: [  
        { label: 'Letter', value: 'Letter', icon: 'letter', description: 'Layout optimized for Letter size paper' },
        { label: 'A4', value: 'A4', icon: 'a4', description: 'Layout optimized for A4 size paper' },
      ],
      showIcon: true, //whether to use icons to beautify the resume layout
      selectedIconOption: 'yes', //the string format of showIcon
      iconOptions: [  
        { label: 'Show Graphics', value: 'yes', icon: 'image', description: 'Use graphics to beautify the resume' },
        { label: 'No Graphics', value: 'no', icon: 'image-x', description: 'Do not use any graphics in the resume' },
      ],
      iconAvailable: true, //whether icons/ graphics are available for the resume layout
      selectedZoom: 1, //the resume zoom level
      zoomOptions: [
        {label: 'Fit', value: 0.50},
        {label: '75%', value: 0.75},
        {label: '90%', value: 0.90},
        {label: '100%', value: 1.00},
        {label: '125%', value: 1.25},
        {label: '150%', value: 1.50},
        {label: '200%', value: 2.00},
      ],
      selectedVersion: 1, //the resume version selected
      // ------------- PAGE SPLITTING ----------------
      //The following variables are required for splitting HTML into multiple pages
      selectedPageIndex: 0, //the page index selected in pagination selector
      pageIndex: 0, //the first page
      contentColumns: [], //store the contents of the content column
      sideBarColumns: [], //store the contents of the sidebar column
      lastNode: document.createElement('div'), //points to where nodes need to be added
      height: 0, //available height of the page to fit content into
      columnOrder: 1, //1=sidebar on left, 0 = sidebar on right
      numPages:1, //the number of pages in the resume
      availableHeights: { // space for fitting keywords
        sideBar: 0, //the white space height remaining in the side bar on the last page 
        centerColumn: 0, //the white space height remaining in the center column on the last page 
      },
      availableSideBarHeightTemp: 0, //temporary prop to comptue the white space remaining in the side bar of the page being split 
      availableCenterColumnHeightTemp: 0, //temporary prop to comptue the white space remaining in the center column of the page being split 
      keywordsPosition: { //location where keywords will be injected
          left: '', 
          right: '',
          top: '',
          width: '',
          height: '',
        },
      // ------------ API call related --------------------- 
      resume_in_progress: false, //When the resume generation call is in progress
      download_in_progress: false, //When the resume is downloading
      dismissable_toast_id: '', // id of the dismissable toast message
      // ------------- EDITOR STATE --------------------------
      // The following variables control the resume editor behavior
      is_editor_expanded: false, // whether the editor panel is expanded
      is_profile_expanded: false, // whether the profile accordion is expanded
      is_school_expanded: [], //array of toggle indicators for each school
      is_project_expanded: [], //array of toggle indicators for each project
      is_experience_expanded: false, // whether the experience accordion is expanded
      is_organization_expanded: [], //array of toggle indicators for each company
      is_org_position_card_expanded: [], //array of toggle indicators for the positions section for each company
      is_position_expanded: [], //array of toggle indicators for each position
      is_skill_group_expanded: [], //array of toggle indicators for each skill_group
      is_skill_group_skills_card_expanded: [], //array of toggle indicators for the skills list cards for each skill_group
      resume_sections: ['professional_summary', 'experiences', 'educational_qualifications', 'projects_and_assignments','professional_skills', 'awards_and_recognitions', 'publications_and_presentations','trainings_and_certifications', 'technical_tools', 'professional_associations', 'volunteer_experience', 'languages_spoken', 'hobbies_and_activities'], // List of sections in the resume
      sections_with_comments: ['professional_summary', 'experiences', 'projects_and_assignments','professional_skills', 'technical_tools', 'volunteer_experience','section_order'], // List of custom sections that can have comments
      section_data: {}, //contains toggle, visibility states and labels for customizable sections like skills, awards etc
      section_names_plural: {  //default section names
        professional_summary: 'Summary',
        experiences: 'Experiences',
        educational_qualifications: 'Educations',
        projects_and_assignments: 'Projects',
        professional_skills: 'Skill Categories',
        awards_and_recognitions: 'Awards',
        publications_and_presentations: 'Publications',
        trainings_and_certifications: 'Certifications',
        technical_tools: 'Tools',
        professional_associations: 'Professional Networks',
        volunteer_experience: 'Community Services',
        languages_spoken: 'Languages',
        hobbies_and_activities: 'Interests',
      },
      section_names_singular: {  //default section names
        professional_summary: 'Summary',
        experiences: 'Experience',
        educational_qualifications: 'Education',
        projects_and_assignments: 'Project',
        professional_skills: 'Skill Category',
        awards_and_recognitions: 'Award',
        publications_and_presentations: 'Publication',
        trainings_and_certifications: 'Certification',
        technical_tools: 'Tool',
        professional_associations: 'Professional Network',
        volunteer_experience: 'Community Service',
        languages_spoken: 'Language',
        hobbies_and_activities: 'Interest',
      },
      section_edit_state: {  //whether the section is being edited
        professional_summary: false,
        experiences: false,
        educational_qualifications: false,
        projects_and_assignments: false,
        professional_skills: false,
        awards_and_recognitions: false,
        publications_and_presentations: false,
        trainings_and_certifications: false,
        technical_tools: false,
        professional_associations: false,
        volunteer_experience: false,
        languages_spoken: false,
        hobbies_and_activities: false,
      },
      section_name_temp: {  //temporary variable to bind section name to
        professional_summary: '',
        experiences: '',
        educational_qualifications: '',
        projects_and_assignments: '',
        professional_skills: '',
        awards_and_recognitions: '',
        publications_and_presentations: '',
        trainings_and_certifications: '',
        technical_tools: '',
        professional_associations: '',
        volunteer_experience: '',
        languages_spoken: '',
        hobbies_and_activities: '',
      },
      // -------------- COMMENTS ---------------
      // The following variables control the resume comment card behavior
      comments: [], //array of comments displayed. Each comment has the following keys: id, show, focus, user_edited, selected_content
      recommendations: [], //array of recommendations displayed. Each recommendation has the following keys: id, show, focus
      show_comment_indicators: true, //whether to show comment indicators on the resume preview
      comment_panel_expanded: true, //whether the comment panel is expanded
      ats_comment: null, // this controls the comments that are displayed in the ATS score card
      comment_tab: true, // true = comment tab selected, false = recommendation tab selected
      // -------------- CONTENT ---------------
      LOADING_STATE_LINE1: 'Looking for your resume...',
      LOADING_STATE_LINE2: 'This can take a few seconds...',
      ERROR_STATE_LINE1: 'Oops! Something went wrong...',
      ERROR_STATE_LINE2: 'Let\'s start over again...',
      ERROR_PDF_EXPORT: 'There was an error generating your PDF resume. Please refresh/ reload the browser window and try again..',
      SUCCESS_PDF_EXPORT: 'Successfully downloaded your PDF resume.\nIf you don\'t see it check your browser\'s download settings. Ensure that automatic downloads aren\'t blocked for this site.',
      LABEL_HOME: 'Input',
      LABEL_HISTORY: 'History',
      LABEL_COVER_LETTER: 'Cover Letter',
      LABEL_EDITOR: 'Edit Resume',
      LABEL_PROFILE: 'Contact Information',
      LABEL_SOCIAL_PROFILE: '$$$ Profile', //Dynamic string for social media profiles
      LABEL_NAME: 'Name',
      LABEL_SUMMARY: 'Summary',
      LABEL_PROFESSIONAL_SUMMARY: 'Professional Summary',
      LABEL_EMAIL: 'Email',
      LABEL_PHONE: 'Phone',
      LABEL_CITY: 'City',
      LABEL_WEBSITE: 'Portfolio URL',
      LABEL_WEBSITE_LABEL: 'Portfolio Label',
      LABEL_OTHER_CONTACT: 'Social Media URL',
      LABEL_OTHER_CONTACT_LABEL: 'Social Media Handle',
      LABEL_EDUCATION_SINGULAR: 'Education',
      LABEL_EDUCATION_PLURAL: 'Educations',
      LABEL_SCHOOL: 'Institution',
      LABEL_DEGREE: 'Degree',
      LABEL_DATE_SINGULAR: 'Date',
      LABEL_START_DATE: 'Start Date',
      LABEL_END_DATE: 'End Date',
      LABEL_GPA: 'Award/ GPA',
      LABEL_EXPERIENCE: 'Experience',
      LABEL_ORGANIZATION_SINGULAR: 'Organization',
      LABEL_ORGANIZATION_PLURAL: 'Organizations',
      LABEL_ORGANIZATION_DESCRIPTION: 'Organization Description',
      LABEL_POSITION_SINGULAR: 'Position',
      LABEL_POSITION_PLURAL: 'Positions',
      LABEL_TITLE: 'Job Title',
      LABEL_RESPONSIBILITIES: 'Role & Responsibilities',
      LABEL_PROJECT_TITLE: 'Title',
      LABEL_PROJECT_DETAILS: 'Details',
      LABEL_ADJECTIVE_SCHOOL: 'A Premier $$$', //Used when new school is added where $$$ is a dynamic string that would be replaced
      LABEL_ADJECTIVE_DEGREE: 'A Prestigious $$$', //Used when new degree is added where $$$ is a dynamic string that would be replaced
      LABEL_ADJECTIVE_ORGANIZATION: 'A Leading $$$', //Used when new company is added where $$$ is a dynamic string that would be replaced
      LABEL_ADJECTIVE_POSITION: 'A Senior $$$', //Used when new position is added where $$$ is a dynamic string that would be replaced
      LABEL_ADJECTIVE_PROJECT: 'An Innovative $$$',
      LABEL_SKILL_SINGULAR: 'Skill',
      LABEL_SKILL_PLURAL: 'Skills',
      LABEL_ADJECTIVE_SKILL_GROUP: 'A Valuable $$$',
      LABEL_ADJECTIVE_GENERIC: 'Some $$$',
      LABEL_SECTION_NAME: 'Resume Section Header',
      LABEL_RESUME_CHANGES: 'ATS Keyword Score',
      LABEL_RESUME_ORDER_CHANGES: 'Resume Section Ordering',
      LABEL_ADD_SECTION: 'Add Section',
      LABEL_NO_COMMENTS: 'There are no comments to display...',
      LABEL_COMMENTS: 'Changes',
      LABEL_RECOMMENDATIONS: 'Recommendations',
      LABEL_ADDITTIONAL_RECOMMENDATIONS: 'Overall Recommendations',
      BUTTON_LABEL_HOME: 'Home',
      BUTTON_LABEL_NEXT: 'Next',
      BUTTON_LABEL_SHOW: 'Show $$$', //where $$$ is a dynamic string that would be replaced by the section name
      BUTTON_LABEL_HIDE: 'Hide $$$', //where $$$ is a dynamic string that would be replaced by the section name
      BUTTON_LABEL_SHOW_ALL: 'Show All $$$', //where $$$ is a dynamic string that would be replaced by the section name
      BUTTON_LABEL_HIDE_SOME: 'Hide Specified $$$', //where $$$ is a dynamic string that would be replaced by the section name
      BUTTON_LABEL_ADD: 'Add $$$', //where $$$ is a dynamic string that would be replaced by the section name
      BUTTON_LABEL_ADD_BELOW: 'Add $$$ Below', //where $$$ is a dynamic string that would be replaced by the section name
      BUTTON_LABEL_DELETE: 'Delete $$$', //where $$$ is a dynamic string that would be replaced by the section name
      BUTTON_LABEL_UPDATE: 'You can add and reorder $$$ below', //where $$$ is a dynamic string that would be replaced by the section name
      TOOLTIP_COLLAPSE: 'Minimize',
      TOOLTIP_SHOW: 'Show',
      TOOLTIP_HIDE: 'Hide',
      TOOLTIP_HIDDEN: 'Hidden',
      TOOLTIP_OFF_EDITOR: 'Remove From Editor',
      TOOLTIP_EXPAND:'Expand',
      TOOLTIP_ADD_SECTION: 'Add A New Section To Resume',
      TOOLTIP_REMOVE_SECTIONS: 'Remove Unused Sections',
      TOOLTIP_EDIT_NAME:'Edit Section Name',
      TOOLTIP_CANCEL:'Cancel',
      TOOLTIP_DONE:'Done',
      TOOLTIP_DOWNLOAD: 'Download',
      TOOLTIP_SAVE: 'Save',
      TOOLTIP_PRINT_PREVIEW: 'Print Preview',
      TOOLTIP_SHOW_COMMENTS: 'Show Changes',
      TOOLTIP_TEMPLATE_SELECTOR: 'Resume Style',
      TOOLTIP_COLOR_SELECTOR: 'Color',
      TOOLTIP_LAYOUT_SELECTOR: 'Resume Layout',
      TOOLTIP_ICON_PREFERENCES: 'Background Graphics',
      TOOLTIP_FONT_SELECTOR: 'Font',
      TOOLTIP_FONT_SIZE_SELECTOR: 'Font Size',
      TOOLTIP_DENSITY_SELECTOR: 'Line & Paragraph Spacing',
      TOOLTIP_COLUMN_SELECTOR: 'Section (e.g. Skills) Columns',
      TOOLTIP_PAPER_SIZE_SELECTOR: 'Paper Size',
      TOOLTIP_ZOOM_SELECTOR: 'Zoom',
      TOOLTIP_HOME: 'View Resume Match',
      TOOLTIP_RESUME: 'Resume',
      TOOLTIP_EXPAND_EDITOR: 'Show Resume Editor',
      TOOLTIP_HIDE_EDITOR: 'Hide Resume Editor',
      TOOLTIP_COVER_LETTER: 'View Cover Letter',
      TOOLTIP_SHOW_ALL_DATES: 'Show Dates for All Educations',
      TOOLTIP_HIDE_ALL_DATES: 'Use Education Level Date Settings',
      PLACE_HOLDER_NAME: 'Your name as you\'d like to appear on your resume e.g. Gavin Belson',
      PLACE_HOLDER_EMAIL: 'The email address where you\'d like the employer to contact you e.g. gavin.belson@hooli.com',
      PLACE_HOLDER_PHONE: 'The phone number where you\'d like the employer to contact you e.g. 555-123-4567',
      PLACE_HOLDER_CITY: 'The city where you are currently located or would like to relocate to for the job',
      PLACE_HOLDER_LINKEDIN: 'Your LinkedIn profile e.g. linkedin.com/in/gavin-belson',
      PLACE_HOLDER_WEBSITE: 'URL of your personal website e.g. gavinbelson.com',
      PLACE_HOLDER_WEBSITE_LABEL: 'Display name of your personal website e.g. Portfolio',
      PLACE_HOLDER_GITHUB: 'Your GitHub profile e.g. github.com/gavinb01',
      PLACE_HOLDER_SOCIAL: 'URL for other social media profile e.g. twitter.com/gavin_belson',
      PLACE_HOLDER_SOCIAL_LABEL: 'Display laybel for social media profile e.g. @gavin_beslon',
      PLACE_HOLDER_DATE: 'E.g. Jun 2024',
      PLACE_HOLDER_END_DATE: 'E.g. Present or Oct 2023',
      PLACE_HOLDER_GPA: 'E.g. Summa cum laude, or Phi Beta Kappa, or 3.5/4.0',
      PLACE_HOLDER_SUMMARY: 'Highlight your achievements, skills and unique experiences matching the job requirements to demonstrate why you are an ideal candidate for this job.',
      PLACE_HOLDER_ORGANIZATION_DESCRIPTION: 'Optional: Enter a one line description of the organization',
      PLACE_HOLDER_RESPONSIBILITIES: '- Enter your responsibilities and achievents for this position.\n- Try to include measurable outcomes.\n- Use \'enter\' or \'return\' to create a new bullet point',
      PLACE_HOLDER_PROJECT_DETAILS: 'Enter project details. Use a new line for each bullet point.',
      MESSAGE_DOWNLOAD_IN_PROGRESS: 'Downloading your resume.\nThe PDF version of your tailored resume should be available shortly.\nEnsure that automatic downloads aren\'t blocked for this site.',
      MESSAGE_PAGE_INSTRUCTIONS: 'You can edit the contents of your resume by clicking on on the left navigation menu.\n You can change the style, layout and color of your resume using the formatting options on the top menu row.',
      MESSAGE_RESUME_SAVED: 'Resume and preferences successfully saved',
      MESSAGE_SCORE_UPDATED: 'We have re-calculated the ATS keyword score for your resume',
      MESSAGE_ERROR_GENERAL: 'Oops! Something went wrong.\nPlease reload the browser tab and try again',
      MESSAGE_RECOMMENDATIONS_EMPTY: 'You\'re all caught up!',
      INSTRUCTIONS_CONTACT_CARD: 'Provide your contact information. You can also add your web and social information to showcase your skills and achivements.',
      INSTRUCTIONS_SUMMARY_CARD: 'Summarize your career highlights below.',
      INSTRUCTIONS_REORDER: 'Drag And Reorder',
      INSTRUCTIONS_INPUT: 'Enter $$$',
    };    
  },
  mounted() {
    // This code block is called when the page components have fully mounted
    // Listen to the resize event of the window and call the updateScaleFactor method if the window size changes
    // window.addEventListener('resize', this.updateScaleFactor); 
    // this.updateScaleFactor(); //Update the scale factor the first time the page is loaded
  },
  beforeUnmount() {
    // Remove the event listener when the component is destroyed
    // try {
    //   window.removeEventListener('resize', this.updateScaleFactor);
    // } catch (error) {
    //     console.error('Error removing event listener: '+error);
    //   }     
  },
  computed: {
    applicationId() {
      console.log('Resume.vue application id= '+this.$route.query.application_id);
      return this.$route.query.application_id;
    },
    ...mapGetters(['getApplicationJSONByVersion']),
    applicationJSONVue: function () {
      return this.getApplicationJSONByVersion(this.selectedVersion);
    },
    ...mapGetters(['getResumePreferences']), //get resume preferences from Vue
    ...mapGetters(['previousRoute']), //get previous page name
    ...mapGetters(['routeOrigin']), //get route origin page name
    ...mapGetters(['hasViewedRecommendations']), //get the value of viewedRecommendations
    // Access the user-specific resume from VueX store based on application_id
    resume() {
      return this.applicationJSONVue?.resume || null;
    },
    // **--- COMPOSABLE FUNCTIONS ----**
    experienceCounts() {
      return this.sectionProperties?.experienceCounts || {visible: 0,hidden: 0,total: 0};
    },
    hasVisibleOrganization() {
      return this.sectionProperties?.hasVisibleOrganization || (() => false);
    },
    experiencePositionCounts() {
      return this.sectionProperties?.experiencePositionCounts || ( () => ({visible: 0,hidden: 0,total: 0}) );
    },
    getExperiencePositionIndex() {
      return this.sectionProperties?.getExperiencePositionIndex || ( () => ({experienceIndex: -1,positionIndex: -1}) );
    },
    overallRecommendationContents() {
      return this.sectionProperties?.overallRecommendationContents || null;
    },
    hasVisibleRecommendations() {
      return this.sectionProperties?.hasVisibleRecommendations || false;
    },
    itemCounts() {
      return this.sectionProperties?.itemCounts || ( () => ({visible: 0,hidden: 0,total: 0}) );
    },
    getItemIndex() {
      return this.sectionProperties?.getItemIndex || ( () => -1 );
    },
    isSectionVisible() {
      return this.sectionProperties?.isSectionVisible || (() => false);
    },
    isNextSectionVisible() {
      return this.sectionProperties?.isNextSectionVisible || (() => false);
    },
    isSectionItemVisible() {
      return this.sectionProperties?.isSectionItemVisible || (() => false);
    },
    sectionRecommendations() {
      return this.sectionProperties?.sectionRecommendations || (() => []);
    },
    hasVisibleSkillGroup() {
      return this.sectionProperties?.hasVisibleSkillGroup || (() => false);
    },
    getSkills() {
      return this.sectionProperties?.getSkills || (() => '');
    },
    sectionHasComments() {
      return this.sectionProperties?.sectionHasComments || (() => false);
    },
    isDateSection() {
      return this.sectionProperties?.isDateSection || (() => false);
    },
    isNonDateSection() {
      return this.sectionProperties?.isNonDateSection || (() => false);
    },
    // **---- END OF COMPOSABLE FUNCTIONS -----**
    getLabelHome() {
      return this.routeOrigin === 'Home'? this.LABEL_HOME : this.LABEL_HISTORY;
    },
    coverLetterAvailable() {
      if (this.applicationJSONVue && this.applicationJSONVue.cover_letter) {
        return true;
      } else return false;
    },
    //Convert the responsibilities array into a string with each item separated by a new line
    responsibilitiesText() {
      return (experienceIndex, positionIndex) => {
        const responsibilities =
          this.userEditedResume.experiences[experienceIndex]?.positions[
            positionIndex
          ]?.job_responsibilities || [];
        return responsibilities.join("\n");
      };
    },
    //Convert the project details array into a string with each item separated by a new line
    projectText() {
      return (index) => {
        const responsibilities =
          this.userEditedResume.projects_and_assignments.projects[index]?.items_array|| [];
        return responsibilities.join("\n");
      };
    },
    /**
     * @returns {Array} An array of objects for each section that's not shown in the resume editor.
     * Each object has the following properties:
     * - id: The resume section key.
     * - label: The section name in the resume.
     * - icon: The section icon identifier.
     * 
     * If no sections are available, the method returns null.
     */
    availableSections() {
      if (!this.section_data || !this.userEditedResume.section_order.items_array) {
        return null;
      }
      console.log('Resume.vue available sections= ', this.userEditedResume.section_order.items_array);
      const filteredSections = this.userEditedResume.section_order.items_array
        .filter(section => {
          console.log('Section= ', section );
          console.log('Section exists in section_data = ', this.section_data.hasOwnProperty(section)); 
          console.log('showOnEditor= ', this.section_data[section].showOnEditor);
          console.log('Section name in resume= ', this.userEditedResume[section].name );
          return this.section_data[section] && 
                this.section_data[section].showOnEditor === false;
        })
        .map(section => ({
          id: section,
          label: this.userEditedResume[section].name,
          icon: section
        }));
      
      console.log('Resume.vue available sections= ', filteredSections);
      return filteredSections.length > 0 ? filteredSections : null;
    },
    filteredSectionOrder() {
      const specialSections = ['professional_summary', 'experiences','educational_qualifications'];
      const sectionOrderCopy = [...this.userEditedResume.section_order.items_array];
      // Remove the special sections from the section order
      return sectionOrderCopy.filter(
        section => !specialSections.includes(section)
      );
    },   
    selectedTemplateComponent() {
      //Return the selected template component based on the option selected by the user
      //Additionally, store the path to the external style sheet for the selected template into the selectedStyleSheet variable
      switch (this.selectedTemplate) {
        case 'Tokyo':
          this.hasSideBarBackground=true;
          this.templatePadding=50;
          this.iconAvailable=false;
          return TokyoResumeTemplate;
        case 'SanFrancisco':
          this.templatePadding=70;
          this.hasSideBarBackground=false;
          this.iconAvailable=true;
          return SanFranciscoResumeTemplate;
        case 'NewYork':
          this.hasSideBarBackground=true;
          this.templatePadding=50;
          this.iconAvailable=false;
          return NewYorkResumeTemplate;
        case 'London':
          this.templatePadding=40;
          this.hasSideBarBackground=false;
          this.iconAvailable=false;
          return LondonResumeTemplate;
        case 'Berlin':
          this.templatePadding=40;
          this.hasSideBarBackground=false;
          this.iconAvailable=true;
          return BerlinResumeTemplate;
        default:
          this.templatePadding=70;
          this.hasSideBarBackground=false;
          return SanFranciscoResumeTemplate;
      }
    },
    selectedPageDimensions () {
      // Set the height and width of the page and the container
      switch (this.selectedPageSize) {
        case 'A4':
          return {
            width: 794, //page width
            height: 1122, //page height
          };
          default:
            //By default return the dimensions for letter
            return {
              width: 816, //page width
              height: 1055.81, //page height
            }
      }
    },
    selectedLayoutSettings () {
      // Return the settings corresponding to the selected layout
      switch (this.selectedLayout) {
        case 'Left':
          this.columnOrder= 1;
          return {
            left: 0, //left margin if layout has a background color of the sidebar columnn
            right: 'unset',
            align_items:'flex-start',
            sidebar_bg_width: '280px',
            sidebar_bg_height: '100%',
          };
          case 'Right':
            this.columnOrder=0;
            return {
              left: 'unset', 
              right: 0, //right margin if layout has a background color of the sidebar columnn
              align_items:'flex-start',
              sidebar_bg_width: '280px',
              sidebar_bg_height: '100%',
            };
          case 'Center':
            this.columnOrder=0;
            return {
              left: 0, 
              right: 0, //right margin if layout has a background color of the sidebar columnn
              align_items:'flex-end',
              sidebar_bg_width: '100%',
              sidebar_bg_height: '130px',
            };
          default:
            //By default return the settings for left
            return {
              left: 0, //left margin if layout has a background color of the sidebar columnn
              right: 'unset',
              align_items:'flex-start',
              sidebar_bg_width: '280px',
              sidebar_bg_height: '100%',
            }
      }
    },
    gridDensityFactor()
    {
      switch (this.selectedColumns) {
        case 1: return ('repeat(1, 100%)');
        case 3: return ('repeat(3, 33.33%)');
        case 4: return ('repeat(4, 25%)');
        default: return ('repeat(2, 50%)');
      }
    },
    scaledPageHeight () {
      const scaledHeight = (650/this.selectedPageDimensions.width ) * this.selectedPageDimensions.height;
      return scaledHeight; 
    },
    resume_comment_classes (){
      let class_list=[];
      if (!this.userEditedResume.id || !this.getShowStatus(this.userEditedResume.id) ) class_list.push('hide');
      if (this.getFocusStatus(this.userEditedResume.id) ) class_list.push('focus');
      return class_list;
    },
    //get the contents of the ATS comment card
    get_ats_comments(){
      return (this.ats_comment || this.userEditedResume.comments);
    },
    // Returns the professional summary as a string
    flattenedSummary: {
      get() {
        // When array is displayed in textarea, join with space
        return Array.isArray(this.userEditedResume.professional_summary.summary) 
          ? this.userEditedResume.professional_summary.summary.join(' ')
          : this.userEditedResume.professional_summary.summary || '';
      },
      set(value) {
        // When updating the value, split by periods to maintain array structure
        console.log('Setting value into professional summary: '+value);
        this.userEditedResume.professional_summary.summary = value;
      }
    },
    getCommentContents() {
      return function(resumeComponent) {
        if (!resumeComponent) return null;
        // Helper function to check if array has non-empty strings
        const hasNonEmptyString = (arr) => {
          return Array.isArray(arr) && arr.some(item => typeof item === 'string' && item.trim() !== '');
        };
        // Check whether the 'comments' and 'change_reason' arrays have valid content
        const hasValidChangeReason = hasNonEmptyString(resumeComponent.change_reason);
        const hasValidComments = hasNonEmptyString(resumeComponent.comments);
        // If neither array has valid content, return null
        if (!hasValidChangeReason && !hasValidComments) {
          return null;
        }
        // Otherwise, create a result object with only valid arrays
        const result = {};
        if (hasValidChangeReason) {
          result.change_reason = resumeComponent.change_reason;
        }
        if (hasValidComments) {
          result.comments = resumeComponent.comments;
        }
        return result;
      };
    },
    //Get the contents of the overall recommendation card
    getOverallRecommendationContents() {
      const contents =  this.overallRecommendationContents;
      if ( contents) {
        return {
          ...contents,
          num_pages: this.numPages
        }; // Add num_pages property
      } else return null;
    },
    /**
     * Returns the width of the editor panel.
     * @return {string} 100% if the editor is expanded, otherwise 4.25rem.
     */
    editor_width() {
      if (this.is_editor_expanded)return '100%';
      else return '4.25rem';
    },
    /**
     * Calculates the width of the content container by adding the width of the
     * editor panel, resume panel, and comment panel.
     * @return {string} A CSS calc expression for the width of the content container.
     */
    content_container_width() {
      const editor_panel_width = this.is_editor_expanded? 'var(--editor-panel-max-width)' : '4.25rem';
      const resume_panel_width = `${650*this.scaleFactor*this.selectedZoom + 2*20}px`; //20px of padding on each side
      const comment_panel_width = '380px';
      const content_container_width = `calc(${editor_panel_width} + ${resume_panel_width} + ${comment_panel_width})`;
      return content_container_width;
    },
    /**
     * Returns the width of the editor panel.
     * @return {string} 100% if the editor is expanded, otherwise 4.25rem.
     */
    editor_width() {
      if (this.is_editor_expanded)return '100%';
      else return '4.25rem';
    },
    /**
     * Calculates the width of the content container by adding the width of the
     * editor panel, resume panel, and comment panel.
     * @return {string} A CSS calc expression for the width of the content container.
     */
    content_container_width() {
      const editor_panel_width = this.is_editor_expanded? 'var(--editor-panel-max-width)' : '4.25rem';
      const resume_panel_width = `${650*this.scaleFactor*this.selectedZoom + 2*20}px`; //20px of padding on each side
      const comment_panel_width = '380px';
      const content_container_width = `calc(${editor_panel_width} + ${resume_panel_width} + ${comment_panel_width})`;
      return content_container_width;
    }
  },
  async created() {
    console.log('Resume.vue navigated from page: ' + this.previousRoute + ' , with route originating from: '+this.routeOrigin);
    this.debounceSplitPages = _.debounce(this.processAndSplitPages, 250); //Add a 500 mili second delay before triggering split pages (to give some time for the user to finish their keystroke)
    if (this.applicationId === null || this.applicationId === undefined || this.applicationId==='') {
      console.log('Application ID not present in URL. Navigating home...');
      //If application_id param isn't present in the URL 
      this.navigateTo('Home') // Return the user to Home screen
      return;
    } else {
      // i.e. if application_id param is present in the URL
      if (!this.resume) {
        //If resume isn't found in Vue store
        // Get the application details from the server
        console.log('Resume for application '+this.applicationId+' is not available in Vue store. Fetching application details from server');
        const isApplicationJSONPresent = await this.getApplicationFromServer();
        if (!isApplicationJSONPresent) {
          // If application isn't present in the server either
          console.log('Application JSON not present..navingating to home screen..');
          setTimeout(() => {
            this.navigateTo('Home'); // Return the user to Home screen 
          }, 3000); // After a 3 second delay
          return;
        } else { //i.e. if application id is present is server          
          console.log('Resume found in the server..');
          await this.initializeToggleArrays(); //initialize the toggle cards
          await this.initializeCommentsArray(); //initialize the comments array
          await this.initializeSectionData(); //initialize the section labels and toggle states
        }
      } else {
        //If resume is present in the Vue store
        console.log('Resume found in Vue store. Fetching resume from Vue store');
        // Set the resume and resume preferences from Vue store
        if (this.getResumePreferences) {
          this.resumePreferences = this.getResumePreferences; //get resume preferences from Vue store
          await this.initializeResumeSettings(); //initialize the resume settings
        }
        const resumeJSON = this.resume;
        await this.$nextTick(); // Wait for DOM to update
        this.userEditedResume = resumeJSON; //Set the contents of userEditedResume to use in the DOM

        this.initializeSectionProperties(); //initialize the composable functions
        await this.initializeToggleArrays(); //initialize the toggle cards
        await this.initializeCommentsArray(); //initialize the comments array
        await this.initializeSectionData(); //initialize the section labels and toggle states
        // console.log('Resume.vue: Resume from Vue store: '+JSON.stringify(this.userEditedResume) );
        if (!this.userEditedResume){
          console.log('Resume is empty in Vue store. Redirecting to home..');
          this.navigateTo('Home'); // Return the user to Home screen
          return;
        } 
      }
      //if cover letter isn't present in Vue, generate cover letter
      // if(!this.coverLetterAvailable) {
      //   console.log('Cover letter not found. Generating cover letter...');
      //   this.generateCoverLetter();
      // }
      //Show toast message with info to navigate to cover letter when user comes from the home screen
      if (this.previousRoute ==='Home') {
        setTimeout(() => {
          this.dismissable_toast_id= this.toast.showToast('Info', this.MESSAGE_PAGE_INSTRUCTIONS);
          }, 1000);
      }
    }
    console.log('Resume.vue created..');
  },
  watch: {
    // Watch for changes to selectedTemplate, selectedPageSize, userEditedContent and call the processAndSplitPages method
    selectedTemplate() {
      // Use $nextTick to run the code after the DOM has been updated
      console.log('Watcher: Template changed..initializing preferences = '+this.initializing_preferences);
      if (!this.initializing_preferences) { 
        this.$nextTick(() => {
          this.$store.commit('setResumePreferences', { config: this.resumePreferences });
            setTimeout(() => { // Add an extra delay of 0.5 seconds to give the browser enough time to render the template
              this.processAndSplitPages(); // finally split the pages
            }, 500);
        });
      }
    },
    selectedPageSize() {
      console.log('Watcher: Page size changed..initializing preferences = '+this.initializing_preferences);
      if (!this.initializing_preferences) { 
        this.$nextTick(() => {
          this.processAndSplitPages();
        });
      }
    },
    selectedLayout() {
      console.log('Watcher: Layout changed..initializing preferences = '+this.initializing_preferences);
      if (!this.initializing_preferences) { 
        this.$nextTick(() => {
          setTimeout(() => { // Add an extra delay of 0.25 seconds to give the browser enough time to render the template
              this.processAndSplitPages(); // finally split the pages
            }, 250);
        });
      }
    },
    selectedDensity() {
      console.log('Watcher: Density changed..initializing preferences = '+this.initializing_preferences);
      if (!this.initializing_preferences) { 
        this.$nextTick(() => {
          setTimeout(() => { // Add an extra delay of 0.5 seconds to give the browser enough time to render the template
              this.processAndSplitPages(); // finally split the pages
            }, 250);
        });
      }
    },
    selectedColumns() {
      console.log('Watcher: column selection changed..initializing preferences = '+this.initializing_preferences);
      if (!this.initializing_preferences) { 
        this.$nextTick(() => {
          setTimeout(() => { // Add an extra delay of 0.5 seconds to give the browser enough time to render the template
              this.processAndSplitPages(); // finally split the pages
            }, 250);
        });
      }
    },
    selectedIconOption() {
      console.log('Watcher: Icon preference changed..initializing preferences = '+this.initializing_preferences);
      if (!this.initializing_preferences) { 
        this.$nextTick(() => {
          this.processAndSplitPages();
        });
      }
    },
    selectedFontSize() {
      console.log('Watcher: Font size changed..initializing preferences = '+this.initializing_preferences);
      if (!this.initializing_preferences) { 
        this.$nextTick(() => {
          setTimeout(() => { // Add an extra delay of 0.25 seconds to give the browser enough time to render the template
              this.processAndSplitPages(); // finally split the pages
            }, 250);
        });
      }
    },
    selectedFont() {
      console.log('Watcher: Font changed..initializing preferences = '+this.initializing_preferences);
      if (!this.initializing_preferences) { 
        this.$nextTick(() => {
          setTimeout(() => { // Add an extra delay of 0.25 seconds to give the browser enough time to render the template
              this.processAndSplitPages(); // finally split the pages
            }, 250);
        });
      }
    },
    show_comment_indicators() {
      console.log('Watcher: comment preview toggled = '+this.initializing_preferences);
      if (!this.initializing_preferences) { 
        this.$nextTick(() => {
          this.processAndSplitPages(); // finally split the pages
        });
      }
    },
    userEditedResume: {
      handler:function(){
        console.log('Inside Resume.vue: userEditedResume Changed');
        this.updateUserResume();
      },
      deep:true
    },
    availableHeights: {
      handler: 'updateKeywordsPosition',
      deep: true,
    },
    comment_tab(newValue) {
      if (newValue === false ) {
        this.$store.commit('updateViewedRecommendations', true);
      }
    }
  },
  components: {
    //register the resume components
    SanFranciscoResumeTemplate,
    NewYorkResumeTemplate,
    TokyoResumeTemplate,
    LondonResumeTemplate,
    BerlinResumeTemplate,
    StepperButton,
    Icon,
    ThemedButton,
    ToastMessage,
    Selector,
    draggable,
    CommentCard,
    ResumeLeftNav,
    TabButton,
    MenuButton,
    Tooltip,
  },
  methods: {
    //Function to handle custom component clicks
    handleButtonClick (id){
      console.log('Clicked button ID: '+id);
      // Download Resume
      if(id=== 'download_pdf') this.downloadPDF(); //this.generatePDF();
      //Toggle Editor Panel
      else if (id==='editor_toggle') this.toggleEditorPanel();
      //Toggle School Card
      else if (id.includes('toggle_school_card')) {
        const index = parseInt(id.replace('toggle_school_card_', ''), 20);         // Extract the index from the componentId
        console.log('Toggling school card at index: '+index);
        if (!isNaN(index)) this.is_school_expanded[index] = !this.is_school_expanded[index];
        else console.warn('Could not parse toggle school card: Invalid componentId:', id);
      }
      // Toggle Org Position Card
      else if (id.includes('toggle_org_position_card')) {
        const index = parseInt(id.replace('toggle_org_position_card_', ''), 21);         // Extract the index from the componentId
        console.log('Toggling org position card at index: '+index);
        if (!isNaN(index)) this.is_org_position_card_expanded[index] = !this.is_org_position_card_expanded[index];
        else console.log('Could not parse toggle org position card: Invalid componentId:', id);
      }
      // Edit Section Name
      else if (id.startsWith('section-name-edit-')) {
        const section = id.slice('section-name-edit-'.length);
        this.editSectionName(section);
      }
      // Save Section Name
      else if (id.startsWith('section-name-save-')) {
        const section = id.slice('section-name-save-'.length);
        this.saveSectionName(section);
      }
      // Cancel Section Name Edit
      else if (id.startsWith('section-name-cancel-')) {
        const section = id.slice('section-name-cancel-'.length);
        this.cancelSectionEdit(section);
      }      
      else if (id.startsWith('scroll_to_')) {
        const reference = id.slice('scroll_to_'.length);
        console.log('Scroll to: '+reference);
        if (reference) {
          this.scrollToAccordion(reference);
        }
      }
      // Togglecomment panel
      else if (id==='toggle_comment_panel') this.toggleCommentPanel();
      // Toggle Comment card
      else if (id.startsWith('toggle_comment_')) {
        const commentId = id.slice('toggle_comment_'.length);
        if (commentId) {
          this.toggleShowStatus(commentId);
        }
      }
      // Focus Comment or recommendation
      else if (id.startsWith('focus_comment_')) {
        const commentId = id.slice('focus_comment_'.length);
        if (commentId) {
          if (commentId.startsWith('reco_')) {
            this.unfocusAllRecommendations(); //unfocus all other recommendations
          } else {
            this.unfocusAllComments(); //unfocus all other comments
          }
          this.updateFocusStatus(commentId, true); //focus on the comment
        }
      }
      // Recalculate ATS Score
      else if (id==='recalculate_score') {
        this.saveAndRecalculateScore();
      }
      // Restore Content
      else if (id.startsWith('restore_')) {
        const parts = id.split('_'); //expecting restore_updateType_commentId i.e. 2 underscores and 3 parts
        const updateType = parts[1];
        const commentId = `${parts[2]}_${parts[3]}`;
        if (parts[2]==='ps') {
          this.restoreSummaryContent('ps', updateType);
        } else if (parts[2].startsWith('pa')){
          this.restoreProjectContent(parts[2], updateType);
        } else if (parts[2].startsWith('ex')){
          this.restorePositionContent(commentId, updateType);
        }
      }
      // Print Preivew
      else if (id==='print_preview') this.toggleCommentIndicators();
      // Save
      else if (id==='save') this.saveApplicationToServer();
      // Route to the previous screen
      else if (id==='input') this.navigatePrevious();
      // Route Home without save
      else if (id==='home') this.navigateTo('Home');
      // Route to Cover Letter
      else if (id==='cover_letter') this.saveAndNavigate('Cover Letter');
      else console.warn('Unknown command: '+id);
    },
    //Function to update the project item
    updateProjectItem(projectIndex, itemIndex, newValue) {
      if (this.userEditedResume.projects && 
        this.userEditedResume.projects.group_array && 
        this.userEditedResume.projects.group_array[projectIndex] &&
        this.userEditedResume.projects.group_array[projectIndex].items_array) {
        this.userEditedResume.projects.group_array[projectIndex].items_array[itemIndex] = newValue;
      }
    },
    //Function to set the resume preferences from the Selector component
    handleOptionSelected(component_id, option) {
        console.log('Selector: '+component_id + ', Selected option:', option.value);
        switch (component_id) {
        case 'template_selector':
          this.selectedTemplate = option.value;
          this.resumePreferences.template = option.value;
          this.selectedFont = this.getDefaultFont(this.selectedTemplate); //change the font when template is changed
          this.$store.commit('updateTemplatePreference',option.value);
          return;
        case 'color_selector':
          this.selectedColor = option.value;
          this.resumePreferences.color = option.value;
          this.$store.commit('updateColorPreference', option.value);
          return;
        case 'font_selector':
          this.selectedFont = option.value;
          this.resumePreferences.font = option.value;
          this.$store.commit('updateFontPreference', option.value);
          return;
        case 'font_size_selector':
          this.selectedFontSize = option.value;
          this.resumePreferences.fontSize = option.value;
          this.$store.commit('updateFontSizePreference', option.value);
          return;
        case 'density_selector':
          this.selectedDensity = option.value;
          this.resumePreferences.density = option.value;
          this.$store.commit('updateDensityPreference', option.value);
          return;
        case 'column_selector':
          this.selectedColumns = option.value;
          this.resumePreferences.columns = option.value;
          this.$store.commit('updateColumnPreference', option.value);
          return;
        case 'layout_selector':
          this.selectedLayout = option.value;
          this.resumePreferences.layout = option.value;
          this.$store.commit('updateLayoutPreference', option.value);
          return;
        case 'page_size_selector':
          this.selectedPageSize = option.value;
          this.resumePreferences.pageSize = option.value;
          this.$store.commit('updatePageSizePreference', option.value);
          return;
        case 'icon_selector':
          this.selectedIconOption = option.value;
          this.showIcon = (option.value==='yes'); 
          this.resumePreferences.showIcon = this.showIcon;
          this.$store.commit('updateIconPreference', this.showIcon);
          return;
        case 'zoom_selector':
          if (option.label ==='Fit') this.selectedZoom = this.getFitZoomFactor();
          else this.selectedZoom = option.value;
          return;
        }
    },
    // Initialize the resume settings form the resumePreferences data property
    // Additionally, if a user doesn't have any resume preferences, set them based on current selections
    async initializeResumeSettings() {
      this.initializing_preferences = true; // set the flag to inform others that resume settings are being initialized
      console.log('Resume settings JSON before initialization: '+JSON.stringify(this.resumePreferences));
      if ( Object.keys(this.resumePreferences).length !== 0) { //If resumePreferences insn't an empty object
        console.log('Using resume preferences from Vue store to initialize');
        if(this.resumePreferences.template && this.resumePreferences.template !=='') { //if user has a template preference
          this.selectedTemplate = this.resumePreferences.template; //set the template based on user's resume preferences
        } else { //if the user doesn't have a template preference
          this.resumePreferences.template = this.selectedTemplate; //set the template preference to the current template
        }
        if(this.resumePreferences.color && this.resumePreferences.color !=='') { //if user has a color preference
          this.selectedColor = this.resumePreferences.color; //set the color  based on user's resume preferences
        } else {
          this.resumePreferences.color = this.selectedColor;
        }
        if(this.resumePreferences.layout && this.resumePreferences.layout !=='') { //if user has a layout preference
          this.selectedLayout = this.resumePreferences.layout;
        } else {
          this.resumePreferences.layout = this.selectedLayout;
        }
        if (this.resumePreferences.hasOwnProperty('showIcon')) { //if user has an icon preference
          this.showIcon = this.resumePreferences.showIcon;
          this.selectedIconOption = this.showIcon? 'yes':'no'; 
          console.log('icon preferences set to: '+this.selectedIconOption);
        } else {
          this.resumePreferences.showIcon =  (this.selectedIconOption==='yes');
          console.log('using default icon settings: show icon: '+this.showIcon);
        }
        if(this.resumePreferences.font && this.resumePreferences.font !=='') { //if user has a font preference
          this.selectedFont = this.resumePreferences.font;
          if (this.selectedFont === 'Helvetica') this.selectedFont = 'Inter'; //Helvetica is no longer supported and has been replaced by Inter
        } else {
          this.selectedFont = this.getDefaultFont(this.selectedTemplate);
          console.log('default settings: font: '+this.selectedFont);
        }
        if(this.resumePreferences.fontSize && this.resumePreferences.fontSize >0 ) { //if user has a font size preference
          this.selectedFontSize = this.resumePreferences.fontSize;
        } else {
          this.resumePreferences.fontSize = this.selectedFontSize;
        }
        if(this.resumePreferences.density && this.resumePreferences.density >0 ) { //if user has a density preference
          this.selectedDensity = this.resumePreferences.density;
        } else {
          this.resumePreferences.density = this.selectedDensity;
        }
        if(this.resumePreferences.columns && this.resumePreferences.columns >2 ) { //if user has a sectioncolumn count preference
          this.selectedColumns = this.resumePreferences.columns;
        } else {
          this.resumePreferences.columns = this.selectedColumns;
        }
        if(this.resumePreferences.pageSize && this.resumePreferences.pageSize !=='') { //if user has a page size preference
          this.selectedPageSize = this.resumePreferences.pageSize;
        } else {
          this.resumePreferences.pageSize = this.selectedPageSize;
        }
      } else {
        console.log('No resume preferences found. Default values will be used...');
      }
      console.log('*** Resume preferences initialized. Waiting for next tick..');
    },
    // Return the template specific font if font isn't set in resume preferences
    getDefaultFont(template){
      if (this.resumePreferences?.font) return this.resumePreferences.font; //if font is available in resume preferences, return it
      switch (template) { //Otherwise return template specific fonts
        case 'SanFrancisco':
          return 'EB Garamond';
        case 'NewYork':
          return 'Open Sans';
        case 'Tokyo':
          return 'Inter';
        case 'London':
          return 'Lora';
        case 'Berlin':
          return 'Raleway';
        default:
          return 'Raleway';
      }
    },
    //Initialize the education, experience and position expand/ collapse toggle arrays
    async initializeToggleArrays() {
      console.log('Initializing toggle arrays...');
      await this.$nextTick(); // Wait for the DOM to render the userEditedResume contents 
      if (this.userEditedResume) {
        // Initialize educations array
        if (this.userEditedResume.educational_qualifications?.educations)  {
          this.is_school_expanded = Array(this.userEditedResume.educational_qualifications.educations.length).fill(false);
          console.log('Initialized toggle schools array: '+this.is_school_expanded.length);
        }
        // Initialize projects array
        if (this.userEditedResume.projects_and_assignments?.projects)  {
          this.is_project_expanded = Array(this.userEditedResume.projects_and_assignments.projects.length).fill(false);
          console.log('Initialized projects toggle array: '+this.is_project_expanded.length);
        }
        // Initialize skill groups array
        if (this.userEditedResume.professional_skills?.skill_groups)  {
          const num_skill_groups = this.userEditedResume.professional_skills.skill_groups.length;
          this.is_skill_group_expanded = Array(num_skill_groups).fill(false);
          this.is_skill_group_skills_card_expanded = Array(num_skill_groups).fill(false);
          console.log('Initialized skill_groups toggle array: '+this.is_skill_group_expanded.length);
        }
        // Initialize experience arrays
        if (this.userEditedResume.experiences) {
          this.is_organization_expanded = Array(this.userEditedResume.experiences.length).fill(false); //organization array
          this.is_org_position_card_expanded = Array(this.userEditedResume.experiences.length).fill(true); //organization position card array
          console.log('Initialized toggle organizations array: '+this.is_organization_expanded.length);
          // Loop through experiences to initialize positions array
          this.userEditedResume.experiences.forEach((experience, experienceIndex) => { 
            if (experience.positions) { // Check if experience has positions
              // Initialize array for positions in this experience
              const positionsArray = Array(experience.positions.length).fill(false);
              this.is_position_expanded.push(positionsArray); // Push this array into is_position_expanded
              console.log('Initialized toggle positions array: '+positionsArray.length);
            } else {
              const positionsArray = [];
              this.is_position_expanded.push(positionsArray); // Push this array into is_position_expanded
              console.log('Initialized toggle positions array: '+positionsArray.length);
            }
          });
        }
      } else {
        console.log('Could not initialize toggle arrays since userEditedResume is not available');
      }
    },
    //Initialize the comments array
    async initializeCommentsArray() {
      await this.$nextTick(); // Wait for the DOM to render the userEditedResume contents 
      let new_comment = {};
      //Resume level comment
      if (this.userEditedResume.id) {
        new_comment = {id: this.userEditedResume.id, show:true, focus:true, user_edited: false};
        this.comments.push(new_comment);
      }
       // Rsume Level Recommendations (Additional recommendations)
       if (this.getOverallRecommendationContents) {
        new_comment = {id: 'reco_resume', show:false, focus:false};
        this.recommendations.push(new_comment);
      }

      for (const section of this.userEditedResume.section_order.items_array) {
        if (section==='experiences') {
          //Experience comments
          // console.log('Initializing Experience Comments: Num experiences: '+this.userEditedResume.experiences.length);
          this.userEditedResume.experiences.forEach((experience) => {
            if (experience.company_name) {
              experience.positions.forEach((position) => {

                const comment_id = position.id;
                if (comment_id) {
                  new_comment = {id: comment_id, show:false, focus:false, user_edited: false, selected_content:'generated'};
                  this.comments.push(new_comment);
                  // console.log('Position: '+position.job_title+ ' @ '+experience.company_name+'\nComment added with id: '+new_comment.id);
                  // this.updateContentStatus(new_comment.id, position.alt_content, position.comments, position.job_responsibilities);
                }
              });
            }
          });
          // Initialize the recommendation comment
          if (this.sectionRecommendations(section)?.length>0) {
            new_comment = {id: 'reco_'+section, show:false, focus:false};
            this.recommendations.push(new_comment);
          }
        }  else if (section==='projects_and_assignments') {
          //Project comments
          this.userEditedResume.projects_and_assignments.projects.forEach((project) => {
            if (project.id) {
              console.log('Project: '+project.title);
              // const comment_id=`project_${projectIndex}_comment`;
              const comment_id = project.id;
              if (comment_id) {
                new_comment = {id: comment_id, show:false, focus:false, user_edited: false, selected_content:'generated'};
                this.comments.push(new_comment);
                // console.log('Comment added with id: '+new_comment.id);
              }
            }
          });
          // Initialize the recommendation comment
          if (this.sectionRecommendations(section)?.length>0) {
            new_comment = {id: 'reco_'+section, show:false, focus:false};
            this.recommendations.push(new_comment);
          }
        } else if ( this.sections_with_comments.includes(section) ) {
          console.log('Generic non-date section '+section);
          // Generic non-date section comments
          // Check if the section has the contents necessary to show a comment
          if (this.userEditedResume[section]?.id && 
            (this.userEditedResume[section]?.comments?.length > 0 || this.userEditedResume[section]?.change_reason?.length > 0)) 
          { 
            const content_status = section ==='professional_summary' ? 'generated' : '';
            new_comment = {id: this.userEditedResume[section].id, show:false, focus:false, user_edited: false, selected_content: content_status};
            this.comments.push(new_comment);
            // console.log('Added section comment: '+new_comment.id);
          }
          // Initialize the recommendation comment
          console.log("Checking recommendation for section: Count= "+this.sectionRecommendations(section)?.length);
          if (this.sectionRecommendations(section)?.length>0 && (this.userEditedResume[section]?.items_array?.length > 0 ||
            this.userEditedResume[section]?.skill_groups?.length > 0 || this.userEditedResume[section]?.summary?.length > 0)) 
          {
            console.log('Adding recommendation for section '+section);
            console.log(JSON.stringify(this.sectionRecommendations(section)));
            new_comment = {id: 'reco_'+section, show:false, focus:false};
            this.recommendations.push(new_comment);
            // console.log('Added recommendation for section '+section);
          }
        } else {
          //These sections only have recommendations, no comments
          console.log('Recommendation only section: '+section);
          if (this.sectionRecommendations(section)?.length>0 ) {
            new_comment = {id: 'reco_'+section, show:false, focus:false};
            this.recommendations.push(new_comment);
          }
        }
      }
      // Section order comment
      if( this.userEditedResume.section_order?.change_reason?.length > 0) {
        new_comment = {id: this.userEditedResume.section_order.id, show:false, focus:false};
        this.comments.push(new_comment);
      }
      console.log('Comment initialization complete. Total comments: '+this.comments.length + ', Total recommendations: '+this.recommendations.length);
      console.table(this.recommendations);
      if (this.recommendations.length>0) {
        this.recommendations[0].show = true;
        this.recommendations[0].focus = true;
      }
    },
    /**
     * Initialize the resumeSectionProperties composable function
     * Also set up a watcher to update the ref that is passed to this function whenever the userEditedResume changes.
     */
    initializeSectionProperties() {
      console.log('Initializing section properties...');
      // Initialize the composable after setting userEditedResume
      const resumeRef = ref(this.userEditedResume);
      this.sectionProperties = resumeSectionProperties(resumeRef);
      // Watch userEditedResume and update the ref so that composable functions can access it
      this.$watch(
        'userEditedResume',
        (newValue) => {
          console.log('userEditedResume updated:', newValue);
          resumeRef.value = newValue
        },
        { immediate: true, deep: true }
      )
    },
    // Initialize the toggle states, visibility, and labels
    async initializeSectionData() {
      console.log('Initializing section data ...');
      if (!this.userEditedResume?.section_order?.items_array) {
        console.warn('Resume doesn\'t have the section_order array');
        return;
      }
      const currentItems = [...this.userEditedResume.section_order.items_array];
      // Find section keys in the resume_sections array (constant) that are not in the userEditedResume
      const missingItems = this.resume_sections.filter(section => 
        !currentItems.includes(section)
      );
      if (missingItems.length > 0) {
        // Update the array with missing items
        this.userEditedResume.section_order.items_array = [
          ...currentItems,
          ...missingItems
        ];
      }
      let showLabel ='';
      let hideLabel = '';
      const itemLevelHideSections = ['experiences', 'educational_qualifications', 'projects_and_assignments'];
      for (const section of this.userEditedResume.section_order.items_array) {
        // Initialize section data
        if (itemLevelHideSections.includes(section) || this.isDateSection(section)) {
          showLabel = this.combinedLabel(this.BUTTON_LABEL_SHOW_ALL, this.section_names_plural[section], false);
          hideLabel = this.combinedLabel(this.BUTTON_LABEL_HIDE_SOME, this.section_names_plural[section], false);
        } else {
          showLabel = this.combinedLabel(this.BUTTON_LABEL_SHOW, this.section_names_plural[section], false);
          hideLabel = this.combinedLabel(this.BUTTON_LABEL_HIDE, this.section_names_plural[section], false);
        }
        this.section_data[section] = {
          expanded: false,
          showOnEditor: false,
          showLabel: showLabel,
          hideLabel: hideLabel,
          addLabel:  this.combinedLabel(this.BUTTON_LABEL_ADD_BELOW, this.section_names_singular[section], false),
          deleteLabel: this.combinedLabel(this.BUTTON_LABEL_DELETE, this.section_names_singular[section], false),
          generalInstructions: this.combinedLabel(this.BUTTON_LABEL_UPDATE, this.section_names_plural[section]?.toLowerCase(), false),
        };
      }
      await this.initializeShowOnEditorSettings(); //initialize which sections should be visible on the editor panel
    },
    // Determine which sections should be visible on the editor panel
    async initializeShowOnEditorSettings() {
      for (const section of this.userEditedResume.section_order.items_array) {
        switch (section) {
          case 'professional_summary':
            this.section_data[section].showOnEditor = true;
            break;
          case 'experiences':
            this.section_data[section].showOnEditor = this.userEditedResume.experiences?.length>0;
            break;
          case 'projects_and_assignments':
            this.section_data[section].showOnEditor = this.userEditedResume.projects_and_assignments?.projects?.length>0;
            break;
          case 'educational_qualifications':
            this.section_data[section].showOnEditor = this.userEditedResume.educational_qualifications?.educations?.length>0;
            break;
          case 'professional_skills':
            this.section_data[section].showOnEditor = this.userEditedResume.professional_skills?.skill_groups?.length>0;
            break;
          default:
            this.section_data[section].showOnEditor = this.userEditedResume[section]?.items_array?.length>0;
            break;
        }
      }
    },
    async downloadPDF() {
      const initial_comment_preview = this.show_comment_indicators;
      if (!initial_comment_preview) {
        await this.generatePDF(); //if comment indicators are hidden, generate the PDF right away
      } else {
        this.show_comment_indicators = false; // first hide the comment indicators
        try {
          await this.$nextTick(); //await DOM changes to complete
          await new Promise(resolve => setTimeout(resolve, 500)); // wait for another 500ms
          await this.generatePDF(); // finally call generatePDF method
        } finally {
          this.show_comment_indicators = true; //show the comment indicators again
        }
      }
    },
    // This method sends an array of HTML strings (for each resume page) + a CSS style string
    // And then receives and displays the PDF in the response
    async generatePDF() {
      //Show the download in progress message
      this.download_in_progress = true;
      let export_success=false;
      // Save the resume and preferences to server
      await this.saveApplicationToServer(false); //don't show save success toast message
      this.dismissable_toast_id = this.toast.showToast('Wait', this.MESSAGE_DOWNLOAD_IN_PROGRESS);
      // Get the Resume container element
      const resumeComponent = document.querySelector('div._container_1axvs_20');

      // Extract the CSS variables and their values dynamically inserted into the '_container_1axvs_20' element 
      const componentStyles = resumeComponent.style
      let cssVariablesAsString = "";  //String to store CSS variables
      if (componentStyles) {
        console.log('CSS rules present in root')
        for (let i = 0; i < componentStyles.length; i++) {
          const property = componentStyles[i];
          const value = componentStyles.getPropertyValue(property);
          if (property !='height') { //copy property as long as it's not the 'height'
            cssVariablesAsString += property+" : "+value+";\n";
          }
        }
      }
      cssVariablesAsString += `--print-scale-factor: ${900 / this.selectedPageDimensions.width};` //change the scale factor for printing
      cssVariablesAsString +='\n--body-justify: flex-start;' //change the body layout for printing
      console.log('CSS Rules Found in Root: '+cssVariablesAsString);

      // Get the inner HTML of the Resume container element
      const htmlContent = [];
      for(let i=0; i< this.numPages; i++){
        htmlContent[i] = this.getHTMLPage(resumeComponent,i);
      }

      //Now make a call to the server
      try {
        //Construct the data payload to send to the server
        const data = {
        id: this.applicationId,
        html_pages: htmlContent,  //array of HTML strings
        page_size: this.selectedPageSize,
        css_variables_string: cssVariablesAsString,
        };
        const response = await axios.post('/api/v1/export/generate_pdf', data, { 
          headers: {
            'Content-Type': 'application/json',
          },
        },);
        //After receiving an accepted response from the server
        if (response.data && response.data.job_id) {
          const jobId = response.data.job_id;
          console.log('Export job queued with job ID:', jobId);

          // Call the jobSuccessful method to check the job status and trigger the download
          const result = await this.jobSuccessful(jobId, 'export', 12, 3000);
          if(result) {
            export_success=true; 
            console.log('Export job completed..result available..Export successful? '+export_success);
            // Create a Blob object from the binary response data
            const blob = new Blob([result], { type: 'application/pdf' });
            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            const file_suffix = this.applicationJSONVue?.company_applied ? this.applicationJSONVue.company_applied : this.applicationJSONVue?.position_applied ? this.applicationJSONVue.position_applied : 'resume';
            const file_name = this.userEditedResume.name + '_' + file_suffix + '.pdf';
            const mod_file_name = file_name.replace(/ /g, '_'); //replace spaces in file name with underscore
            link.download = mod_file_name;

            // Click the link to start the download
            link.click();

            // Revoke the Object URL to free up resources
            window.URL.revokeObjectURL(url);
  
          } else {
            console.log('Export job failed or was cancelled');
          }  
        }
        else {
          console.log('server did not queue the job');
        }
      } catch (error) {
        console.error('Error generating pdf: '+error);
      } finally {
        //remove the download progress
        this.download_in_progress = false;
        this.toast.removeToast(this.dismissable_toast_id);
        this.dismissable_toast_id = ''; //reset the dismissable toast id
        if (!export_success) {
          this.toast.showToast('Error', this.ERROR_PDF_EXPORT);
        } else {
          this.toast.showToast('Success', this.SUCCESS_PDF_EXPORT);
        }
      }  
    },
    // Requires the resume wrapper div node (N) as an input
    // Returns the HTML source for a page with a given index
    getHTMLPage(N, index) {
      console.log('Get HTML Page: '+index);
      if (index <0 || index >= this.numPages) return ''; // Ensure that the index parameter is a valid index
      const nodeCopy = N.cloneNode(true);
      //Find the _page_container class
      const pageContainer = nodeCopy.querySelector('div._page_container');
      // Locate the target page i.e. the div which has the 'page-index' attribute with the matching index
      const targetPage = pageContainer.querySelector(`[page-index="${index}"]`);
      if (targetPage) {
        //Remove all the  other children of the pageContainer node except for the targetPage
        const contents = pageContainer.childNodes;
        for (let i=0; i<contents.length; i++){
          const child=contents[i];
          if (child != targetPage) {
            pageContainer?.removeChild(child);
            i--; //reduce the counter to account for the child removal
          }
        }
        return nodeCopy.innerHTML;
      } else return '';
    },
    // Pre-process the document to unhide the full resume content and then split it into multiple pages
    processAndSplitPages(){
      //Find whether it is a supported resume type
      const pageContainer = document.querySelector('div._page_container'); //original container
      console.log('Split Pages: page index ='+this.pageIndex);
      if (!pageContainer) { //If the anchor element isn't available
        console.log('Unsupported template. Anchor element not found ..');
        return;
      }
      //Step 1: Locate the content node and unhide it
      const elements = pageContainer.querySelectorAll('div._content._full');
      if (elements.length ==0 ) return; //exit if a div with class=content and type="Full" isn't found
      const contentNode = elements[0];

      console.log(contentNode);
      //Step 2: Temporarily unhide the original content to allow calculating heights
      contentNode.style.display = 'block'; 
      // Use this.$nextTick to wait for the DOM update after unhiding the content
      this.$nextTick(() => {
        // Step 3: Split the pages
        this.splitPages(pageContainer, contentNode);
        // Step 4: After pages have been split, hide the original content
        contentNode.style.display = 'none'; //hide the original content
        this.initializing_preferences = false; // reset the preference initialization flag
        // Step 5: Inject Keywords
        // Compute the available side bar and center column height/ space for keyword injection
        let sideBarHeight = 0;
        let centerColumnHeight = 0;
        if (this.sideBarColumns.length < this.numPages) {
          // If the side bar contents don't extend to the last page, the available height for keywords is the full page height
          sideBarHeight = this.selectedPageDimensions.height;
        } else {
          sideBarHeight = this.availableSideBarHeightTemp + this.templatePadding -10; //add the bottom margin to the available height and subtract 10px for extra padding
        }
        if (this.contentColumns.length < this.numPages) {
          // If the center column contents don't extend to the last page, the available height for keywords is the full page height
          centerColumnHeight = this.selectedPageDimensions.height;
        } else {
          centerColumnHeight = this.availableCenterColumnHeightTemp; 
        }
        // Update the availableHeidhts object. This will notify the watcher
        this.availableHeights.sideBar = sideBarHeight;
        this.availableHeights.centerColumn = centerColumnHeight;
        console.log('Available center column height: '+centerColumnHeight);
        this.$nextTick(() => {
          // Add comment click listeners after all DOM manipulation is complete
          this.addClickListener();
          });
      });
    },
    //Split the resume content into multiple pages
    splitPages(pageContainer, contentNode) {

      //Step 0: Reset all the variables required for splitting HTML into multiple pages
      this.numPages=1; //reset the max number of pages to 1
      this.contentColumns= [], //reset the content array
      this.sideBarColumns= [], //reset the sidebar array


      //Step 1a: Split the center column
      this.splitContent(contentNode, 'content_column', this.contentColumns);
      //Step 1b: Split sidebar column only if layout = left or right
      if (this.selectedLayout!='Center') this.splitContent(contentNode, 'sidebar_column', this.sideBarColumns);

      // Step 2: Get the number of resume pages
      this.numPages = this.contentColumns.length> this.sideBarColumns.length? this.contentColumns.length: this.sideBarColumns.length;
      // Step 3: Construct the pages array
      const contentNodes =[];

      for (let i=0; i< this.numPages; i++) {
        contentNodes[i] = this.createNode(['_content', '_page'],i);
        //replace it with modified pages
        const resumeHeader = contentNode.querySelector('div._header');
        const resumeHeaderCopy = resumeHeader? resumeHeader.cloneNode(true):null;
        const pageHeader =this.showIcon? contentNode.querySelector('div._labelTop'): null;
        const pageHeaderCopy = pageHeader? pageHeader.cloneNode(true):null;
        const pageFooter = this.showIcon? contentNode.querySelector('div[class^="_labelBottom"]'): null;
        const pageFooterCopy = pageFooter? pageFooter.cloneNode(true):null;
        let modPage = this.createNode(['_page_resume_template'], i);
        if (i==0) {
          //first page has headers
          if(resumeHeader && (!this.hasSideBarBackground || (this.hasSideBarBackground && this.selectedLayout=='Center'))) {
             modPage.appendChild(resumeHeaderCopy);
          }
          if (pageHeader) modPage.appendChild(pageHeaderCopy);
        }
        // all pages have footer
        if (pageFooter) modPage.appendChild(pageFooterCopy);
        if (this.hasSideBarBackground &&  this.selectedLayout!='Center') {
            // If template has a sidebar background
            console.log('Template has sidebar background');
            const sideBarBackgroundCopy = this.createNode(['_sidebar_background'],i);
            modPage.appendChild(sideBarBackgroundCopy);
          }
        else if (this.hasSideBarBackground && i==0 && this.selectedLayout==='Center') {
            // If template has a topbar background
            console.log('Template has topbar background');
            const topBarBackgroundCopy = this.createNode(['_topbar_background'],i);
            modPage.appendChild(topBarBackgroundCopy);
          }
        const content = this.createNode(['_resume_columns'], i);
        if(i<this.contentColumns.length) {
          this.contentColumns[i].style.order=this.columnOrder;
          this.contentColumns[i].setAttribute('page-index', i);
          content.appendChild(this.contentColumns[i]);
        } else {
          const emptyColumn = this.createNode(['_column','_main_content'],i);
          emptyColumn.style.order=this.columnOrder; //add an empty column
          content.appendChild(emptyColumn);
        }
        if(i<this.sideBarColumns.length) {
          this.sideBarColumns[i].style.order=0;
          this.sideBarColumns[i].setAttribute('page-index', i);
          content.appendChild(this.sideBarColumns[i]);
        } else if(this.selectedLayout!='Center') {
          //Append an empty sidebar column if layout = 'left' or 'right'
          const emptyColumn = this.createNode(['_column','_sidebar_column'],i);
          emptyColumn.style.order=0; //add an empty column
          content.appendChild(emptyColumn);
        }
        modPage.appendChild(content);
        contentNodes[i].appendChild(modPage);
        contentNodes[i].style.display = 'block'; //set visibility = true
        console.log('Page['+i+']=');
        console.log(contentNodes[i])
        // contentNodeCopy.appendChild(modPage)
      }
     // Step 4: remove all the previously generated content
      const children = pageContainer.childNodes;
      for (let i=children.length-1; i>=0; i--){
        const child=children[i];
        if (!child.classList.contains('_content') || !child.classList.contains('_full')) {
          pageContainer?.removeChild(child);
        }
      }
      for (let i=0; i< this.numPages; i++) {
        pageContainer.appendChild(contentNodes[i]); //append the newly generated content
      }
      
    },
    //Split a dcoument node (containerNode) with a given ID into multiple pages
    splitContent(containerNode, id, page)
    {
      console.log('Initiating Split Content');
      this.pageIndex=0;
      this.height=this.getPageHeight(containerNode);
      page[this.pageIndex]= document.createElement('div'); //root node
      this.lastNode = page[this.pageIndex]; //set last node = root node
      const parentDiv = containerNode.querySelector (`#${id}`); //look up the parent div by ID
      console.log('Split Content for div ID = '+id + ', layout= '+this.selectedLayout);
      console.log(parentDiv);
      // const targetDiv = parentDiv.querySelector('._inner_column'); //look up the target div
      let targetStyle =''; //content specific styling
      if (id ==='content_column') {
        this.fitNode(parentDiv,true,0); //fit into contentColumns
        targetStyle='_main_content';
        this.availableCenterColumnHeightTemp= this.height; //set the available height for keywords in the center column
      } else {
        this.fitNode(parentDiv,false,0); //fit into sideBarColumns
        targetStyle='_sidebar_column';
        this.availableSideBarHeightTemp = this.height;  //set the available height for keywords in the side column
      }
      console.log('***** Num pages: '+page.length);
      for (let i=0; i <page.length; i++) {
        page[i].classList.add('_column', targetStyle); //assign the classes accordingly
        console.log('Page['+i+']:');
        console.log(page[i]);
      }
    },
    copyStyle(sourceElement) {
      //Given any document element return an empty element of the same type that has the same list of classes
      // Step 1: Identify the element type based on the tag name of the source element
      const tagName = sourceElement.tagName.toLowerCase();
      const destinationElement = document.createElement(tagName);
      const classList = sourceElement.classList;
      for (const className of classList) {
        console.log('Class Name: '+ className);
        destinationElement.classList.add(className);
        }
      return destinationElement;
    },
    // fits the contents of a node 'N' into multiple pages
    // content =true -> indicates centerColumn; false -> indicates sideBar column
    // parentMargins -> the total of top and botom margin of the parent element of node N
    fitNode(N, content, parentMargins) {
      
      console.log('Fit Node Called with height remaining: '+this.height + ', parent margin: '+parentMargins);
      console.log(N);
      if ((N.nodeType !== Node.ELEMENT_NODE)) return; //ignore nodes which aren't HTML elements e.g. comment nodes
      const elementHeight = this.getElementHeight(N);
      const elementMargins = this.getMargins(N);
      console.log('Element Margin: '+elementMargins);
      if ( elementHeight + parentMargins <= this.height) {
        //element fits
        //let newNode = document.createElement('div');
        let newNode = N.cloneNode(true); //fully copies the node incl. children
        this.lastNode.appendChild(newNode);
        this.height -= elementHeight; //adjust the remaining height
        console.log('Element fits, height remaining: '+this.height);
        return;
      }
      console.log('Element does not fit. Iterating through children');
      if ( N.tagName === 'LI' || N.nodeName.toLowerCase() === 'hr' || !this.hasChildren(N) || this.height<=parentMargins + elementMargins || 
        this.isCardNode(N) || this.isTitleNode(N)) {
        //If it's a card div or if the available space is less than the margins or the element doesn't fit but has no children
        console.log('No children or not enough space or it is a card or a title node');
        console.log('Space remaining: '+this.height);
        console.log('has child nodes? '+this.hasChildren(N));
        console.log(N);
        const parent = this.lastNode.parentNode;
        if(!parent) return; //This is a rare case where it's an empty element that's forced to fit the rest of the screen
        this.pageIndex++; //if node has no children and can't fit into existing page
        console.log('Creating new page: '+this.pageIndex);
        this.height = this.getPageHeight(N);  //reset the available height
        console.log('Removing last child ..');
        console.log(this.lastNode);
        parent?.removeChild(this.lastNode);
        const newPage = this.replicateBranchFrom(parent);
        if(content) {
          this.contentColumns[this.pageIndex] =  newPage.root; //when iterating through content, adjust the content array
        } else {
          this.sideBarColumns[this.pageIndex] = newPage.root; //when iterating through sidebar, adjust the sidebar array
        }
        //Iterate through the last node of the parent and remove any node that has just one child
        let currentNode = parent;
        let siblings = null;
        while (currentNode!== document.documentElement) {
          console.log('Traversing up the tree');
          if(this.isFloatingNode(currentNode)) {
            siblings = this.isFloatingSibling(currentNode); 
            // Checking for floating siblings ensures that the title node isn't the last node on a page
            if (siblings) {
              console.log('This is a floating sibling'); //this means that the only other siblings are either title nodes or hr nodes
              console.log(currentNode);
              break;
            } // otherwise traverse up the tree
            const motherNode = currentNode.parentNode;
            console.log('Removing node and moving up the tree:');
            console.log(currentNode);
            motherNode?.removeChild(currentNode);
            currentNode = motherNode;
          } else break;
        }
        // Check if the last node of the parent node is an hr element
        const lastChild = currentNode?.lastChild;
        if (lastChild?.nodeName.toLowerCase() === 'hr') {
          console.log('Last node is an HR element. Removing it...');
          currentNode?.removeChild(lastChild);
        } else if (lastChild && this.isTitleNode(lastChild)) {
          console.log('Last node is a title node. Removing it...');
          console.log(lastChild);
          currentNode?.removeChild(lastChild);
          siblings = {titleNode: lastChild};
        }


        this.lastNode = newPage.last; 
        // If there are any title and hr siblings of the last node, insert them here:
        if (siblings) {
          console.log('Inserting siblings to the last node on the new page..');
          if (siblings.titleNode) {
            this.lastNode.parentNode.insertBefore(siblings.titleNode, this.lastNode);
          }
          if (siblings.hrNode) {
            this.lastNode.parentNode.insertBefore(siblings.hrNode, this.lastNode);
          }
        }
        this.fitNode(N, content, parentMargins);
      } else {
        const children = N.childNodes;
        console.log('Children found: '+children.length);
        for (let i = 0; i < children.length; i++) {
          const child = children[i];
          console.log('Child['+i+']');
          console.log(child);
          if (child.nodeType === Node.ELEMENT_NODE) {
            //Try fitting it only if it is an html element
            const childeHeight = this.getElementHeight(child);
            if ( childeHeight + parentMargins + elementMargins < this.height) {
              this.height -= childeHeight; //reduce the available height
              // let clonedChild = document.createElement('div');
              let clonedChild = child.cloneNode(true); //full copy of the node including its children
              this.lastNode.appendChild(clonedChild);
              console.log('Child filts, height remaining = '+this.height);
              console.log(this.clonedChild);
            } else {
              console.log('Child doesnot fit, calling recursion');
              const emptyChild = this.copyStyle(child); //creates a new empty div with the same class list
              this.lastNode.appendChild(emptyChild);
              this.lastNode = emptyChild;  //point to the child     
              this.fitNode(child, content, (parentMargins + elementMargins)); // call this method recursively to fit the child node
            }
          }
        }
        if (this.lastNode.parentNode) {
          this.lastNode = this.lastNode.parentNode; //once you've gone through all the child nodes, move back to the parent
        }
      }
    },
    //Get the total height of an HTML element including margins
    getElementHeight(element) {
        // Skip calculation for comment icons
        if (element.classList.contains('CommentIconContainer')) {
          console.log('Comment Icon Found..skipping height calculation..');
          return 0;
        }
        const computedStyle = getComputedStyle(element);
        let marginTop =0;
        let marginBottom = 0;
        let elementHeight = 0;
        let gap = 0; //this is applicable only when the element is a child of a grid 
        if (computedStyle) {
          console.log('Computed Style Found');
          marginTop = Math.ceil(parseFloat(computedStyle.marginTop));
          marginBottom = Math.ceil(parseFloat(computedStyle.marginBottom));
          const parentElement = element.parentElement;
          if (parentElement && parentElement.classList.contains('_sidebar_column')) {
            marginBottom += 12 * this.selectedDensity;
            console.log('This is a _sidebar_column child...Adding grid spacing to parent margin: ' + (12 * this.selectedDensity));
          }
          elementHeight = Math.ceil(element.offsetHeight);
        } else console.log('Computed Style Not Found');

        // If the element is a child of a grid (it will always have an 'item-id' attribute)
        if (element.hasAttribute('item-id')) {
          gap = 10 * this.selectedDensity; //Add the gap to the overall element height
          if (element.classList.contains('_card_row') && element.classList.contains('_sidebar_card')) { //additionally if the element is in a grid with columns (it has bot the classes '_card_row' and '_sidebar_card')
            const numColumns = this.selectedColumns;  
            elementHeight = elementHeight/numColumns; //reduce the height to account for the extra columns
            gap = gap/numColumns; //reduce the gap to account for the extra columns
            console.log('element is in a grid with columns')
          }
        }
        const totalHeight = elementHeight + marginTop + marginBottom + gap;
        console.log('Margin Top: '+marginTop+', Margin Bottom: '+marginBottom+', elementHeight: '+elementHeight+', rowGap: ' +gap +', total height: '+totalHeight);
        return totalHeight;
    },
    //Get the max possible height of the current page (based on the pageIndex global variable)
    getPageHeight(containerNode){
      console.log('Get Page Height, pageIndex = '+this.pageIndex);
      if(this.pageIndex >0 ){
        return this.selectedPageDimensions.height-2*this.templatePadding;  //This is the available content height after subtracting the padding
      } else {
        const targetDiv = containerNode.querySelector('div._header');
        const headerHeight = targetDiv? this.getElementHeight(targetDiv) : 0;
        console.log('get max height: page height ='+this.selectedPageDimensions.height+', headerHeight: '+ headerHeight);
        return this.selectedPageDimensions.height-2*this.templatePadding - headerHeight; //additionally subtract the header height for the first page
      }
    },
    //return margins for a node
    getMargins(N) {
      let marginTop = 0;
      let marginBottom = 0; 
        console.log('Getting margins for element node');
        console.log(N);
        const elementStyle = getComputedStyle(N);
        if(elementStyle) {
          marginTop = parseFloat(elementStyle.marginTop);
          marginBottom = parseFloat(elementStyle.marginBottom);
        }
      const totalMargin = marginBottom + marginTop;
      console.log('Get Margins: '+ totalMargin);
      return totalMargin;
    },
    //create a replica of the branch from node N up to the root node and return the last node and the root node
    replicateBranchFrom(N) {
      let branch = this.copyStyle(N); //this will be the root node
      const branchLastNode = branch; //this will be the last node
      while (N.parentNode) {
        const parent = this.copyStyle(N.parentNode); 
        parent.appendChild(branch);
        branch = parent; //set the pointer to the branch to that of the parent node
        N = N.parentNode; //traverse up the branch
      }
      return {root:branch, last:branchLastNode};
    },
    hasChildren(N) {
      //check whether a node has any children of type HTML element
      const children = N.childNodes;
        for (let i = 0; i < children.length; i++) {
          if (children[i].nodeType === Node.ELEMENT_NODE) {
            return true;
          }
        }
        return false;
    },
    //Check whether the node is a card div
    isCardNode(N){
      if (N.classList) {
        const classList = N.classList;
        for (let i = 0; i < classList.length; i++) {
          const className = classList[i];
          if (className.startsWith('_card')) {
            console.log('This is a card node');
            return true;
          }
        }
      }
      return false;
    },
    //Check whether the node is a '_resume_title' div
    isTitleNode(N){
      // console.log('checking whether node is is a Title Node:');
      // console.log(N);
      if (N?.classList) {
        const classList = N.classList;
        for (let i = 0; i < classList.length; i++) {
          const className = classList[i];
          if (className.startsWith('_resume_title')) {
            console.log('This is a title node');
            return true;
          }
        }
      }
      return false;
    },

/**
 * Check if a node is "floating" by determining if it has no significant children.
 * A significant child is defined as a non-whitespace text node, a non-comment node,
 * or any element that is not an <hr> element.
 *
 * @param {Node} node - The DOM node to evaluate for floating status.
 * @returns {boolean} - Returns true if the node has no significant children,
 *                      otherwise returns false.
 */
   isFloatingNode(node) {
      // Get array of child nodes, filtering out: (i) whitespace text nodes, (ii)comment nodes, and (iii) hr elements
      if (!node) return false; // early return
      const significantChildren = Array.from(node.childNodes)
        .filter(child => {
          // Filter out text nodes that are just whitespace
          if (child.nodeType === Node.TEXT_NODE) {
            return child.nodeValue.trim() !== '';
          }
          // Filter out comment nodes
          if (child.nodeType === Node.COMMENT_NODE) {
            return false;
          }
          // Filter out hr elements
          if (child.nodeType === Node.ELEMENT_NODE && 
              child.nodeName.toLowerCase() === 'hr') {
            return false;
          }
          // Keep all other nodes
          return true;
        });
      // Return true only if there are no significant children left
      return significantChildren.length === 0;
    },
    /**
     * Determine if the only other siblings of a node are either title nodes or hr elements.
     * If yes, remove the title and hr siblings
     * 
     * Returns one of the following:
     * - null if the node does not meet the above criteria
     * - { titleNode } if the node's only sibling is a title node
     * - { titleNode, hrNode } if the node's only two siblings are a title node and an hr node.
     * 
     * @param {Node} node The node to check
     * @return {Object|null}
     */
    isFloatingSibling(node) {
      if (!node || !node.parentNode) {
        return null;
      }

      // Get array of siblings excluding the current node and whitespace text nodes
      const siblings = Array.from(node.parentNode.childNodes)
        .filter(child => {
          return child !== node && 
                !(child.nodeType === Node.TEXT_NODE && child.nodeValue.trim() === '');
        });

      // Case 1: No siblings
      if (siblings.length === 0) {
        return null;
      }

      // Case 2: One sibling that is a title (we don't care about lone hr elements anymore)
      if (siblings.length === 1) {
        const onlySibling = siblings[0];
        if (this.isTitleNode(onlySibling)) {
          node.parentNode?.removeChild(onlySibling);
          return { titleNode: onlySibling};
        }
        return null;
      }

      // Case 3: Two siblings - one hr and one title
      if (siblings.length === 2) {
        const firstSibling = siblings[0];
        const secondSibling = siblings[1];

        // Check combination: hr + title
        if (firstSibling.nodeName.toLowerCase() === 'hr' && this.isTitleNode(secondSibling)) {
          node.parentNode?.removeChild(firstSibling);  // Remove hr
          node.parentNode?.removeChild(secondSibling); // Remove  title
          return {titleNode: secondSibling, hrNode: firstSibling};
        }

        // Check combination: title + hr
        if (this.isTitleNode(firstSibling) && secondSibling.nodeName.toLowerCase() === 'hr') {
          node.parentNode?.removeChild(firstSibling); // Remove title
          node.parentNode?.removeChild(secondSibling);  // Remove hr
          return {titleNode: firstSibling, hrNode: secondSibling};
        }
      }

      // Any other case returns null
      return null;
    },
    createNode(classList, attribute){
      //create a div with the provided class list and the page-index attribute
      const div = document.createElement('div');
      for (const className of classList) {
        console.log('Create node with class name Class Name: '+ className);
        div.classList.add(className);
      }
      div.setAttribute('page-index', attribute);
      return div;
    },
    //Update the position for the keywords div
    updateKeywordsPosition () {
      console.log('update keywords position');
      let side_bar_top = 0; // the topmost empty position available on the side bar panel
      let center_column_top = 0; //the topmost empty position available on the center panel
      // Get the top position for the available space in side bar and center columns
      if (this.availableHeights.sideBar< this.selectedPageDimensions.height) { //i.e. if it doesn't have the full page height
        side_bar_top = this.selectedPageDimensions.height - this.availableHeights.sideBar - this.templatePadding; 
      } 
      if (this.availableHeights.centerColumn < this.selectedPageDimensions.height) {
        console.log('Keyword position: page height= '+this.selectedPageDimensions.height+' , padding= '+this.templatePadding+', available height= '+this.availableHeights.centerColumn);
        center_column_top = this.selectedPageDimensions.height - this.templatePadding - this.availableHeights.centerColumn;
        console.log('Keyword div position top= '+center_column_top);
      } 
      console.log('Available side bar height: '+this.availableHeights.sideBar+ ', Available center column height: '+this.availableHeights.centerColumn);
      if (this.selectedLayout !== 'Center'){ //For side panel layouts i.e. left and right
        const top = side_bar_top > center_column_top? side_bar_top: center_column_top; //set the top based on whichever is lower
        this.keywordsPosition = {
            left: '0', 
            top: `${top}px`,
            width: '100%',
          };
      } 
      else { //For 'Center' layout
        this.keywordsPosition = {
          left: '0', 
          top: `${center_column_top}px`,
          width: '100%',
        };
      }
    },
    // When showing a page index selector, this will trigger an event when the page index is changed
    onPageSelectorChange(event) {
      const selectedIndex = parseInt(event.target.value);
      if (!isNaN(selectedIndex)) {
        this.showPage(selectedIndex);
      }
    },
    showPage(index) {
      //Show a given page index
      console.log('Show Page: '+index);
      const allPages = document.querySelectorAll('._page_resume_template');

      allPages.forEach(page => {
        const pIndex = parseInt(page.getAttribute('page-index'));
        if (!isNaN(pIndex)) {
          page.style.display = pIndex === index ? 'block' : 'none';
        }
      });
    },
    showSectionOnEditor(section) {
      console.log('Show on editor for section: '+section);
      this.section_data[section].showOnEditor = true;
    },
    // Generates a unique id for a new element in a given section
    // The unique id is the max id for the section + 1 (or 0 if no elements exist in the section)
    generateSectionId(section) {
      // ID prefix for each section where items require an id
      const idPrefixMap = {
        "experiences": "ex",
        "educational_qualifications": "eq",
        "projects_and_assignments": "pr",
        "awards_and_recognitions": "ar",
        "publications_and_presentations": "pp",
        "trainings_and_certifications": "tc"
      };

      // The array object inside each section
      const arrayNameMap = {
        'educational_qualifications': 'educations',
        'projects_and_assignments': 'projects',
        'awards_and_recognitions': 'items_array',
        'trainings_and_certifications': 'items_array',
        'publications_and_presentations': 'items_array'
      };

      // Get the section prefix for the given section
      const prefix = idPrefixMap[section];
      if (!prefix) {
        return null; // Early return if section isn't found in the map
      }
      
      // Get the array to traverse
      let arrayToCheck;
      if (section === 'experiences') {
        arrayToCheck = this.userEditedResume.experiences;
      } else {
        const arrayName = arrayNameMap[section];
        arrayToCheck = this.userEditedResume[section][arrayName];
      }

      // If array doesn't exist or is empty, return prefix + '0'
      if (!arrayToCheck || arrayToCheck.length === 0) {
        return prefix + '0';
      }

      // Find max id number
      let maxNum = -1;
      arrayToCheck.forEach(item => {
        if (item.id && item.id.startsWith(prefix)) {
          const numStr = item.id.substring(2); // Remove prefix
          const num = parseInt(numStr);
          if (!isNaN(num) && num > maxNum) {
            maxNum = num;
          }
        }
      });

      // Return new id with incremented number
      return prefix + (maxNum + 1);
    },
    // Generates a unique id for a new position in an experience (with the provided Id)
    // The unique id is the max id for the position in the experience + 1 (or 0 if no positions exist in the experience)
    generatePositionId(experienceId) {
      // Find the experience in the experiences array
      const experience = this.userEditedResume.experiences.find(exp => exp.id === experienceId);
      
      // Return null if experience not found
      if (!experience) {
        return null;
      }

      // If no positions array or empty array, return first position id
      if (!experience.positions || experience.positions.length === 0) {
        return `${experienceId}_po0`;
      }

      // Find max position number
      let maxNum = -1;
      experience.positions.forEach(position => {
        if (position.id && position.id.startsWith(`${experienceId}_po`)) {
          const numStr = position.id.substring(experienceId.length + 3); // Remove experienceId_po
          const num = parseInt(numStr);
          if (!isNaN(num) && num > maxNum) {
            maxNum = num;
          }
        }
      });

      // Return new id with incremented number
      return `${experienceId}_po${maxNum + 1}`;
    },
    // Add an item at a given index for a given section
    addSectionItem(section, index) {
      if (!this.userEditedResume[section].items_array) { //initialize the items array if not prsent
        this.userEditedResume[section].items_array = [];
      }
      const length = this.userEditedResume[section].items_array.length;
      index = index > length ? length : index;
      let new_item = {};
      if (this.isDateSection(section)){
        const id = this.generateSectionId(section);
        console.log('Adding date element at index: '+index + ' for section: '+section + ' with id: '+id);
        new_item = {
          id: id,
          item_name: '',
          date:'',
        };
      } else {
        console.log('Adding string element at index: '+index + ' for section: '+section);
        // this.userEditedResume[section].items_array.splice(index, 0, ''); //add a default item (=string)
        new_item = {
          item_name: '',
        };
      }
      this.userEditedResume[section].items_array.splice(index, 0, new_item); //add a default item (=object)
    },
    // Delete an item at a given index for a given section
    deleteSectionItem(section, index) {
      // Ensure that the index is within the valid range of the 'items_array' 
      if (index >= 0 && index < this.userEditedResume[section].items_array.length) {
        // Use splice to remove the skill at the specified index
        this.userEditedResume[section].items_array.splice(index, 1);
      }
    },
    // Launch the Section Name Editor
    editSectionName(section){
      console.log('Edit name for section: '+section);
      this.section_name_temp[section]=this.userEditedResume[section].name; //initialize the temp variable
      this.section_edit_state[section]=true;
    },
    // Save section name, update preferences and dismiss the section name editor
    saveSectionName(section) {
      console.log('Save name for section: '+section);
      const newName = this.section_name_temp[section].trim();
      // Check if the name has changed
      if (this.userEditedResume[section].name.trim() !== newName){
        console.log('Section name changed..Updating preferences');
        this.userEditedResume[section].name = newName;
        // Check if the new name is different from the default name
        const defaultName = this.section_names_plural[section];
        if (newName !== defaultName) {
          // If resumePreferences.sectionNames doesn't exist, initialize it
          if (!this.resumePreferences.sectionNames) {
            this.resumePreferences.sectionNames = {};
          }  
          // Add/update the custom name in resumePreferences
          this.resumePreferences.sectionNames[section] = newName;
        } else {
          // If the name matches default, remove it from custom preferences if it exists
          if (this.resumePreferences.sectionNames && 
              this.resumePreferences.sectionNames.hasOwnProperty(section)) {
            delete this.resumePreferences.sectionNames[section];
          }
        }
        // Update section name preferences in VueX store
        this.$store.commit('updateSectionNamePreference', this.resumePreferences.sectionNames);
      }
      
      // Reset edit state
      this.section_edit_state[section]=false;
      this.section_name_temp[section] = ''; //reset the temp variable
    },
    // Dismiss the section name editor without saving name changes
    cancelSectionEdit(section) {
      console.log('Cancel name changes for section: '+section);
      this.section_edit_state[section]=false;
      this.section_name_temp[section] = ''; //reset the temp variable
    },
    addEducation(index) {
      // Ensure that the necessary objects and arrays exist
      if (index==0) {
        if (!this.userEditedResume.educational_qualifications) {
          this.userEditedResume.educational_qualifications = {};
        }
        if (!this.userEditedResume.educational_qualifications.educations) {
          this.userEditedResume.educational_qualifications.educations = [];
        }
        this.section_data['educational_qualifications'].expanded= true; //expand the Education Accordion so that the newly added school can be seen
      }
      const length = this.userEditedResume.educational_qualifications.educations.length;
      index = index > length ? length : index;
      const id = this.generateSectionId('educational_qualifications');
      console.log('Creating a new degree with id: '+id)
      const newEducation = {
        id: id,
        school: this.combinedLabel(this.LABEL_ADJECTIVE_SCHOOL, this.LABEL_SCHOOL, false),
        degree: this.combinedLabel(this.LABEL_ADJECTIVE_DEGREE, this.LABEL_DEGREE, false),
        school_start_date: "",
        school_end_date: "",
        gpa: "",
      };
      this.userEditedResume.educational_qualifications.educations.splice(index, 0, newEducation); //add a default education to a certain index
      this.is_school_expanded.splice(index, 0, true); //expand the degree card for the user to edit its contents
    },
    addProject(index) {
      console.log('Adding project at index: '+index);
      // Ensure that the necessary objects and arrays exist
      if (index == 0) {
        if (!this.userEditedResume.projects_and_assignments) {
          this.userEditedResume.projects_and_assignments = {};
        }
        if (!this.userEditedResume.projects_and_assignments.projects) {
          this.userEditedResume.projects_and_assignments.projects = [];
        }
        this.section_data['projects_and_assignments'].expanded = true; //expand the Projects Accordion so that the newly added project can be seen
      }
      const length = this.userEditedResume.projects_and_assignments.projects.length;
      index = index > length ? length : index;
      const id = this.generateSectionId('projects_and_assignments');
      const newProject = {
        id: id,
        title: this.combinedLabel(this.LABEL_ADJECTIVE_PROJECT, this.section_names_singular['projects_and_assignments'], false),
        items_array: [],
      };
      this.userEditedResume.projects_and_assignments.projects.splice(index, 0, newProject); //add a default project to a certain index
      this.is_project_expanded.splice(index, 0, true); //expand the project card for the user to edit its contents
    },    
    addSkillGroup(index) {
      console.log('Adding skill group at index: '+index);
      // Ensure that the necessary objects and arrays exist
      if (index == 0) {
        if (!this.userEditedResume.professional_skills) {
          this.userEditedResume.professional_skills = {};
        }
        if (!this.userEditedResume.professional_skills.skill_groups) {
          this.userEditedResume.professional_skills.skill_groups = [];
        }
        this.section_data['professional_skills'].expanded = true; //expand the skill_groups Accordion so that the newly added skill_group can be seen
      }
      const length = this.userEditedResume.professional_skills.skill_groups.length;
      index = index > length ? length : index;
      const newSkill = this.combinedLabel(this.LABEL_ADJECTIVE_GENERIC, this.LABEL_SKILL_SINGULAR, false);
      const newSkillGroup = {
        title: this.combinedLabel(this.LABEL_ADJECTIVE_SKILL_GROUP, this.section_names_singular['professional_skills'], false),
        items_array: [newSkill],
      };
      this.userEditedResume.professional_skills.skill_groups.splice(index, 0, newSkillGroup); //add a default skill_group to a certain index
      this.is_skill_group_expanded.splice(index, 0, true); //expand the skill_group card for the user to edit its contents
      this.is_skill_group_skills_card_expanded.splice(index, 0, true); //expand the skills card as well
    },
    addSkill(index, skillIndex) {
      //Add a skill at skillIndex at the indexth skill group
      console.log('Adding skill['+skillIndex+'] for skill g: '+index);
      const newSkill = this.combinedLabel(this.LABEL_ADJECTIVE_GENERIC, this.LABEL_SKILL_SINGULAR, false);
      if (this.userEditedResume.professional_skills.skill_groups[index]?.items_array) {
        //if items array exists
        this.userEditedResume.professional_skills.skill_groups[index].items_array.splice(skillIndex, 0, newSkill); //add a default skill at the skillIndex
      }
      else { //if items array does not exist
        this.userEditedResume.professional_skills.skill_groups[index].items_array = [newSkill]; //add a default skill as the first skill
      }
      if ( skillIndex==0 ) this.is_skill_group_skills_card_expanded[index] = true; //expand the skills list card so that skills are visible
    },
    deleteSkill(index, skillIndex) {
      //Delete a skill from the indexth skill group
      // Ensure that the skillIndex is within the valid range of the items array
      if (skillIndex >= 0 && skillIndex < this.userEditedResume.professional_skills.skill_groups[index].items_array.length) {
        // Use splice to remove the education at the specified index
        this.userEditedResume.professional_skills.skill_groups[index].items_array.splice(skillIndex, 1);
      }
    },
    deleteEducation(index) {
      // Ensure that the index is within the valid range of the 'educations' array
      if (index >= 0 && index < this.userEditedResume.educational_qualifications.educations.length) {
        // Use splice to remove the education at the specified index
        this.userEditedResume.educational_qualifications.educations.splice(index, 1);
        this.is_school_expanded.splice(index, 1);
      }
    },
    deleteProject(index) {
      // Ensure that the index is within the valid range of the 'projects' array
      if (index >= 0 && index < this.userEditedResume.projects_and_assignments.projects.length) {
        // Use splice to remove the education at the specified index
        this.userEditedResume.projects_and_assignments.projects.splice(index, 1);
        this.is_project_expanded.splice(index, 1);
      }
    },
    deleteSkillGroup(index) {
      // Ensure that the index is within the valid range of the 'skill_groups' array
      if (index >= 0 && index < this.userEditedResume.professional_skills.skill_groups.length) {
        // Use splice to remove the education at the specified index
        this.userEditedResume.professional_skills.skill_groups.splice(index, 1);
        this.is_skill_group_skills_card_expanded.splice(index, 1);
        this.is_skill_group_expanded.splice(index, 1);
      }
    },
    addCompany(index) {
      // Ensure that the necessary objects and arrays exist
      console.log('Adding company at index: '+index);
      if (index==0) {
        if (!this.userEditedResume.experiences) {
          this.userEditedResume.experiences = [];
        }
        this.is_experience_expanded = true; //expand the Experience Accordion so that the newly added company can be seen
      }
      const length = this.userEditedResume.experiences.length;
      index = index > length ? length : index;
      const id = this.generateSectionId('experiences');
      const newCompany = {
        id: id,
        company_name: this.combinedLabel(this.LABEL_ADJECTIVE_ORGANIZATION, this.LABEL_ORGANIZATION_SINGULAR, false),
        company_description: "",
      };
      this.userEditedResume.experiences.splice(index, 0, newCompany); //add a default company to a certain index
      this.is_organization_expanded.splice(index, 0, true); // initialize the organization toggle
      this.is_org_position_card_expanded.splice(index, 0, true); // initialize the organization position card toggle
      this.addPosition(index,0); //Also add a position for the newly added company
    },
    deleteCompany(index) {
      // Ensure that the index is within the valid range of the 'experiences' array
      console.log('Deleting company at index: '+index);
      if (index >= 0 && index < this.userEditedResume.experiences.length) {
        // remove all the position comment cards for the org
        const experience = this.userEditedResume.experiences[index];
        experience.positions.forEach((position, positionIndex) => {
          this.deleteCommentById(`experience_${index}_position_${positionIndex}_comment`);
        });
        this.is_organization_expanded.splice(index,1); // remove the org toggle
        this.is_org_position_card_expanded.splice(index,1); // remove the org position card toggle
        this.is_position_expanded.splice(index, 1); // remove all the position toggles for the org
        this.userEditedResume.experiences.splice(index, 1); //remove the experience at the given index
      }
    },
    addPosition(index, positionIndex) {
      //Add a position at positionIndex at the indexth experience
      const experienceId = this.userEditedResume.experiences[index].id;
      const positionId = this.generatePositionId(experienceId);
      console.log('Adding position['+positionIndex+'] for company: '+index + ' with position id: '+positionId);

      const newPosition = {
        id: positionId,
        job_title: this.combinedLabel(this.LABEL_ADJECTIVE_POSITION, this.LABEL_POSITION_SINGULAR, false),
        job_start_date: "",
        job_end_date: "",
        job_responsibilities:"",
      };
      if (this.userEditedResume.experiences[index]?.positions) {
        //if position array exists
        // const length = this.userEditedResume.experiences[index].positions.length;
        this.userEditedResume.experiences[index].positions.splice(positionIndex, 0, newPosition); //add a default position to a certain positionIndex
        this.is_position_expanded[index].splice(positionIndex, 0, true);
      }
      else { //if position array does not exist
        this.userEditedResume.experiences[index].positions = [newPosition]; //add a default position as the first position
        if (!this.is_position_expanded[index]) {
          this.is_position_expanded[index] = []; //If position not present at the experience index, create the positions array first
        }
        this.is_position_expanded[index].splice(0, 0, true); //insert true for the first position at the given experience index
      }
      if ( positionIndex==0 ) this.is_org_position_card_expanded[index] = true; //expand the org position card so that positions are visible
    },
    deletePosition(index, positionIndex) {
      //Delete a position a position from the indexth experience
      // Ensure that the positionIndex is within the valid range of the 'positions' array
      if (positionIndex >= 0 && positionIndex < this.userEditedResume.experiences[index].positions.length) {
        // Use splice to remove the education at the specified index
        this.userEditedResume.experiences[index].positions.splice(positionIndex, 1);
        this.is_position_expanded[index].splice(positionIndex, 1);
      }
    },
    // Split the job responsibilities string in resume text editor input into multiple strings (array) based on the new line character
    // Additionally call the updateContentStatus method to update the content status of the comment
    updateResponsibilities(commentId, originalContent, diffArray, editedContent) {
      for (const experience of this.userEditedResume.experiences) {
        for (const position of experience.positions) {
          if (position.id === commentId) {
            position.job_responsibilities = editedContent.split("\n");
            this.updateContentStatus(commentId, originalContent, diffArray, position.job_responsibilities);
            return; // Exit the method once a match is found
          }
        }
      }
    },
    updateProjectDetails(commentId, originalContent, diffArray, editedContent) {
      for (const project of this.userEditedResume.projects_and_assignments.projects) {
        if (project.id === commentId) {
          project.items_array = editedContent.split("\n");
          this.updateContentStatus(commentId, originalContent, diffArray, project.items_array);
          return; // Exit the method once a match is found
        }
      }
      
    },
    updateSectionOrderPreferences() {
      const updatedPreferences = {
        ...(this.resumePreferences || {}),
        sectionOrder: [...this.userEditedResume.section_order.items_array]
      };
      console.log('Updated Section Order Preferences: '+JSON.stringify(updatedPreferences));
      this.resumePreferences = updatedPreferences;
      this.$store.commit('setResumePreferences', { 
        config: updatedPreferences 
      });
    },
    updateUserResume() {
      // Update the resume in the Vuex store when the user makes changes
      console.log('Updating User Resume in Vue Store');
      this.$store.commit('updateResume', {
        version: this.selectedVersion, //update the selected version
        resume: this.userEditedResume,
      });
      //Split content
      console.log('Resume settings JSON in updateResume method: '+JSON.stringify(this.resumePreferences));
      this.$nextTick(() => {
        // setTimeout(() => { // Add an extra delay of 0.25 seconds to give the browser enough time to render the template
        //     console.log('User resume updated:', this.userEditedResume);
        //     this.processAndSplitPages(); // finally split the pages
        //   }, 250);
        this.debounceSplitPages(); //call the debounced split pages method
      });
    },
    toggleEditorPanel() {
      this.is_editor_expanded = !this.is_editor_expanded;
    },
    toggleProfileAccordion() {
      this.is_profile_expanded = !this.is_profile_expanded;
    },
  
    toggleExperienceAccordion() {
      this.is_experience_expanded = !this.is_experience_expanded;
    },
    toggleCompanyAccordion(index) {
      this.is_organization_expanded[index] = !this.is_organization_expanded[index]
    },
    toggleSkillGroupSkillsCard(index) {
      this.is_skill_group_skills_card_expanded[index] = !this.is_skill_group_skills_card_expanded[index];
    },
    toggleSectionAccordion(section) {
      console.log('Toggling accordion for section: '+section);
      this.section_data[section].expanded = !this.section_data[section].expanded;
    },
    // Toggle the visibility of a section
    toggleSectionVisibility(section) {
      console.log('Toggling visibility of section: '+section);
      const newHideValue = !this.userEditedResume[section].hide;
      this.userEditedResume[section].hide = newHideValue;
      
      // Update resume preferences
      if (!this.resumePreferences.sectionHide) { //if sectionHide preferences does not exist
        this.resumePreferences.sectionHide = {}; //create the sectinoHidepreferences object
      }
      this.resumePreferences.sectionHide[section] = newHideValue; // Udate user's hide preferences
      console.log('Updated user hide preferences: '+JSON.stringify(this.resumePreferences.sectionHide));
    },
    toggleExperienceVisbility() {
      this.userEditedResume.hide_experiences = !this.userEditedResume.hide_experiences;
      this.resumePreferences.experienceHide = this.userEditedResume.hide_experiences; //update the user's experienceHide preference
    },
    toggleDateVisbility() {
      const newHideValue = !this.userEditedResume.educational_qualifications.hide_date;
      this.userEditedResume.educational_qualifications.hide_date = newHideValue;
      console.log('Toggling date visibility..New hide value: '+newHideValue);
      this.resumePreferences.dateHide = newHideValue; //update the user's dateHide preference
    },
    // Toggle the date visibility of a degree (at a specific index in the educational_qualifications array)
    // Additionally, add/remove the degree id from the degreeDateHide array in resume_preferences
    toggleDegreeDateVisbility(index) {
      if (this.userEditedResume.educational_qualifications.educations[index]) {
        const degree = this.userEditedResume.educational_qualifications.educations[index];
        degree.hide_date = !degree.hide_date;
        
        // Initialize degreeDateHide if it doesn't exist
        if (!this.resumePreferences.degreeDateHide) {
          this.resumePreferences.degreeDateHide = [];
        }

        if (degree.hide_date) {
          // Add ID if not already in array
          if (!this.resumePreferences.degreeDateHide.includes(degree.id)) {
            this.resumePreferences.degreeDateHide.push(degree.id);
          }
        } else {
          // Remove ID if exists in array
          const idIndex = this.resumePreferences.degreeDateHide.indexOf(degree.id);
          if (idIndex > -1) {
            this.resumePreferences.degreeDateHide.splice(idIndex, 1);
          }
        }
        console.log('Updated resume preferences after toggling degree date visibility at index: '+index+': '+JSON.stringify(this.resumePreferences));
      }
    },
    togglePositionAccordion(experienceIndex, positionIndex) {
      this.is_position_expanded[experienceIndex][positionIndex] = !this.is_position_expanded[experienceIndex][positionIndex];
    },
    toggleProjectCard(index) {
      this.is_project_expanded[index] = !this.is_project_expanded[index];
    },
    toggleSkillGroupCard(index) {
      this.is_skill_group_expanded[index] = !this.is_skill_group_expanded[index];
      //Also expand the skill group skills card when skill group card is expanded
      if (this.is_skill_group_expanded[index]) {
        this.is_skill_group_skills_card_expanded[index] = true;
      }
    },
    togglePostionVisibility(experienceIndex, positionIndex) {
      console.log('Toggling visibility of experience['+experienceIndex+'] position['+positionIndex+']..');
      this.userEditedResume.experiences[experienceIndex].positions[positionIndex].hide = 
      !this.userEditedResume.experiences[experienceIndex].positions[positionIndex].hide;
    },
    toggleCommentIndicators() {
      this.show_comment_indicators = !this.show_comment_indicators;
    },
    toggleCommentPanel() {
      this.comment_panel_expanded = !this.comment_panel_expanded;
    },
    scrollToAccordion(reference) {
      console.log('Scrolling to accordion: '+reference);
      if (reference) {
        this.is_editor_expanded = true; //expand the editor panel
        // Find which card it is:
        if (reference.startsWith('profile')) {
          this.is_profile_expanded = true;
        } else if (reference.startsWith('experience')) {
          this.is_experience_expanded = true;
        } else { //This would be a custom section
          console.log('Scrolling to section: '+reference);
          this.section_data[reference].expanded = true;
        }
        this.$nextTick(() => { //wait for the DOM update to complete before scrolling
          let accordion_reference = this.$refs[reference]; 
          console.log('accordion_reference: '+accordion_reference);
          if (accordion_reference instanceof Element) {
            console.log('Accordion reference is a static string'); //if the ref is a static string
          } else {
            console.log('Accordion reference '+reference+' not a static string'); //if the ref is dynamic string
            console.log('this.$refs[reference]: '+this.$refs[reference]);
            accordion_reference_reference = this.$refs[reference][0]; //Since it's a dynamic reference, Vue returns an array instead of elements instead of a single element
          }
          if (accordion_reference instanceof Element) {
            console.log('Accordion reference found..Scrolling..'); //if the ref is dynamic string
            accordion_reference.scrollIntoView({ behavior: 'smooth', block: 'start' }); // scroll to the accordion
          }
        });
      }
    },
    toggleCommentIndicators() {
      this.show_comment_indicators = !this.show_comment_indicators;
    },
    toggleCommentPanel() {
      this.comment_panel_expanded = !this.comment_panel_expanded;
    },
    scrollToAccordion(reference) {
      console.log('Scrolling to accordion: '+reference);
      if (reference) {
        this.is_editor_expanded = true; //expand the editor panel
        // Find which card it is:
        if (reference.startsWith('profile')) {
          this.is_profile_expanded = true;
        } else if (reference.startsWith('summary')) {
          this.is_summary_expanded = true;
        } else if (reference.startsWith('education')) {
          this.is_education_expanded = true;
        } else if (reference.startsWith('experience')) {
          this.is_experience_expanded = true;
        } else { //This would be a custom section
          console.log('Scrolling to section: '+reference);
          this.section_data[reference].expanded = true;
        }
        this.$nextTick(() => { //wait for the DOM update to complete before scrolling
          let accordion_reference = this.$refs[reference]; 
          console.log('accordion_reference: '+accordion_reference);
          if (accordion_reference instanceof Element) {
            console.log('Accordion reference is a static string'); //if the ref is a static string
          } else {
            console.log('Accordion reference '+reference+' not a static string'); //if the ref is dynamic string
            console.log('this.$refs[reference]: '+this.$refs[reference]);
            accordion_reference_reference = this.$refs[reference][0]; //Since it's a dynamic reference, Vue returns an array instead of elements instead of a single element
          }
          if (accordion_reference instanceof Element) {
            console.log('Accordion reference found..Scrolling..'); //if the ref is dynamic string
            accordion_reference.scrollIntoView({ behavior: 'smooth', block: 'start' }); // scroll to the accordion
          }
        });
      }
    },
    // Scroll to the first visible comment for an experience
    async scrollToExperienceComment(index) {
      const numExperiences = this.userEditedResume.experiences.length;
      if (index <0 || index>= numExperiences) return;
      const experience = this.userEditedResume.experiences[index];
      let comment_id = '';
     // Iterate through positions array to find the first visible comment
      for (const position of experience.positions) {
        comment_id = position.id;
        if (this.getShowStatus(comment_id)) {
          break; // If showStatus is true, break the loop
        }
      }
      // If no visible comment is found and positions array is not empty, set comment_id to the first position's comment_id
      if (!comment_id && experience.positions?.length > 0) {
        comment_id = experience.positions[0].id;
      } 
      // Scroll to the comment if a comment_id is found
      if (comment_id !=='') {
        await this.scrollToComment(comment_id);
      } else {
        console.log('No comment found for this experience');
      }
    },
    // Scroll to and focus on a given comment based on the comment id
    async scrollToComment(id) {
      console.log('Scrolling to comment '+id);
      if (id) {
        const isReco = id.startsWith('reco_'); //check if the comment is a recommendation
        this.comment_panel_expanded = true; //expand the comment panel if it's not already expanded
        this.updateShowStatus(id, true); // expand the comment details
        let comment_reference = this.$refs[id]; 
        //Exit if comment reference is not found
        if (!comment_reference) {
          console.log(`No reference found for comment ID: ${id}`);
          return;
        }
        
        // Switch tab if needed
        if (!isReco && !this.comment_tab || isReco && this.comment_tab) {
          this.comment_tab = !isReco;
          // Wait for the next DOM update
          await this.$nextTick();
          // Add a small delay to ensure CSS transitions have completed
          await new Promise(resolve => setTimeout(resolve, 50));
        }

        if (comment_reference instanceof Element) {
          console.log('Comment reference is a static string; Show status: '+this.getShowStatus(id)); //if the ref is a static string
        } else {
          console.log('Comment reference '+id+' not a static string'); //if the ref is dynamic string

          if (Array.isArray(comment_reference) && comment_reference.length > 0) {
            comment_reference = comment_reference[0]; //Since it's a dynamic reference, Vue returns an array instead of elements instead of a single element
          } else {
            console.log(`Reference exists for ID ${id} but it has no elements`);
            return;
          }
        }
        if (comment_reference instanceof Element) {
          console.log('Comment reference '+id+' is a dynamic string'); //if the ref is dynamic string
          //first unfocus other comments/ recos
          if (isReco) this.unfocusAllRecommendations(); else this.unfocusAllComments(); 
          this.updateShowStatus(id, true); //update the visible property of the comment, if it was previously hidden
          // this.updateCommentVisibility(id, true); //update the visibility of the DOM element 
          this.updateFocusStatus(id, true); // then focus on it
          // Get references of the parent containers:
          const commentPanel = this.$refs.comment_panel; // Add a ref to CommentPanel
          const commentContainer = this.$refs.comment_container;
          const panelRect = commentPanel.getBoundingClientRect();
          const containerRect = commentContainer.getBoundingClientRect();

          // Calculate the offset of the comment relative to the container
          const offsetTop = comment_reference.offsetTop;
          const topBuffer = 10; // Add 10px buffer to the top
          // Calculate the scroll position
          const scrollPosition = Math.max(0, offsetTop - (containerRect.top - panelRect.top) - topBuffer);

          // Scroll the container to the top of the comment container
          commentContainer.scrollTop = scrollPosition;
          // comment_reference.scrollIntoView({ behavior: 'smooth', block: 'start' }); // scroll to the comment
        } else console.log('Comment reference not found');
      } else {
        console.log('Comment ID is NULL');
      }
    },
    //Update the visibility of a given comment
    //-- Repurpse this for highlighting resume text --
    // updateCommentVisibility(id, isVisible){
    //   console.log('Updating visibility to '+isVisible +' for comment '+id);
    //   if (id) {
    //     let comment_reference = this.$refs[id]; 
    //     if (comment_reference instanceof Element) {
    //       console.log('Comment reference is a static string; Show status: '+this.getShowStatus(id)); //if the ref is a static string
    //     } else {
    //       console.log('Comment reference '+id+' not a static string'); //if the ref is dynamic string
    //       comment_reference = this.$refs[id][0]; //Since it's a dynamic reference, Vue returns an array instead of elements instead of a single element
    //     }
    //     if (comment_reference instanceof Element) {
    //       if (isVisible) {
    //         comment_reference.style.display = 'block'; //Display the comment
    //       } else {
    //         setTimeout(() => {// Delay the visibility update by 0.4 seconds
    //           comment_reference.style.display = 'none'; //Hide the comment
    //         }, 400);
    //       }
    //     } else { // If no comment with the given refrence is found
    //       console.warn(`Comment '${id}' not found.`);
    //     }
    //   } else {
    //     console.log('Comment ID is NULL');
    //   }
    // },
    
    /**
     * Add a click listener to the elements that have a data-comment-id attribute and
     * correspond to a comment in the 'comments' array. The click listener will call the
     * commentClicked method with the comment ID as an argument.
     */
    addClickListener() {
      const elements = document.querySelectorAll('[data-comment-id]');
      const elementsArray = Array.from(elements);
      const halfLength = Math.floor(elementsArray.length / 2);
      const secondHalf = elementsArray.slice(halfLength);

      secondHalf.forEach(element => {
        const commentId = element.getAttribute('data-comment-id');
        const matchingComment = commentId.startsWith('reco_')? this.recommendations.find(reco => reco.id === commentId) : 
          this.comments.find(comment => comment.id === commentId);

        if (matchingComment) {
          const clickHandler = () => {
            this.commentClicked(commentId);
          };

          // Remove any existing click listener first
          element.removeEventListener('click', element.clickHandler);
          
          // Add the new click listener
          element.addEventListener('click', clickHandler);
          
          // Store the handler on the element for future removal
          element.clickHandler = clickHandler;

          console.log(`Added click listener to element with data-comment-id "${commentId}"`);
        }
      });
    },
    /**
     * This method is called when a comment is clicked. It calls the scrollToComment method to scroll to the comment
     * @param {string} id - The ID of the comment which was clicked
     */
    async commentClicked(id) {
      console.log(`Comment icon with id "${id}" was clicked`);
      await this.scrollToComment(id);
    },
    //get details of a comment by id
    getCommentById(id) {
      return this.comments.find(comment => comment.id === id) || null;
    },
    //get details of a recommendation by id
    getRecommendationById(id) {
      return this.recommendations.find(comment => comment.id === id) || null;
    },
    // delete a comment from the comments array given the id
    deleteCommentById(id) {
      const index = this.comments.findIndex(comment => comment.id === id);
      if (index !== -1) {
        this.comments.splice(index, 1);
      }
    },
    // Method to get the show status of a comment by its id, or NULL if comment is not found
    getShowStatus(id) {
      const comment = id.startsWith('reco_')? this.getRecommendationById(id) : this.getCommentById(id);
      return comment ? comment.show : null;
    },
    // Method to update the show status of a comment by its id if comment is found or do nothing if comment is not found
    updateShowStatus(id, showStatus) {
      const comment =  id.startsWith('reco_')? this.getRecommendationById(id) : this.getCommentById(id);
      if (comment) {
        console.log('Updating show status of '+id);
        comment.show = showStatus;
      } else console.log('Could not update show status. Comment not found: '+id);
    },
    // Method to toggle the show status of a comment by its id if comment is found or do nothing if comment is not found
    toggleShowStatus(id) {
      const comment =  id.startsWith('reco_')? this.getRecommendationById(id) : this.getCommentById(id);
      if (comment) {
        console.log('toggling show status of '+id);
        comment.show = !comment.show;
      } else console.log('Comment with given ID not found: '+id);
    },
    // Method to get the focus status of a comment by its id or NULL if comment is not found
    getFocusStatus(id) {
      const comment =  id.startsWith('reco_')? this.getRecommendationById(id) : this.getCommentById(id);
      return comment ? comment.focus : null;
    },
    // Method to update the focus status of a comment by its id if comment is found or do nothing if comment is not found
    updateFocusStatus(id, focusStatus) {
      const comment =  id.startsWith('reco_')? this.getRecommendationById(id) : this.getCommentById(id);
      if (comment) {
        comment.focus = focusStatus;
      }
    },
    // Method to get the content status of a comment by its id or NULL if comment is not found
    getContentStatus(id) {
      const comment = this.getCommentById(id);
      return comment ? comment.selected_content : null;
    },
    //Check the contents of a comment and update its content status
    updateContentStatus(id, original_content, diff_array, edited_content){
      console.log('Inside update content status for comment id: '+id);
      console.log('original content: '+original_content);
      console.log('edited content: '+edited_content);
      const comment = this.getCommentById(id);
      if (!comment) return; // do nothing if comment wasn't found
      const hasValidContent = original_content !==undefined && original_content !==null 
                              && Array.isArray(original_content) &&  original_content.length > 0;
      console.log('Has valid content: '+hasValidContent);
      let orig_content_copy = hasValidContent ? [...original_content] : []; //create a copy of the object before manipulating it
      const generated_content = this.getGeneratedContent(orig_content_copy, diff_array).join(' ').trim();
      console.log('Generated content:\n'+generated_content);
      if (typeof edited_content !== 'string') {
        console.log('edited content is not a string');
        edited_content = edited_content.join(' ');
      }
      edited_content = edited_content.trim();
      console.log('Edited content string:\n'+edited_content);
      if (Array.isArray(orig_content_copy)) {
        console.log('original content is an array');
        orig_content_copy = this.convertArrayToString(orig_content_copy).trim();
      }
      console.log('Original content string:\n'+orig_content_copy);
      if (edited_content === orig_content_copy) {
        console.log('setting content status  '+comment.selected_content +' to original');
        comment.selected_content = 'original';
      } else if (edited_content === generated_content.trim()) {
        console.log('setting content status  '+comment.selected_content +' to generated');
        comment.selected_content = 'generated';
      } else {
        console.log('setting content status from '+comment.selected_content +' to edited');
        comment.selected_content = 'edited';
      }
      console.log('original_content: '+original_content);
      console.log('diff array: '+diff_array);
      console.log('edited content: '+edited_content);
    },
    // Convert an array of strings into a single paragraph string, with each sentence separated by a period and a space
    convertArrayToString(item_array) {
      // Join the modifiedContent array into a single string with periods and spaces
      return item_array.map(content => {
        if (content.endsWith('.')) {
          return content;
        } else {
          return content + '.';
        }
      }).join(' ');
    },
    //Reconstruct the AI generated content as an array from the original content and diff object
    getGeneratedContent(original_content, diff_array) {
      const generated_content = [];
      let index;
      // Convert original_content to an array if it's not already one
      const original_array = Array.isArray(original_content) ? original_content : Object.values(original_content);
      console.log('Constructing generated content from Original array:\n'+original_array);
      // each comment starts with an action [+], [-] or [=]
      for (const comment of diff_array) {
        const action = comment.substring(0, 3);
        const content = comment.substring(4);

        switch (action) {
          case '[+]':
            console.log('Add Action: '+action+', content: '+content);
            generated_content.push(content);
            break;
          case '[-]':
            console.log('Remove Action: '+action+', content: '+content);
            index = original_array.indexOf(content);
            if (index !== -1) {
              original_array.splice(index, 1);
            }
            break;
          default:
            console.log('No Action: '+action+', content: '+content);
            generated_content.push(content); //add the content to the generated content
            index = original_array.indexOf(content); //remove the content from the original content
            if (index !== -1) {
              original_array.splice(index, 1);
            }
            break;
        }
      }
      // Add any remaining original content that was not modified
      generated_content.push(...original_array);
      return generated_content;
    },
    // Restore the original or generated professional summary
    restoreSummaryContent(commentId, updateType) {
      console.log('Restoring professional summary content to ' + updateType +' for id: '+commentId);
      const comment = this.getCommentById(commentId);
      if (!comment) return; //if comment is not found, exit
      const altContent = this.userEditedResume.professional_summary?.alt_content || [];
      const orig_content_copy = this.getOriginalContentCopy(altContent);
    
      if (updateType ==='original') {
        this.userEditedResume.professional_summary.summary = this.convertArrayToString(orig_content_copy).trim();
      } else if (updateType ==='generated') {
        this.userEditedResume.professional_summary.summary = this.getGeneratedContent(orig_content_copy, this.userEditedResume.professional_summary.comments);
      } else { //invalid update type
        console.log('Invalid update type: '+updateType);
        return;
      }
      //Finally updte the content status 
      comment.selected_content= updateType;
      console.log('comment id: '+ commentId+' status= '+comment.selected_content);
    },
    //Restore the original or generated job responsibilities of a position based on the commentId
    restorePositionContent(commentId, updateType) {
      console.log('Restoring job responsibilities for id: '+commentId);
      const comment = this.getCommentById(commentId);
      if (!comment) return; //if comment is not found, exit

      for (const experience of this.userEditedResume.experiences) {
        for (const position of experience.positions) {
          if (position.id === commentId) {
            //position match found..
            const altContent = position.alt_content || [];
            const orig_content_copy = this.getOriginalContentCopy(altContent); //create a copy of the original content
            
            if (updateType ==='original') {
              position.job_responsibilities = orig_content_copy ;
            } else if (updateType ==='generated') {
              position.job_responsibilities = this.getGeneratedContent(orig_content_copy, position.comments);
            } else { //invalid update type
              console.log('Invalid update type: '+updateType);
              return;
            }
            //Finally updte the content status 
            comment.selected_content= updateType;
            console.log('comment id: '+ commentId+' status= '+comment.selected_content);
            return; // Exit once a match is found
          }
        }
      }
    },
    //Restore the original or generated project bullets based on the commentId
    restoreProjectContent(commentId, updateType) {
      console.log('Restoring project bullets for id: '+commentId);
      const comment = this.getCommentById(commentId);
      if (!comment) return; //if comment is not found, exit

      for (const project of this.userEditedResume.projects_and_assignments?.projects) {
        if (project.id === commentId) {
          //project match found..
          const altContent = project.alt_content || [];
          const orig_content_copy = this.getOriginalContentCopy(altContent); //create a copy of the original content
          
          if (updateType ==='original') {
            project.items_array = orig_content_copy ;
          } else if (updateType ==='generated') {
            project.items_array = this.getGeneratedContent(orig_content_copy, project.comments);
          } else { //invalid update type
            console.log('Invalid update type: '+updateType);
            return;
          }
          //Finally updte the content status 
          comment.selected_content= updateType;
          console.log('comment id: '+ commentId+' status= '+comment.selected_content);
          return; // Exit once a match is found
        }
      }
    },
    //Return a copy of the original contents of a resume section/ sub section as an array of strings
    // Input: altContent key (can be an array or a string)
    getOriginalContentCopy(altContent) {
      let orig_content_copy = [];
      if (altContent.length > 0) {
        if (Array.isArray(altContent)) {
          orig_content_copy = [...altContent];
        } else { //assuming it's a string
           // Split by period followed by space, preserve periods in each element
            orig_content_copy = altContent
              .split(/(?<=\.)\s+/)  // Split after period followed by whitespace
              .filter(sentence => sentence.trim())  // Remove empty strings
              .map(sentence => {
                sentence = sentence.trim();
                // Ensure sentence ends with period
                return sentence.endsWith('.') ? sentence : sentence + '.';
              });
        }
      }
      return orig_content_copy;
    },
    //reset the focus of all comments to false
    unfocusAllComments() {
      this.comments.forEach((comment) => {
          comment.focus=false;
        });
    },
    //reset the focus of all recommendations to false
    unfocusAllRecommendations() {
      this.recommendations.forEach((comment) => {
          comment.focus=false;
        });
    },
    /**
     * Accept or rejecta recommendation to hide a section/date or part of a section.
     * @param {Object} details - An object containing the following properties:
     *   - recommendationKey: The section (key) in the resume.
     *   - id: The id of the section/ item in the section.
     *   - accept: A boolean indicating whether to accept or reject the recommendation.
     *   - hideType: The type of hide recommendation (hide_item, hide_date) when section is education.
     */
     handleRecommendationAction(details) {
      // console.log('Handling recommendation action: '+JSON.stringify(details));
      const section = details.recommendationKey;
      const accept = details.accept;
      if (section === 'experiences') {
        const { experienceIndex, positionIndex } = this.getExperiencePositionIndex(details.id);
        if (experienceIndex === -1 || positionIndex === -1) return; //if a matching position isn't found, exit
        this.userEditedResume.experiences[experienceIndex].positions[positionIndex].hide = accept;
        // When accepting a hide suggestion OR
        // When rejecting a hide suggestion AND all positions are shown
        if (accept || this.experienceCounts.hidden === 0) {
          this.resumePreferences.experienceHide = accept;
          this.userEditedResume.hide_experiences = accept;
        }
        // console.log('Executed hide recommendation for experience['+experienceIndex+'] position['+positionIndex+'], where accept: '+accept);
      } else if (section === 'educational_qualifications') {
        const hideType = details.hideType;
        if (hideType === 'hide_date') { //hide the date
          this.updateDateVisibility(accept);
          return;
        }
        const arrayKey = 'educations';
        if (hideType === 'hide_item') { //hide the degree
          this.updateSectionItemVisibility(section, arrayKey, details.id, accept);
        } 
      } else if (section === 'projects_and_assignments' || this.isDateSection(section)) {
        const arrayKey = this.isDateSection(section)? 'items_array' : 'projects';
        this.updateSectionItemVisibility(section, arrayKey, details.id, accept);
      } else { //generic section
        this.userEditedResume[section].hide = accept;
        if (!this.resumePreferences.sectionHide) { //if sectionHide preferences does not exist
          this.resumePreferences.sectionHide = {}; //create the sectinoHidepreferences object
        }
        this.resumePreferences.sectionHide[section] = accept; // finally udate user's hide preferences
      }
    },
    /**
     * Updates the hide settings for an item in a section with nested array, and updates user's hide preferences for the section
     * @param {string} section - the section name
     * @param {string} arrayKey - the array key in the section
     * @param {number} id - the id of the item in the array
     */
    updateSectionItemVisibility(section, arrayKey, id, hide) {
      const index = this.getItemIndex(section, arrayKey, id);
      // console.log('Update visibility for section['+section+'] arrayKey['+arrayKey+'] index['+index+'] where accept: '+hide);
      if (index === -1) return; // exit if item is not found
      this.userEditedResume[section][arrayKey][index].hide = hide;
      // When accepting a hide suggestion OR
      // When rejecting a hide suggestion AND all items are already visible
      if (hide || this.itemCounts(section).hidden === 0) {
        this.userEditedResume[section].hide = hide; //update the top/ section level hide settings
        // Then update resume preferences
        if (!this.resumePreferences.sectionHide) { //if sectionHide preferences does not exist
          this.resumePreferences.sectionHide = {}; //create the sectinoHidepreferences object
        }
        this.resumePreferences.sectionHide[section] = hide; // finally udate user's hide preferences
      }
    },
/**
 * Updates the visibility of dates within the educational qualifications section.
 * Sets the hide_date property for each education item and the section as a whole.
 * Also updates the user's resume preferences for date visibility.
 * @param {boolean} hide - A boolean indicating whether to hide or show the dates.
 */
    updateDateVisibility(hide) {
      for ( const education of this.userEditedResume.educational_qualifications.educations) {
        education.hide_date = hide; //update degree level hide settings
      }
      // Update section level date hide settings
      this.userEditedResume.educational_qualifications.hide_date = hide;
      // Then update resume preferences
      this.resumePreferences.dateHide = hide;
    },
    //Get the job application details from server. Return true if application is retrieved from the server
    async getApplicationFromServer() {
      console.log('Getting (resume) application details from server for application: ' + this.applicationId );
      this.resume_in_progress = true; //set the flag while retrieving resume from the server
      let application_available = false;
      try {
          const response = await axios.get(`/api/v1/user_applications/${this.applicationId}`);
          console.log('Status for getting job application details from server: ' + response.status);
          // set userEditedResume to the resume from the server
          const userApplication = response.data;
          if (userApplication.resume){ //If a resume is available in the user applicaiton records
            this.userEditedResume = userApplication.resume;
            this.initializeSectionProperties();
            this.$store.commit('setApplicationJSON', { version: this.selectedVersion, response: userApplication });
            if (userApplication.resume_preferences) {// overwrite the resume preferences in the Vue store
              this.resumePreferences = userApplication.resume_preferences; 
              this.$store.commit('setResumePreferences', { config: userApplication.resume_preferences });
            }
            await this.initializeResumeSettings(); //initialize resume settings
            application_available=true;
          } else {
            console.log('Resume not found in the server');
          }
      } catch (error) {
          // In case there's an error making any of the API calls
          console.error('Error fetching job application:', error);
      }
      this.resume_in_progress=false; //reset the flag after the call to the server has completed
      return application_available;
    },
    //Save resume and recalculate ATS score
    async saveAndRecalculateScore() {
      console.log('recalculating ats score');
      const now = new Date();
      try {
        const response = await axios.patch(`/api/v1/user_applications/${this.applicationId}/recalculate_ats_score`, {
          user_application: {
            resume: this.userEditedResume, //save the application json
            resume_preferences: this.resumePreferences, // save the resume preferences
            applied_at: now.toISOString(),   //update the application time
          },
        },);
        if (response.data) {
          this.ats_comment=response.data;
          this.toast.showToast('Success', this.MESSAGE_SCORE_UPDATED);
        }
      } catch (error) {
        // In case there's an error making any of the API calls
        console.error('Error re-calculating ATS score:', error);
        this.toast.showToast('Warning', this.MESSAGE_ERROR_GENERAL);
      }
    },
    //Update the job application details in server
    async saveApplicationToServer(showToast=true) {
      console.log('Saving application to server');
      const now = new Date();
      try {
        const response = await axios.patch(`/api/v1/user_applications/${this.applicationId}`, {
          user_application: {
            resume: this.userEditedResume, //save the application json
            resume_preferences: this.resumePreferences, // save the resume preferences
            applied_at: now.toISOString(),   //update the application time
          },
        },);
        if (response.status >= 200 && response.status < 300) {
          if (showToast) this.toast.showToast('Success', this.MESSAGE_RESUME_SAVED);
        }
      } catch (error) {
        // In case there's an error making any of the API calls
        console.error('Error creating a job application:', error);
        // Do not navigate users to the job match screen
      }
    },
    // Return to home screen without saving
    navigateTo(destination){
      this.$store.commit('setPreviousRoute', this.$route.name); //save the previous route name in Vue store
      this.$router.push({ name: destination });
    },
    navigatePrevious(){
      if(this.routeOrigin === 'History') this.navigateTo('History'); //if the previous page is the home page, also save the resume
      else this.saveAndNavigate('Home'); //otherwise just navigate to it
    },
    //Save the application to server and navigate to the destination screen
    async saveAndNavigate(destination) {
      await this.saveApplicationToServer();
      this.$store.commit('setPreviousRoute', this.$route.name); //save the previous route name in Vue store
      if (destination ==='Cover Letter') {
        this.$router.push({ name: destination, query: { application_id: this.applicationId }});
      } else { //home
        this.$router.push({ name: destination, query: { application_id: this.applicationId }});
      }
    },
    //Generate a cover letter for the given application
    async generateCoverLetter() {
      console.log('Inside Generate Cover Letter')
      try {
        // Make an API call to queue the cover letter generation
        const response = await axios.post(`/api/v1/user_applications/${this.applicationId}/generate_cover_letter`);
        if (response.status === 202) { //if the application was successfully queued
          const cover_letter_job_id = response.data.job_id// get the job id
          this.pollAndGetCoverLetter(cover_letter_job_id);
        } else {
            console.log('Failed to queue cover letter job');
        }
      } catch (error) {
          console.error('Error queuing cover letter generation', error);
      }
    },
    // Get the cover letter once available
    // Max duration: 50 seconds
    async pollAndGetCoverLetter(job_id) {
      console.log( 'Inside Poll and Get Cover Letter for job id: '+job_id);
      const poll_interval = 10000; //poll every 10 seconds
      const max_poll_attempts = 5; //poll a max of 5 times
      const generated_cover_letter = await this.jobSuccessful(job_id, 'cover_letter', max_poll_attempts, poll_interval);

      if(generated_cover_letter) {
        console.log('Cover letter returned by server..');
        // save cover letter to Vue store
        this.$store.commit('updateCoverLetter', {
          version: 1, 
          cover_letter: generated_cover_letter,
        });
      } else {
        console.warn('Server failed to generate cover letter');
      }
    },
     // Poll the backend to check the status of a job
    // Return the actual result if job has completed successfully, null otherwise
    async jobSuccessful(job_id, job_type, max_poll_attempts, poll_interval) {
      console.log('Checking success status for job id: ' + job_id);
      if (!job_id || this.cancel_application) return null; // return null if an empty job id was passed

      let poll_attempts = 0;

      return new Promise((resolve, reject) => {
        const pollStatus = async () => {
          try {

            const statusResponse = await axios.get(`/api/v1/user_applications/${this.applicationId}/job_status?job_id=${job_id}`);
            const jobStatus = statusResponse.data.status;

            if (jobStatus === 'completed') {
              // Job succeeded
              console.log('Job id: ' + job_id + ' successfully completed');
              if (job_type === 'export') { //get the pdf from the download url
                const downloadResponse = await axios.get(`/api/v1/user_applications/${this.applicationId}/download_pdf?job_id=${job_id}`, {
                  responseType: 'blob',
                });
                const blob = new Blob([downloadResponse.data], { type: 'application/pdf' });
                resolve(blob);
              } else {
                resolve(statusResponse.data.result);
              }
            } else if (jobStatus === 'working' || jobStatus === 'queued' || !jobStatus) {
              // Job still in progress, continue polling
              console.log('Job id: ' + job_id + ' is still in progress..will poll again in a few...');
              poll_attempts++;

              if (poll_attempts < max_poll_attempts) {
                setTimeout(pollStatus, poll_interval);
              } else {
                console.log('Job id: ' + job_id + ' Hit max poll attempts. Quitting ...');
                resolve(null);
              }
            } else {
              // Job failed
              console.log('Job id: ' + job_id + ' failed with status: ' + jobStatus);
              resolve(null);
            }
          } catch (error) {
            console.log('Job id: ' + job_id + ' failed with error: ' + error);
            reject(error);
          }
        };
        // Start polling
        pollStatus();
      });
    },
    updateScaleFactor () { //Update the scale factor for the div class _wrapper_1axvs_1 to fit the resume width
      console.log("Inside update scale factor: current scale factor ="+this.scaleFactor);
      this.windowWidth = window.innerWidth;
      console.log('Window width ='+this.windowWidth);
      console.log('Panel container width before ='+this.$refs.panelContainer.offsetWidth);
      const panelWidth = this.windowWidth > 1280? Math.min((this.windowWidth - 40)*21/32, 1240): 820;
      console.log('Computed panel width: '+panelWidth);
      const panelContainer = this.$refs.panelContainer;
      if (panelContainer) {
        // Update the width of the element
        panelContainer.style.width = panelWidth + 'px';
      }
      // this.scaleFactor = (( this.$refs.panelContainer ? this.$refs.panelContainer.offsetWidth : 820)  - 20) / 650; //Subtract 20px for the margins; The resume template is designed for 650px width. Hence 650 in the denominator
      this.scaleFactor = ( panelWidth  - 20) / 650; //Subtract 20px for the margins; The resume template is designed for 650px width. Hence 650 in the denominator
      console.log('After resizing, scale factor ='+this.scaleFactor);
      console.log('Panel container width after='+this.$refs.panelContainer.offsetWidth);
    },
    //Return the zoom factor to fit 1 page of the resume in the available screen height
    getFitZoomFactor() {
      const viewportHeight = window.innerHeight;
      const availableHeight = viewportHeight - 45 - 5.625 * parseFloat(getComputedStyle(document.documentElement).fontSize); //Subtract the top nav, menu container and margin heights to get the available page height
      console.log('Panel Height = '+availableHeight);
      console.log('scaledPageHeight = '+this.scaledPageHeight);
      const zoom = availableHeight/(this.scaledPageHeight * 1.23076923); //Note: 100% zoom = 1.23076923. Hence the further division by 1.23076923
      return zoom;
    },
    // Combine two predefined strings by replacing the $$$
    // If it is for a button, then it returns the combined string if it is within 18 characters, esle it returns 'action' without $$$
    combinedLabel(action, noun, isButton) {
      if (action !== '' && noun !== '') {
        const combinedString = action.replace('$$$', noun);
        if (isButton && combinedString.length > 10) {
          return action.replace(/ \$+$/, '').trim();
        } else {
          return combinedString;
        }
      } else if (action !== '' && noun === '') {
        const actionString = action.replace(/ \$+$/, '').trim();
        return actionString;
      } else {
        return '';
      }
    },
    /**
     * Toggles the visibility of the degree/ education at the given index in the educational_qualifications array
     * Additionally, updates the sectionItemHide preferences if the degree is hidden/ unhidden
     * @param {number} index - The index of the degree to toggle
     */
     toggleDegreeVisibility(index) {
      if (this.userEditedResume.educational_qualifications.educations[index]) {
        const degree = this.userEditedResume.educational_qualifications.educations[index];
        const hide = degree.hide || false;
        degree.hide = !hide;
        
        // Initialize sectionItemHide if it doesn't exist
        if (!this.resumePreferences.sectionItemHide) {
          this.resumePreferences.sectionItemHide = [];
        }

        if (degree.hide) {
          // Add ID if not already in array
          if (!this.resumePreferences.sectionItemHide.includes(degree.id)) {
            this.resumePreferences.sectionItemHide.push(degree.id);
          }
        } else {
          // Remove ID if exists in array
          const idIndex = this.resumePreferences.sectionItemHide.indexOf(degree.id);
          if (idIndex > -1) {
            this.resumePreferences.sectionItemHide.splice(idIndex, 1);
          }
        }
        console.log('Updated resume preferences after toggling degree visibility at index: '+index+': '+JSON.stringify(this.resumePreferences));
      }
    },
    /**
     * Toggles the visibility of the project at the given index in the projects_and_assignments array
     * Additionally, updates the sectionItemHide preferences if the project is hidden/ unhidden
     * @param {number} index - The index of the project to toggle
     */
    toggleProjectVisibility(index) {
      if (this.userEditedResume.projects_and_assignments.projects[index]) {
        const project = this.userEditedResume.projects_and_assignments.projects[index];
        const hide = project.hide || false;
        project.hide = !hide;
        
        // Initialize sectionItemHide if it doesn't exist
        if (!this.resumePreferences.sectionItemHide) {
          this.resumePreferences.sectionItemHide = [];
        }

        if (project.hide) {
          // Add ID if not already in array
          if (!this.resumePreferences.sectionItemHide.includes(project.id)) {
            this.resumePreferences.sectionItemHide.push(project.id);
          }
        } else {
          // Remove ID if exists in array
          const idIndex = this.resumePreferences.sectionItemHide.indexOf(project.id);
          if (idIndex > -1) {
            this.resumePreferences.sectionItemHide.splice(idIndex, 1);
          }
        }
        console.log('Updated resume preferences after toggling project visibility at index: '+index+': '+JSON.stringify(this.resumePreferences));
      }
    },
    /**
     * Toggles the visibility of the position at the given index in the experiences array
     * Additionally, updates the sectionItemHide preferences if the position is hidden/ unhidden
     * @param {number} index - The index of the experience in the experiences array
     * @param {number} positionIndex - The index of the position in the positions array of the experience
     */
    togglePositionVisibility(index, positionIndex) {
      if (this.userEditedResume.experiences[index] && 
          this.userEditedResume.experiences[index].positions[positionIndex]) {
        
        const position = this.userEditedResume.experiences[index].positions[positionIndex];
        const hide = position.hide || false;
        position.hide = !hide;
        
        // Initialize sectionItemHide if it doesn't exist
        if (!this.resumePreferences.sectionItemHide) {
          this.resumePreferences.sectionItemHide = [];  // Direct assignment works in Vue 3
        }

        if (position.hide) {
          // Add ID if not already in array
          if (!this.resumePreferences.sectionItemHide.includes(position.id)) {
            this.resumePreferences.sectionItemHide.push(position.id);
          }
        } else {
          // Remove ID if exists in array
          const idIndex = this.resumePreferences.sectionItemHide.indexOf(position.id);
          if (idIndex > -1) {
            this.resumePreferences.sectionItemHide.splice(idIndex, 1);
          }
        }
        console.log('Updated resume preferences after toggling position visibility at index: '+index+', positionIndex: '+positionIndex+': '+JSON.stringify(this.resumePreferences));
      }
    },
    /**
    * Toggles the visibility of an item within a specified section of the resume.
    * Additionally, updates the sectionItemHide preferences if the section item is hidden/ unhidden
    * @param {string} section - The key of the resume section containing the item.
    * @param {number} index - The index of the item within the section's items array.
    */
    toggleSectionItemVisibility(section, index) {
      if (this.userEditedResume[section] && this.userEditedResume[section].items_array[index]) {
        const sectionItem = this.userEditedResume[section].items_array[index];
        const hide = sectionItem.hide || false;
        sectionItem.hide = !hide;
        
        // Initialize sectionItemHide if it doesn't exist
        if (!this.resumePreferences.sectionItemHide) {
          this.resumePreferences.sectionItemHide = [];
        }

        if (sectionItem.hide) {
          // Add ID if not already in array
          if (!this.resumePreferences.sectionItemHide.includes(sectionItem.id)) {
            this.resumePreferences.sectionItemHide.push(sectionItem.id);
          }
        } else {
          // Remove ID if exists in array
          const idIndex = this.resumePreferences.sectionItemHide.indexOf(sectionItem.id);
          if (idIndex > -1) {
            this.resumePreferences.sectionItemHide.splice(idIndex, 1);
          }
        }
        console.log('Updated resume preferences after toggling section '+section+' visibility at index: '+index+': '+JSON.stringify(this.resumePreferences));
      }
    },
    // The tooltip text for the count bubble
    getCountTooltip(count, label_singular, label_plural, hidden) {
      if (count == 1 && hidden) {
        return count + ' ' + label_singular + ' ' + this.TOOLTIP_HIDDEN;
      } else if (count != 1 && hidden) {
        return count + ' ' + label_plural + ' ' + this.TOOLTIP_HIDDEN;
      } else if (count == 1 && !hidden) {
        return count + ' ' + label_singular;
      } else {
        return count + ' ' + label_plural;
      }
    },
    focusCommentTab(focusState) {
      if (this.comment_tab !== focusState) this.comment_tab = focusState;
    }
  },
};
</script>

<style scoped>
.TopMenuScaffold {  /* Override the default TopMenuScaffold style */
  width: calc( 100% - 3.625rem); /*subtract the panel toggle container width */
}
.List {
  width: 100%;
  margin-top: 0;
}
.BottomButtonRow {
  display: flex;
  justify-content: flex-end;
  margin: 0.625rem 0 0.25rem 0;
  height: 2.5rem;
}

.BottomButtonRow.LeftAligned {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.BottomButtonRow.LeftAligned :deep(.selector-container) {
  width: 100%;
  max-width: 300px; /* Adjust this value as needed */
}

.BottomButtonRow.LeftAligned :deep(.selector-button) {
  width: 100%;
}

.BottomButtonRow.LeftAligned :deep(.selector-options) {
  width: 100%;
  min-width: 100%;
}

.BottomButtonRow.LeftAligned :deep(.selector-option) {
  width: 100%;
}
.AdditionalSectionsDropdownContainer {
  width: 100%;
  text-align: end;
  margin-bottom: 0.625rem;
}
.CardList {
  width: calc(100% - 2.5rem);
  margin: 0 1.25rem;
}
.ScoreBoostComment {
  color: var(--color-input-selected);
}
.GrandChildCard { /* A modification of regular card with smaller width */
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  transition: max-height var(--transition-time) ease-out;
  width: calc(100% - 2.5rem); /* Updated width to subtract left and right margins*/
  padding: 0.625rem 0;
  margin: 0.625rem 1.25rem;  /* Updated margin to add left and right margings of 1.25 rem */
  border: var(--border-thickness-normal) solid var(--color-card-border);
  border-radius: var(--card-border-radius);
  background: var(--color-background-canvas);
}
.GrandChildCard:hover {
  border: var(--border-thickness-normal) solid var(--color-input-normal);
}
.GrandChildCard:focus,
.GrandChildCard:active {
  border: var(--border-thickness-normal) solid var(--color-input-selected);
  transition: border var(--transition-time) ease-in-out;
}
.CommentPanelHeader{
  display: flex;
  padding: 0.625rem 1rem 0 0;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0.625rem;
  align-self: flex-start;
  width: 100%;
  border-bottom: var(--border-thickness-normal) solid var(--color-input-disabled);
  transition: max-height var(--transition-time) ease-out;
}

.CommentTabContainer {
  display: flex;
  width: calc(100% - 2.375rem);
}

.CommentCollapsed {
  max-height: 3rem;
  overflow-y: visible;
  top: calc(100% - 3rem);
}
.tooltip-container {
  position: relative;
}
.tooltip-trigger {
  height: 1.5rem
}
.tooltip-trigger::before {
  content: attr(tooltip-text);
  position: absolute;
  transform: translateY(2.75rem);
  background-color: var(--color-input-secondary);
  color: var(--color-background-canvas);
  padding: 0 0.25rem;
  border-radius: var(--button-border-radius);
  font-size: var(--size-x-small);
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--transition-time) ease;
  z-index: var(--z-index-tooltip);
}

.tooltip-trigger:hover::before {
  opacity: 1;
}
.SectionNameField {
  flex: 2;
}
.SectionDateField {
  flex: 1;
}

.PageFormatting {
  --resume-page-gap: 10px;
}

.item-drag-handle {
  cursor: move;
  margin-right: 10px;
}

.ThemedDropdown {
  /* Add your styling here to match your app's theme */
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  background-color: var(--white);
  color: var(--gray-900);
  font-size: 14px;
  width: 100%;
}

.selector-wrapper {
  width: 100%;
  max-width: 300px; /* Adjust this value as needed */
}

.selector-wrapper :deep(.selector-container) {
  width: 100%;
}

.selector-wrapper :deep(.selector-button) {
  width: 100%;
}

.selector-wrapper :deep(.selector-options) {
  width: 100%;
  min-width: 100%;
}

.selector-wrapper :deep(.selector-option) {
  width: 100%;
}

.selector-wrapper :deep(.selector-option[disabled]) {
  color: var(--gray-600);
  font-weight: bold;
  cursor: default;
}
</style>





  