import axios from 'axios';
import { useStore } from 'vuex';
import { computed } from 'vue';

export function userAuthentication() {
    const store = useStore();

    // Computed property for user details
    const userDetails = computed(() => store.state.userDetails);

    // Authentication check function
    const checkAuthentication = async () => {
        console.log('inside check authentication: ');
        try {
            const response = await axios.get('/api/v1/auth/next');
            console.log('Authentication validation response: ' + response.data.next);

            if (response.data.next) {
                const user_details = response.data.user_details;
                console.log('User details received from server: ' + JSON.stringify(user_details));
                store.commit('setUserDetails', { userDetails: user_details });
            }

            console.log('User details object after setting userID in check authentication: ' + JSON.stringify(userDetails.value));
            return response.data.next;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    return {
        userDetails,
        checkAuthentication
    };
}