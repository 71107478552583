<template>
    <div :class="(template === 'NewYork' || template === 'Tokyo') && pageLayout!=='Center'? '_section_side_header' : '_header'">
      <!-- Resume Level Comments and Recommendations -->
      <feedback
        v-if="showCommentIndicators"
        :comments="headerComments"
        :recommendations="sectionLevelRecommendations"
        :position="commentPosition"
        :on-margin="true"
      />
      <div :class="{
        '_info_panel': template === 'SanFrancisco' || ((template === 'NewYork' || template === 'Tokyo') && pageLayout === 'Center'),
        '_personal_info_section': template === 'Berlin'
        }">
        <!-- Name Section -->
        <div :class="{
          '_personal_info': template === 'SanFrancisco' || ((template === 'NewYork' || template === 'Tokyo') && pageLayout === 'Center')
          }"
          itemscope 
          itemtype="http://schema.org/Person"
        >
          <div class="_name_panel" 
            :class="{'PrimaryColor': template === 'Tokyo' || (template === 'NewYork' && pageLayout === 'Center')}"
            itemprop="name">
            {{ name }}
          </div>
        </div>
        <!-- Contact Information -->
        <div :class="{'_contacts_section': template === 'London' || template === 'Berlin',
          '_contacts_panel': template === 'SanFrancisco',
          '_contacts_panel_center': (template === 'NewYork' || template === 'Tokyo') && pageLayout === 'Center',
          '_contacts_panel_side': (template === 'NewYork' || template === 'Tokyo') && pageLayout !== 'Center',
        }" 
          v-if="hasContactInfo" 
          itemprop="contactInformation" 
          itemscope 
          itemtype="http://schema.org/ContactPoint"
        >
          <!-- City -->
          <template v-if="contactInfo.city">
            <div v-if="template === 'SanFrancisco'">■</div>
            <span class="_contact_item" itemprop="addressLocality"> {{ contactInfo.city }}</span>
          </template>
          <!-- Phone -->
          <template v-if="contactInfo.phone_number">
            <div v-if="template === 'SanFrancisco'">■</div>
            <a
              :class="linkClass"
              :href="'tel:' + contactInfo.phone_number"
              itemprop="telephone"
            >
              {{ contactInfo.phone_number }}
            </a>
          </template>
          <!-- Email -->
          <template v-if="contactInfo.email">
            <div v-if="template === 'SanFrancisco'">■</div>
            <a
              :class="linkClass"
              :href="'mailto:' + contactInfo.email"
              itemprop="email"
            >
              {{ contactInfo.email }}
            </a>
          </template>
          <!-- LinkedIn -->
          <template v-if="contactInfo.linkedin_id">
            <div v-if="template === 'SanFrancisco'">■</div>
            <a
              :class="linkClass"
              :href="getLinkedInURL(contactInfo.linkedin_id)"
              target="_blank"
            >
              {{ getLinkedInId(contactInfo.linkedin_id) }}
            </a>
          </template>
          <!-- Website -->
          <template v-if="contactInfo.website">
            <div v-if="template === 'SanFrancisco'">■</div>
            <a
              :class="linkClass"
              :href="getURLString(contactInfo.website)"
              target="_blank"
            >
              {{ contactInfo.website_label || contactInfo.website }}
            </a>
          </template>
          <!-- GitHub -->
          <template v-if="contactInfo.github">
            <div v-if="template === 'SanFrancisco'">■</div>
            <a
              :class="linkClass"
              :href="getGitHubURL(contactInfo.github)"
              target="_blank"
            >
              {{ getGitHubId(contactInfo.github) }}
            </a>
          </template>
          <!-- Other Social Media -->
          <template v-if="contactInfo.other_social_media">
            <div v-if="template === 'SanFrancisco'">■</div>
            <a
              :class="linkClass"
              :href="getURLString(contactInfo.other_social_media)"
              target="_blank"
            >
              {{ contactInfo.other_social_media_label || contactInfo.other_social_media }}
            </a>
          </template>
        </div>
      </div>
      <hr v-if="template === 'Berlin'"/>
    </div>
  </template>
  
  <script>
  import Feedback from './Feedback.vue'
  import { urlHelpers } from '../../mixins/urlHelpers'
  import { labelHelpers } from '../../mixins/labelHelpers'
  import { layoutHelpers } from '../../mixins/layoutHelpers'
  
  export default {
    name: 'ResumeHeader',
    components: {
      Feedback
    },
    mixins: [urlHelpers, labelHelpers, layoutHelpers],
    props: {
      template: {
        type: String,
        required: true
      },
      pageLayout: {
        type: String,
        default: 'Center',
        validator: value => ['Left', 'Right', 'Center'].includes(value)
      },
      name: { // candidate's name
        type: String,
        required: true
      },
      contactInfo: { // candidate's contact information
        type: Object,
        required: true,
        validator: (obj) => {
          const requiredProps = [
            'email',
            'phone_number',
            'city',
            'linkedin_id',
            'website',
            'github',
            'other_social_media'
          ]
          return requiredProps.every(prop => prop in obj)
        }
      },
      showCommentIndicators: { //show/ hide comment and recommendation indicators
        type: Boolean,
        default: false
      },
      resumeId: { //id of the ATS score comment
        type: String,
        default: null
      },
      sectionOrderId: { //id of the section order comment
        type: String,
        default: null
      },
      sectionOrderChangeReason: {
        type: Array,
        default: () => []
      },
      comments: { //array of comments
        type: Array,
        required: true
      },
      hasRecommendations: {
        type: Boolean,
        default: false
      },
      recommendations: { //array of recommendations
        type: Array,
        required: true
      }
    },
    data() {
      return {
        sectionKey: 'resume',
      }
    },
    computed: {
      headerComments() {
        return [
          // ATS Comment
          this.resumeId && {
            id: this.resumeId,
            label: this.getCommentIndex(this.resumeId)
          },
          // Section Order Comment
          this.sectionOrderChangeReason?.length > 0 && {
            id: this.sectionOrderId,
            label: this.getCommentIndex(this.sectionOrderId)
          }
        ].filter(Boolean)  // Remove falsy values
      },
      commentPosition() {
        if (this.template === 'SanFrancisco' || this.template === 'Berlin' || 
        ( (this.template === 'NewYork' || this.template === 'Tokyo') && this.pageLayout !== 'Center')) {
          return 'top';
        } else {
          return 'left'; //default
        }
      },
      linkClass() {
        if ( (this.template === 'NewYork' || this.template === 'Tokyo') && this.pageLayout !== 'Center') return '';
        else return ' _custom_link';
      }
    },
  }
  </script>