<template>
    <div class="Selector" :class="selectorClasses">
      <!-- Header -->
      <div class="DropdownHeader" :class="headerClasses"
       @mouseover="handleMouseOver" @mouseout="handleMouseOut" @keydown="handleKeyDown" @click="handleSelectorClick">
        <div class="HeaderIconContainer" v-if="headerIcon">
        <!-- set the color of the icon to the selected color when it's a color selector -->
            <icon :iconName="headerIcon" :static-color="type === 'color'? selectedOption.value : null"/> 
        </div> 
        <div class="HeaderIconContainer" v-else-if="type==='icon-text'">
            <icon :iconName="selectedOption.icon"/> 
        </div> 
        <div class="HeaderTextContainer" v-if="type==='font' || type ==='number' || type ==='percentage' || type ==='text'"> 
            <div class="HeaderRowText TextTruncate" :class="selectorClasses">{{ selectedOption.label }}</div> 
        </div> 
        <div class="DropdwonArrowContainer">
            <icon :iconName="isDropdownExpanded? 'up-arrow-filled': 'down-arrow-filled'"/>
        </div>
      </div>
      <div class="tooltip" :class="tooltipClasses">{{ tooltipText }}</div> 
      <!-- Dropdown List -->
      <transition name="fade">
        <div class="OptionsDropdown" 
          :class="dropdownClasses"
          :style="{ 'max-height': displayHeight + 'rem' }">
          <div class="OptionsRow" :class="option.value === selectedOption?.value ? 'selected':'' " v-for="(option, index) in options" :key="index" 
          @click="handleDropdownClick(option)">
            <div class="OptionsRowIconContainer" :style="{ width: iconWidth + 'rem', height: iconHeight + 'rem' }"  v-if="type=='icon-text'">
                <icon :iconName="option.icon" :iconStroke="1"/> 
                <!-- reducing the stroke width by 2 since the height of the icon is 3rem i.e 48px instead of 24px -->
             </div>
            <div class="OptionsRowIconContainer" :style="{ width: iconWidth + 'rem', height: iconHeight + 'rem', color: option.value }"  v-else-if="type=='color'">
                <icon iconName="gem"/> 
             </div>
             <div class="OptionsRowVerticalGroup">
                <div class="OptionsRowText"  v-if="option.label">
                    <div class="TextParagraph" v-if="type!=='font' && type!=='text'">{{ option.label }}</div>
                    <div class="TextParagraph TextTruncate" 
                      :style="{ 'margin-left' : '0.625rem'}" 
                      v-else-if="type ==='text'">{{ option.label }}</div>
                    <div class="TextParagraph TextTruncate" 
                      :style="{'font-family': option.value , 'margin-left' : '0.625rem'}" 
                      v-else>{{ option.label }}</div>
                </div>
                <div class="OptionsRowText" v-if="option.description && type!='font'">
                    <div class="OptionsRowDescription" v-if="type !=='text'">{{ option.description }}</div>
                    <div class="OptionsRowDescription"
                      :style="{ 'margin-left' : '0.625rem'}" 
                      v-else>{{ option.description }}</div>
                </div>
             </div>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import Icon from './Icon.vue'; 

  export default {
    props: {
      // --- General Settings -------
      componentId: {
        type: String,
        required: true,
      },
      type: {    //the selector type; available options:
        // (1)'icon-text' - shows an icon + text + optional description
        // (2) 'font' - shows font names in the specified font
        // (3) 'color' - shows color circles in the dropdown with the appropriate color
        // (4) 'number' - shows a numerical dropdown up to 2 characters wide
        // (5) 'percentage' - shows a numerical dropdown up to 4 characters wide
        // (6) 'text' - shows a dropdown of text values, with the selected value highlighted
        type: String,
        defaul: 'icon-text',
        validator: function(value) {
            return ['icon-text', 'font', 'color', 'number', 'percentage', 'text'].includes(value);
        },
      },
      headerIcon: {
        type: String,
      },
      tooltipText: {    //tooltip text to show in the header row
        type: String,
      },
      isDisabled: {  //whether the selector is disabled
        type: Boolean,
        default: false,
      },
      // --- Options and Values ------
      options: { // array of options to display in  the dropdown list
        type: Array,
        required: true
      },
      defaultValue: { //The default value selected
        validator: function(value) {
            return typeof value === 'string' || typeof value === 'number';// Check if the value is either a string or a number
        }
      },
      // ---- Formatting ----
      iconHeight: { // Height of the image inside the dropdown
        type: Number,
        default: 2 // Default image size in rem
      },
      iconWidth: { // Width of the image inside the dropdown
        type: Number,
        default: 2 // Default image size in rem
      },
      displayHeight: {
        type: Number, // Maximum height of the dropdown in rem
        default: 50
      },
      tooltipAlignment: {
        type: String,
        default: 'left', // Whether the tooltip is left aligned with the selector or right aligned
        validator: function(value) {
            return ['left', 'right'].includes(value);
        },
      },
      showOutline: {
        type: Boolean, // Whether to show an outline around the selector input
        default: false
      }
    },
    components: {
        Icon,
    },
    data() {
      return {
        isDropdownExpanded: false,
        tooltipVisible: false,
        isHoverState: false,
        isFocusState: false,
        isActiveState: false,
        pressedReturnKey: false, //did the user press the return key?
        handlingClick: false, //when click is being handled
        optionSelected: null, //the option selected
        disabledState: this.isDisabled, 
      };
    },
    computed: {
      // Find the selected option based on the value passed from the parent or the first option if no such option is found
      selectedOption() {
        if (this.optionSelected) {
            return this.optionSelected;
        } else {
            return this.options.find(option => option.value === this.defaultValue) || this.options[0];
        }
      },
      dropdownClasses() {
        let classList =[];
        if (this.isDisabled) return 'hidden';
        if (this.isDropdownExpanded) { 
            classList.push('visible');
        } else {
            classList.push('hidden');
        }
        return classList;
      },
      tooltipClasses() {
        const classList =[];
        if (this.tooltipAlignment === 'left') { 
          classList.push('left');
        } else { 
          classList.push('right');
        }
        if (!this.isDropdownExpanded && this.tooltipVisible) {
          classList.push ('visible');
        } else {
          classList.push ('hidden');
        }
        return classList;
      },
      headerClasses() {
        let classList =[];
        if (this.isDisabled) classList.push('disabled');
        if (this.type==='font') { 
          classList.push('font');
        } else if (this.type ==='text') {
          classList.push('text');
        }
        if (this.isHoverState && !this.isDropdownExpanded) {
            classList.push('hover');
        } else if (!this.isDisabled && (this.isFocusState || this.isActiveState || this.pressedReturnKey) ){
            classList.push('selected');
        }
        return classList;
      },
      selectorClasses() {
        const classList =[];
        // Width classes based on the type of the selector
        if (this.type==='font') classList.push('font-width');
        else if (this.type=='text') classList.push('text-width');
        else if (this.type=='percentage') classList.push('percentage-width');
        else classList.push('icon-width');
        // Outline classes based on whether outline should be shown
        if (this.showOutline) classList.push('outline');
        return classList;
      },
    },
    methods: {
      toggleDropdown() {
        if (!this.isDisabled) this.isDropdownExpanded = !this.isDropdownExpanded;
      },
      // Mouseover on header row/ selector
      handleMouseOver() {
        if (!this.isDropdownExpanded) {
            this.isHoverState = true;
            this.pressedReturnKey=false;
            if (this.tooltipText) {
                this.tooltipVisible = true;
            }
        }
      },
      // Mouse out action on header row/ selector
      handleMouseOut() {
        this.isHoverState = false;
        this.isFocusState = false;
        this.tooltipVisible = false;
        this.pressedReturnKey=false;
      },
      // Keydown action on header row/ selector
      handleKeyDown(event) { //When a user enters a key on the button
        console.log('Key Down');
        if (event.key === 'Enter' || event.key === ' ') {
          this.pressedReturnKey=true;
          this.toggleDropdown();
        } else {
          this.pressedReturnKey=false;
        }
      },
      // Focus action on header row/ selector
      handleFocus() { //when a user tabs in using a keyboard
        // console.log('Focus State');
        if (!this.isDropdownExpanded) {
            this.isFocusState = true;
            if (this.tooltipText) {
            this.tooltipVisible = true;
            }
        }
      },
      handleBlur() { //when a user tabs out of the selector using a keyboard
        this.isFocusState = false;
        this.tooltipVisible=false;
      },
      // Click action on header row/ selector
      handleSelectorClick() {
        this.handlingClick = true; // click is being processed
        setTimeout(() => { // Set a timeout to reset isActiveState after 200ms
            if (!this.isDropdownExpanded) {
                this.isActiveState = true;
            } else {
                this.isActiveState = false;
            }
            this.toggleDropdown();
            this.handlingClick = false; // click processing is complete
            }, 200);
      },
      // Click action on the dropdown
      handleDropdownClick(option) {
        this.handlingClick = true; // click is being processed
        setTimeout(() => { // Set a timeout to reset isActiveState after 200ms
            this.optionSelected = option;
            this.isDropdownExpanded = false;
            this.isActiveState = false;
            this.handlingClick = false; // click processing is complete
            console.log('emitting option-selected for coomponent id = '+this.componentId);
            this.$emit('option-selected', this.componentId, option); //emit an event to the parent informing them about the option that was selected
            }, 200);
      },
      // Method to handle clicks outside the Selector component
      handleClickOutside() {
        // console.log('Selector - handle click outside event listener method');
        if (!this.handlingClick) {
            // console.log('Click was outside the selector and dropdown');
            this.isActiveState = false;
            this.isDropdownExpanded = false;
        }
      },
    },
    async created() {
        //Set the default option
        await this.$nextTick();
        this.optionSelected= this.options.find(option => option.value === this.defaultValue) || this.options[0];
        // console.log('Selector: selected value = '+this.optionSelected.value + ', default value = '+this.defaultValue);
    },
    watch: {
        // Update the selectedOption when the default value changes
        defaultValue(newValue) {
            this.optionSelected = this.options.find(option => option.value === newValue) || this.options[0];
            // console.log('Selector: Default selection changed to '+newValue + ', updated value = '+this.optionSelected.value);
        },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside); // Add a click event listener to the document
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside); // Remove the click event listener when the component is destroyed
    },
  };
  </script>
  
  <style scoped>
  .Selector {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 2rem;
    border-radius: var(--button-border-radius);
  }

  .Selector.font-width {
    width: 7.5rem;
  }
  .Selector.text-width {
    width: 100%;
  }
  .Selector.percentage-width {
    width: 4.25rem;
  }
  .Selector.icon-width {
    width: 3rem;
  }
  .Selector.outline {
    border: solid var(--color-card-border) var(--border-thickness-normal);
  }
  
  .DropdownHeader {
    cursor: pointer;
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    height: 2rem;
    width: 100%;
    min-width: 3rem;
  }
  .DropdownHeader.hover {
    color: var(--color-input-normal);
    background-color: var(--color-background-primary);
    border-radius: var(--button-border-radius);
  }
  .DropdownHeader.selected {
    color: var(--color-input-selected);
    background-color: var(--color-outline-hover);
    border-radius: var(--button-border-radius);
  }
  .DropdownHeader.font {
    justify-content: space-around;
  }
  .DropdownHeader.text {
    justify-content: space-between;
  }
  .DropdownHeader.disabled {
    cursor: not-allowed;
    color: var(--color-outline-disabled);
  }

  .HeaderIconContainer {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.125rem;
  }

  .HeaderTextContainer{
    display: flex;
    align-items: center;
    overflow: hidden;
    text-align: left;
    padding: 0 0.125rem;
}

.HeaderRowText {
    line-height: 1.5rem;
    font-size: var(--size-normal);
}
.HeaderRowText.font-width {
    width: 5.5rem;
}
.HeaderRowText.text-width {
    width: 100%;
}
.HeaderRowText.number-width {
    width: fit-content;
}

.DropdwonArrowContainer {
  width: 0.5rem;
  height: 0.5rem;
  display: flex;
  align-items: center;
}
  
.tooltip {
  position: absolute;
  top: 2.25rem;
  width: fit-content;
  background-color: var(--color-input-secondary);
  color: var(--color-background-canvas);
  padding: 0 0.25rem;
  border-radius: var(--button-border-radius);
  font-size: var(--size-x-small);
  white-space: nowrap;
  z-index: var(--z-index-tooltip);
}

.tooltip.left {
  align-self: flex-start;
}
.tooltip.right {
  right: 0;
}
.tooltip.visible {
  display: block;
}

.tooltip.hidden {
  display: none;
}
  .OptionsDropdown {
    position: absolute;
    top: 2.25rem;
    width: fit-content;
    min-width: 3rem;
    align-self: flex-start;
    padding: 0.5rem 0;
    max-width: max(25rem, 100%);
    border: var(--border-thickness-normal) solid var(--color-card-border);
    background-color: var(--color-background-canvas);
    border-radius: var(--card-border-radius);
    z-index: var(--z-index-tooltip);
    box-shadow: var(--shadow-elevation-one);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .OptionsDropdown.visible {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  .OptionsDropdown.hidden {
    display: none;
  }
  
  .OptionsRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    gap: 0.5rem;
    width: 100%;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    /* border-radius: var(--button-border-radius); */
    color: var(--gray-700);
  }
  .OptionsRow:hover {
    background-color: var(--color-background-primary);
    color: var(--color-input-normal);
    cursor: pointer;
  }

  .OptionsRow.selected {
    border-left: 0.25rem solid var(--color-input-normal);
  }

  .OptionsRowVerticalGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* max-width: 20rem; */
    max-width: 100%;
    gap: 0.25rem;
  }

  .OptionsRowDescription {
    font-size: var(--size-small); 
    font-weight: var(--weight-normal); ; 
    line-height: var(--size-small);
    color: var(--gray-500);
  }
  .OptionsRowText {
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .OptionsRowIconContainer {
    display: flex;
    align-items: center;
  }
  .arrow-down {
    margin-left: auto;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity var(--transition-time);
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  