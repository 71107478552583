<template>
    <template v-if="visible">
      <div class="_resume_section" :data-section-id="sectionKey">
        <div class="_resume_title" :class="{
          '_less_margin': template === 'SanFrancisco' || template === 'NewYork' ,
        }">
          <label>{{ section.name }}</label>
          <!-- Only recommendations -->
          <feedback
            v-if="showCommentIndicators"
            :recommendations="sectionLevelRecommendations"
            :on-margin="false"
          />
        </div>
        <hr v-if="template !== 'Berlin'"/>
        <div class="_center_column">
          <template v-if="section.educations?.length>0"
            v-for="(education, index) in section.educations">
                <div class="_card_row" :education-id="index">
                    <div v-if="isSectionItemVisible(sectionKey, index) || !section.hide">
                        <div class="_section_subtitle"> {{ education.school }}</div>
                        <div class="_header_with_date">
                            <div class="_section_title">{{ education.degree }}</div>
                            <div class="_resume_date" v-if="getDateText(education.school_start_date, education.school_end_date) &&
                                  ( !education.hide_date || !section.hide_date )">
                                {{ getDateText(education.school_start_date, education.school_end_date) }}
                            </div>
                        </div>
                        <div v-if="education.gpa">
                            <div class="_resume_paragraph">{{ education.gpa }} </div>
                        </div>
                    </div>
                </div>
          </template>
        </div>  
      </div>
      <hr v-if="template === 'Berlin' && hasNextSection"/>
    </template>
  </template>
  
  <script>
  import Feedback from './Feedback.vue'
  import { labelHelpers } from '../../mixins/labelHelpers'
  import { layoutHelpers } from '../../mixins/layoutHelpers'
  
  export default {
    name: 'ResumeEducation',
    components: {
      Feedback
    },
    mixins: [labelHelpers, layoutHelpers],
    props: {
      template: {
        type: String,
        required: true
      },
      section: {
        type: Object,
        required: true,
        validator: (obj) => {
          const requiredProps = ['name', 'educations']
          return requiredProps.every(prop => prop in obj)
        }
      },
      visible: {
        type: Boolean,
        default: false
      },
      pageLayout: {
        type: String,
        default: 'Center',
        validator: value => ['Left', 'Right', 'Center'].includes(value)
      },
      showCommentIndicators: {
        type: Boolean,
        default: false
      },
      hasRecommendations: {
        type: Boolean,
        required: true
      },
      recommendations: { //array of recommendations
        type: Array,
        required: true
      },
      isSectionItemVisible: {
        type: Function,
        required: true,
      },
      hasNextSection: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        sectionKey: 'educational_qualifications',
      }
    }
  }
  </script>