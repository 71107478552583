<template>
  <div class="_labelTop" v-if="showIcon && ( template === 'SanFrancisco' || template === 'Berlin')">
    <template v-if="template == 'SanFrancisco'">
      <svg width="60" height="142" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0h60v142l-30-20-30 20V0Z" fill="currentcolor"></path>
      </svg>
    </template>
    <template v-else-if="template == 'Berlin'">
      <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M17 0h15v15H17zM34 17h15v15H34z"></path>
        <path opacity=".2" fill="currentColor" d="M34 34h15v15H34zM17 17h15v15H17zM0 0h15v15H0z"></path>
        <path fill="currentColor" d="M34 0h15v15H34z"></path>
      </svg>
    </template>
  </div>
  <div :class="{
    '_topbar_background': pageLayout === 'Center',
    '_sidebar_background': pageLayout !== 'Center',
    }"
    v-else-if="template == 'NewYork'"
  ></div>
</template>

<script>

export default {
  name: 'PageHeader',
  props: {
    template: { 
      type: String,
      validator: value => ['London','NewYork','SanFrancisco','Berlin','Tokyo'].includes(value)
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    pageLayout: {
      type: String,
      default: 'Center',
    },
  },
}
</script>