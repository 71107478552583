<template>
    <template v-if="visible">
      <div class="_resume_section" :data-section-id="sectionKey">
        <!-- Date Section -->
        <div class="_resume_title" :class="{
          '_less_margin': template === 'SanFrancisco' || template === 'NewYork' ,
        }">
          <label>{{ section.name }}</label>
          <!-- Section Recommendations Only-->
          <feedback
            v-if="showCommentIndicators"
            :recommendations="sectionLevelRecommendations"
            :on-margin="false"
          />
        </div>
        <hr v-if="template !== 'Berlin'"/>
        <div class="_section_card_more_margin">
          <ul class="_custom_list">
              <template v-for="(item, index) in section.items_array">
                  <li  v-if="isSectionItemVisible(sectionKey, index) || !section.hide ">
                    <div class="_card_row" :item-id="index">
                      <div class="_header_with_date">
                          <div class="_resume_paragraph">{{ item.item_name }} </div>
                          <div class="_resume_date" v-if="item.date!==''">{{ item.date }}</div>
                      </div>
                    </div>
                  </li>
              </template>                                            
          </ul> 
        </div>

      </div>
      <hr v-if="template === 'Berlin' && hasNextSection"/>
    </template>
  </template>
  
  <script>
  import Feedback from './Feedback.vue'
  import { labelHelpers } from '../../mixins/labelHelpers'
  import { layoutHelpers } from '../../mixins/layoutHelpers'
  
  export default {
    name: 'DateSection',
    components: {
      Feedback
    },
    mixins: [labelHelpers, layoutHelpers],
    props: {
      template: {
        type: String,
        required: true
      },
      section: {
        type: Object,
        required: true,
        validator: (obj) => {
          const requiredProps = ['name']
          return requiredProps.every(prop => prop in obj)
        }
      },
      sectionKey: {
        type: String,
        required: true
      },
      visible: {
        type: Boolean,
        default: false
      },
      pageLayout: {
        type: String,
        default: 'Center',
        validator: value => ['Left', 'Right', 'Center'].includes(value)
      },
      showCommentIndicators: {
        type: Boolean,
        default: true
      },
      hasRecommendations: {
        type: Boolean,
        required: true
      },
      recommendations: { //array of recommendations
        type: Array,
        required: true
      },
      isSectionItemVisible: {
        type: Function,
        required: true
      },
      hasNextSection: {
        type: Boolean,
        default: false
      },
    }
  }
  </script>