<template>
    <template v-if="visible">
      <div class="_resume_section" :data-section-id="sectionKey">
        <div class="_resume_title" :class="{
          '_less_margin': template === 'SanFrancisco' || template === 'NewYork' ,
        }">
          <label>{{ section.name }}</label>
          <!-- Project Section Recommendations -->
          <feedback
            v-if="showCommentIndicators"
            :recommendations="sectionLevelRecommendations"
            :on-margin="false"
          />
        </div>
        <hr v-if="template !== 'Berlin'"/>
        <div class="_center_column">
          <template v-for="(project, index) in section.projects">
              <div class="_card_row" :project-id="index" 
                v-if="isSectionItemVisible(sectionKey, index) || !section.hide ">
                  <!-- Project specific comment -->
                  <feedback
                    v-if="showCommentIndicators && sectionHasComments(sectionKey,index)"
                    :comments="projectComments(index)"
                    :on-margin="true"
                    :position="pageLayout=='Left'? 'right' : 'left'"
                  />
                  <div class="_section_title"> {{ project.title }}
                  </div>
                  <ul class="_custom_list">
                      <template v-for="(bullet) in project.items_array" >
                          <li v-if="bullet.trim() !==''"><div> {{ bullet }} </div></li>                                  
                      </template>
                  </ul>
                  
              </div>
          </template>
        </div> 
      </div>
      <hr v-if="template === 'Berlin' && hasNextSection"/>
    </template>
  </template>
  
  <script>
  import Feedback from './Feedback.vue'
  import { labelHelpers } from '../../mixins/labelHelpers'
  import { layoutHelpers } from '../../mixins/layoutHelpers'
  
  export default {
    name: 'ResumeProjects',
    components: {
      Feedback
    },
    mixins: [labelHelpers, layoutHelpers],
    props: {
      template: {
        type: String,
        required: true
      },
      section: {
        type: Object,
        required: true,
        validator: (obj) => {
          const requiredProps = ['name', 'projects']
          return requiredProps.every(prop => prop in obj)
        }
      },
      visible: {
        type: Boolean,
        default: false
      },
      pageLayout: {
        type: String,
        default: 'Center',
        validator: value => ['Left', 'Right', 'Center'].includes(value)
      },
      showCommentIndicators: {
        type: Boolean,
        default: false
      },
      comments: { //array of comments
        type: Array,
        required: true
      },
      hasRecommendations: {
        type: Boolean,
        required: true
      },
      recommendations: { //array of recommendations
        type: Array,
        required: true
      },
      isSectionItemVisible: {
        type: Function,
        required: true,
      },
      sectionHasComments: {
        type: Function,
        required: true,
      },
      hasNextSection: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        sectionKey: 'projects_and_assignments',
      }
    },
    computed: {
      projectComments() {
        return (index) => {
          if (!this.sectionHasComments(this.sectionKey,index)) return [];
          const id = this.section.projects[index].id;
          return [ 
            { 
              id: id,
              label: this.getCommentIndex(id) 
            }
          ];
        }
      },
    }
  }
  </script>