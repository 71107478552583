import eventBus from '../../utils/event-bus';
import { ToastEvents } from '../../utils/toast-events';

export function toastHandler() {
    const showToast = (toastType, toastMessage) => {
        const toastId = Date.now();
        const newToast = {
            id: toastId,
            type: toastType,
            message: toastMessage,
            visible: true,
        };
        eventBus.emit(ToastEvents.ADDED, newToast);
        return toastId;
    };

    const removeToast = (toastId) => {
        if (toastId && toastId !== '') {
            eventBus.emit(ToastEvents.REMOVED, toastId);
        }
    };

    return {
        showToast,
        removeToast
    };
}