<template>
    
      <!-- Tabs: Job Match Preview -->
      <div class="TabMenu">
        <tab-button componentId="job_preview" 
          :hasRightSibling=true 
          :isSelected="current_tab === 'job_preview'" 
          :isDisabled="!showJobPreview" 
          :label=BUTTON_LABEL_JOB_PREVIEW 
          @tabClick="handleClick"></tab-button>
        <tab-button componentId="match_preview" 
          :hasRightSibling=false :isSelected="current_tab === 'match_preview'" 
          :isDisabled="!showMatchPreview"  
          :label=BUTTON_LABEL_JOB_MATCH 
          @tabClick="handleClick"></tab-button>
      </div>
      <!-- Preview: Tabs Content -->
      <div class="JobPanelContainer" :class="{ 'skinnyHeight' : parent_screen === 'history'}">
        <div class="PreviewScaffold" :class="{ 'CardShadow': showJobPreview || showMatchPreview }"
          ref="tabContainer">
          <!-- Empty State -->
          <div :class="['PreviewEmptyState', jobPreviewClass]">
            <Icon iconName="rocket"/>
            <div class="TextDescription TextAlignCenter">{{ EMPTY_STATE_MESSAGE_LINE1 }}</div>
            <div class="TextDescription TextAlignCenter">{{ EMPTY_STATE_MESSAGE_LINE2 }}</div>
          </div>
          <!-- Job Preview -->
          <div :class="['PrintPreview', jobPreviewClass]">
              <div class="HorizontalGroup MarginBottom" v-show="job_title">
                <div class="TextHeading2 FontBold">{{ job_title }}</div>
              </div>      
              <div class="HorizontalGroup MarginBottom" v-show="company_name">
                <div class="TextParagraph FontBold">Company:</div>
                <div class="TextDescription">{{ company_name }}</div>
              </div>
              <div class="HorizontalGroup MarginBottom" v-show="job_location">
                <div class="TextParagraph FontBold">Location:</div>
                <div class="TextDescription">{{ job_location }}</div>
              </div>
              <div class="TextParagraph FontBold" v-show="job_description">Job Description</div>
              <pre class="TextPreview">{{ job_description }}</pre>
            </div>
          <!-- Match Preview -->
          <div :class="['PrintPreview', matchPreviewClass]">
            <div v-if="hasMandatoryRequirements">
              <!-- Match Summary Card -->
              <div class="MatchCard">
                <div class="CardHeaderRow NoHorizontalPadding ExtraBottomMargin">
                  <div class="TextHeading1" ref="evaluationSummary">{{LABEL_MATCH_SUMMARY_TITLE}}</div>
                </div>
                <div class="MatchSummaryRow MarginBottom">
                  <div class="TextDescription">{{ MESSAGE_EVALUATION_SUMMARY }}</div>
                </div>
                <!-- ATS Match -->
                <div class="RegularCardSection">
                  <div class="MatchSummaryRow">
                    <div class="MatchIndicator">
                      <bar :color="scoreToColor(combinedATSScore)" 
                      thickness="0.5rem" 
                      :percentage=combinedATSScore ></bar>
                    </div>
                    <div class="MatchText BoldText">{{ LABEL_ATS_SCORE }}</div>
                  </div>  
                  <div class="MatchSummaryRow">
                    <div class="ScoreValue" :style="{color: scoreToColor(combinedATSScore)}">{{ scoreToText(combinedATSScore) }}</div>
                    <div class="TextDescription"> {{messageATSMatch}}</div>
                  </div>
                </div>
                <!-- Qualitative Match -->
                <div class="RegularCardSection">
                  <div class="MatchSummaryRow">
                    <div class="MatchIndicator">
                      <bar :color="scoreToColor(application_json.match.overall_match_score)" 
                      thickness="0.5rem" 
                      :percentage=application_json.match.overall_match_score ></bar>
                    </div>
                    <div class="MatchText BoldText">{{ LABEL_MATCH_SCORE }}</div>
                  </div>  
                  <div class="MatchSummaryRow">
                    <div class="ScoreValue" :style="{color: scoreToColor(application_json.match.overall_match_score)}">{{ scoreToText(application_json.match.overall_match_score) }}</div>
                    <div class="TextDescription"> {{messageQualitativeMatch}}</div>
                  </div>
                </div>
                <!-- Keyword Boost -->
                <div class="RegularCardSection">
                  <div class="MatchSummaryRow">
                    <div class="MatchIndicator">
                      <bar :color="scoreToColor(keywordBoost.score)" 
                      thickness="0.5rem" 
                      :percentage=keywordBoost.score ></bar>
                    </div>
                    <div class="MatchText BoldText">{{LABEL_BOOST_SCORE}}</div>
                  </div>  
                  <div class="MatchSummaryRow">
                    <div class="ScoreValue" :style="{color: scoreToColor(keywordBoost.score)}">{{ scoreToText(keywordBoost.score) }}</div>                   
                    <div class="TextDescription"> {{keywordBoost.message}}</div>
                  </div>
                </div>
                <!-- FAQs -->
                  <div class="QuestionsContainer MarginTop" @click="scrollToScoreDetails">{{ QUESTION_SCORE_BREAKDOWN }}
                  <div class="QuestionsIcon"><Icon iconName="right-arrow"/></div>
                  </div>
                  <div class="QuestionsContainer" @click="scrollToJobRequirements">{{ QUESTION_REQUIREMENTS_MATCH }}
                  <div class="QuestionsIcon"><Icon iconName="right-arrow"/></div>
                  </div>
                  <div class="QuestionsContainer" @click="scrollToKeywords">{{ QUESTION_KEYWORDS_MATCH }}
                  <div class="QuestionsIcon"><Icon iconName="right-arrow"/></div>
                  </div>
              </div>
              <!-- Score Details Card -->
              <div class="MatchCard" ref="scoreDetails">
                <div class="CardHeaderRow NoHorizontalPadding ExtraBottomMargin">
                  <div class="TextHeading1" >{{LABEL_SCORE_DETAILS}}</div>
                </div>
                <div class="MatchSummaryRow MarginBottom">
                  <div class="TextDescription">{{ MESSAGE_SCORE_DETAILS }}</div>
                </div>
                <!-- ATS Score Breakdown -->
                <div class="RegularCardSection VerticalSpacing">
                  <div class="MatchSummaryRow">
                    <div class="MatchText BoldText InlineText">{{ LABEL_ATS_SCORE_DETAILS }}
                      <button class="TertiaryButton FontSmall" @click="scrollToKeywords">{{ LABEL_DETAILS }}</button>
                    </div>
                  </div>
                  <div class="MatchSummaryRow MarginBottom">
                    <div class="TextDescription">{{ MESSAGE_ATS_ROUND }}</div>
                  </div>
                  <!-- Readability -->
                  <div class="MatchSummaryRow JustifyCenter" v-if="'resume_readability' in application_json.match">
                    <div class="InputProgressScaffold">
                      <div class="MatchTextSmall AlignRight ">{{ LABEL_RESUME_READABILITY }}</div>
                    </div>
                    <div class="InputProgressScaffold">
                      <div class="FullScoreIndicator">
                        <bar :color="scoreToColor(application_json.match.resume_readability)" 
                        thickness="0.5rem" 
                        :percentage=application_json.match.resume_readability ></bar>
                      </div>
                    </div>
                    <div class="ProgressIndicator">
                      <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.resume_readability)}"> {{ Math.round(application_json.match.resume_readability)}}</div>
                    </div>
                  </div> 
                  <!-- Keywords -->
                  <div class="MatchSummaryRow JustifyCenter" v-if="'keyword_frequency_score' in application_json.match">
                    <div class="InputProgressScaffold">
                      <div class="MatchTextSmall AlignRight ">{{ LABEL_KEYWORD_SCORE }}</div>
                    </div>
                    <div class="InputProgressScaffold">
                      <div class="FullScoreIndicator">
                        <bar :color="scoreToColor(application_json.match.keyword_frequency_score)" 
                        thickness="0.5rem" 
                        :percentage=application_json.match.keyword_frequency_score ></bar>
                      </div>
                    </div>
                    <div class="ProgressIndicator">
                      <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.keyword_frequency_score)}"> {{ Math.round(application_json.match.keyword_frequency_score)}}</div>
                    </div>
                  </div> 
                </div>
                <!-- Qualitative Match Breakdown -->
                <div class="RegularCardSection VerticalSpacing MarginTop">
                  <div class="MatchSummaryRow">
                    <div class="MatchText BoldText InlineText">{{ LABEL_MATCH_SCORE_DETAILS }}
                      <button class="TertiaryButton FontSmall" @click="scrollToJobRequirements">{{ LABEL_DETAILS }}</button>
                    </div>
                  </div>
                  <div class="MatchSummaryRow MarginBottom">
                    <div class="TextDescription">{{ MESSAGE_RECRUITER_ROUND }}</div>
                  </div>
                  <!-- Experience -->
                  <div class="MatchSummaryRow JustifyCenter" v-if="'years_of_experience' in application_json.match">
                    <div class="InputProgressScaffold">
                      <div class="MatchTextSmall AlignRight ">{{ LABEL_EXPERIENCE_SCORE }}</div>
                    </div>
                    <div class="InputProgressScaffold">
                      <div class="FullScoreIndicator">
                        <bar :color="scoreToColor(application_json.match.years_of_experience)" 
                        thickness="0.5rem" 
                        :percentage=application_json.match.years_of_experience ></bar>
                      </div>
                    </div>
                    <div class="ProgressIndicator">
                      <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.years_of_experience)}"> {{ Math.round(application_json.match.years_of_experience)}}</div>
                    </div>
                  </div> 
                  <!-- Hard Skills -->
                  <div class="MatchSummaryRow JustifyCenter" v-if="'hard_skills' in application_json.match">
                    <div class="InputProgressScaffold">
                      <div class="MatchTextSmall AlignRight ">{{ LABEL_HARD_SKILLS_SCORE }}</div>
                    </div>
                    <div class="InputProgressScaffold">
                      <div class="FullScoreIndicator">
                        <bar :color="scoreToColor(application_json.match.hard_skills)" 
                        thickness="0.5rem" 
                        :percentage=application_json.match.hard_skills ></bar>
                      </div>
                    </div>
                    <div class="ProgressIndicator">
                      <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.hard_skills)}"> {{ Math.round(application_json.match.hard_skills)}}</div>
                    </div>
                  </div> 
                  <!-- Education -->
                  <div class="MatchSummaryRow JustifyCenter" v-if="'educational_qualifications' in application_json.match">
                    <div class="InputProgressScaffold">
                      <div class="MatchTextSmall AlignRight ">{{ LABEL_EDUCATION_SCORE }}</div>
                    </div>
                    <div class="InputProgressScaffold">
                      <div class="FullScoreIndicator">
                        <bar :color="scoreToColor(application_json.match.educational_qualificationss)" 
                        thickness="0.5rem" 
                        :percentage=application_json.match.educational_qualifications ></bar>
                      </div>
                    </div>
                    <div class="ProgressIndicator">
                      <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.educational_qualifications)}"> {{ Math.round(application_json.match.educational_qualifications)}}</div>
                    </div>
                  </div> 
                  <!-- Soft Skills -->
                  <div class="MatchSummaryRow JustifyCenter" v-if="'soft_skills' in application_json.match">
                    <div class="InputProgressScaffold">
                      <div class="MatchTextSmall AlignRight ">{{ LABEL_SOFT_SKILLS_SCORE }}</div>
                    </div>
                    <div class="InputProgressScaffold">
                      <div class="FullScoreIndicator">
                        <bar :color="scoreToColor(application_json.match.soft_skills)" 
                        thickness="0.5rem" 
                        :percentage=application_json.match.soft_skills ></bar>
                      </div>
                    </div>
                    <div class="ProgressIndicator">
                      <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.soft_skills)}"> {{ Math.round(application_json.match.soft_skills)}}</div>
                    </div>
                  </div> 
                  <!-- Other Requirements-->
                  <div class="MatchSummaryRow JustifyCenter" v-if="'other' in application_json.match">
                    <div class="InputProgressScaffold">
                      <div class="MatchTextSmall AlignRight ">{{ LABEL_OTHER_REQUIREMENTS_SCORE }}</div>
                    </div>
                    <div class="InputProgressScaffold">
                      <div class="FullScoreIndicator">
                        <bar :color="scoreToColor(application_json.match.other)" 
                        thickness="0.5rem" 
                        :percentage=application_json.match.other ></bar>
                      </div>
                    </div>
                    <div class="ProgressIndicator">
                      <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.other)}"> {{ Math.round(application_json.match.other)}}</div>
                    </div>
                  </div> 
                </div>
                <div class="MatchSummaryRow JustifyCenter MarginTop">
                  <div class="QuestionsIcon">
                    <themed-button  componentId="scroll_to_top" button-type="icon"  
                    icon="to-top" :tooltip-text="TOOLTIP_SCROLL_TOP" @buttonClick="scrollToEvaluationSummary"/>
                  </div>
                </div>  
              </div>
              <!-- <match-bar :match=application_json.match.overall_match ></match-bar> -->
            </div>
            <!-- Job Requirements Card-->
            <div class="MatchCard"  v-if="hasMandatoryRequirements"
              ref="jobRequirements">
              <div class="CardHeaderRow NoHorizontalPadding">
                <div class="TextHeading1" >{{LABEL_JOB_REQUIREMENTS}}</div>
              </div>
              <div class="MatchSummaryRow MarginBottom">
                <div class="TextDescription">{{ MESSAGE_JOB_REQUIREMENTS }}</div>
              </div>
              <!-- Mandatory -->
              <div class="RegularCardSection">
                <div class="MatchSummaryRow MarginBottom">
                    <div class="MatchText BoldText">{{ LABEL_MANDATORY_MATCH }}</div>
                  </div>
                <div class="HorizontalGroup">
                  <div class="ChartContainer">
                    <doughnut-chart :percentage="application_json.match.mandatory_match_score" 
                        :color="scoreToColor(application_json.match.mandatory_match_score)"></doughnut-chart>
                  </div>
                  <ul class="MatchList">
                    <li class="MatchRow" v-for="(requirement, index) in application_json.match.mandatory_requirements" :key="index">
                      <div class="HorizontalGroup AlignTop">
                        <div class="IconContainerMini MatchIconColor">
                          <Icon :iconName="requirement.candidate_match? 'circle-checklist':'circle'"/>
                        </div>
                        <div class="MatchListWidth TextDescription">
                          <span> {{ requirement.requirement }}
                            <div class="ImpactPill"> {{ scoreKeyToImpact(requirement.requirement_type) }} </div>
                          </span>
                        </div>                          
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Optional -->
              <div class="RegularCardSection" v-if="hasOptionalRequirements">
                <div class="MatchSummaryRow MarginBottom">
                  <div class="MatchText BoldText">{{ LABEL_OPTIONAL_MATCH }}</div>
                </div>
                <div class="HorizontalGroup">
                  <div class="ChartContainer">
                    <doughnut-chart :percentage="application_json.match.optional_match_score" 
                        :color="scoreToColor(application_json.match.optional_match_score)"></doughnut-chart>
                  </div>
                  <ul class="MatchList">
                    <li class="MatchRow" v-for="(requirement, index) in application_json.match.optional_requirements" :key="index">
                      <div class="HorizontalGroup AlignTop">
                        <div class="IconContainerMini MatchIconColor">
                          <Icon :iconName="requirement.candidate_match? 'circle-checklist':'circle'"/>
                        </div>
                        <div class="TextDescription MatchListWidth"> 
                          <span> {{ requirement.requirement }}
                            <div class="ImpactPill"> {{ LABEL_IMPACT_LOW }} </div>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Scroll to top -->
              <div class="MatchSummaryRow JustifyCenter">
                <div class="QuestionsIcon">
                  <themed-button  componentId="scroll_to_top" button-type="icon"  
                  icon="to-top" :tooltip-text="TOOLTIP_SCROLL_TOP" @buttonClick="scrollToEvaluationSummary"/>
                </div>
              </div> 
            </div>
            <!-- Keywords Card -->
            <div class="MatchCard" v-if="application_json && application_json.keywords"
              ref="keywords">
              <div class="CardHeaderRow NoHorizontalPadding">
                <div class="TextHeading1" >{{LABEL_KEYWORDS}}</div>
              </div>
              <div class="MatchSummaryRow MarginBottom">
                <div class="TextDescription">{{ MESSAGE_KEYWORDS }}</div>
              </div>
              <span class="KeywordsContainer">
                <!-- show a keyword is present if the presence score > 0.02 -->
                <div class="ImpactPill KeywordPillMargin TextTruncate" :class="{'KeywordPresent': presence > KEYWORD_PRESENCE_THRESHOLD}"
                  v-for="(presence, keyword) in application_json.keywords" :key="keyword"> {{ keyword }}</div>
              </span>
              <!-- Scroll to top -->
              <div class="MatchSummaryRow JustifyCenter">
                <div class="QuestionsIcon">
                  <themed-button  componentId="scroll_to_top" button-type="icon"  
                  icon="to-top" :tooltip-text="TOOLTIP_SCROLL_TOP" @buttonClick="scrollToEvaluationSummary"/>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    
  </template>
  
  <script>
  import { scoreUtils } from '../mixins/scoreUtils';
  import Bar from '../ui_controls/Bar.vue';
  import Icon from '../ui_controls/Icon.vue';
  import TabButton from '../ui_controls/TabButton.vue';
  import ThemedButton from '../ui_controls/ThemedButton.vue';
  import DoughnutChart from '../ui_controls/DoughnutChart.vue';
  
  export default {
    name: 'JobMatchPreview',
    emits: ['buttonClick'],
    components: {
      Bar,
      Icon,
      TabButton,
      ThemedButton,
      DoughnutChart
    },
  
    mixins: [scoreUtils],
  
    props: {
      job_title: {
          type: String,
      },
      company_name: {
          type: String,
      },
      job_location: {
          type: String,
      },
      job_description: {
          type: String,
      },
      application_json: {
          type: Object,
      },
      current_tab: {
          type: String,
          // default: 'job_preview',
      },
      parent_screen: {
        type: String,
        enum: ['home', 'history'],
        default: 'home',
      }
    },
  
    data() {
      return {
        previous_tab: 'job_preview', //the previous tab selection
        // Thresholds
        KEYWORD_PRESENCE_THRESHOLD: 0.0005, //only if a keyword has a score >= this value, will it be considered to be present in the resume
        // Button Labels
        BUTTON_LABEL_JOB_PREVIEW: 'Job Details',
        BUTTON_LABEL_JOB_MATCH: 'Resume Match',
        // Other Labels
        LABEL_DETAILS: 'Details →',
        // Match Summary Labels
        LABEL_MATCH_SUMMARY_TITLE: 'Resume Evaluation',
        LABEL_ATS_SCORE: 'Resume Selection Chances',
        LABEL_MATCH_SCORE: 'Recruiter Selection Chances',
        LABEL_BOOST_SCORE: 'Impact Of Tailoring Resume',
        // Score Details Labels
        LABEL_SCORE_DETAILS: 'Score Details',
        LABEL_ATS_SCORE_DETAILS: 'Round 1: ATS Screening',
        LABEL_MATCH_SCORE_DETAILS: 'Round 2: Recruiter Screening',
        LABEL_KEYWORD_SCORE: 'Job Specific Keywords',
        LABEL_EDUCATION_SCORE: 'Educational Qualifications',
        LABEL_EXPERIENCE_SCORE: 'Work Experience',
        LABEL_HARD_SKILLS_SCORE: 'Hard Skills',
        LABEL_SOFT_SKILLS_SCORE: 'Soft Skills',
        LABEL_OTHER_REQUIREMENTS_SCORE: 'Other Requirements',
        LABEL_RESUME_READABILITY: 'Resume Readability',
        LABEL_JOB_REQUIREMENTS:'Job Requirements Match',
        LABEL_KEYWORDS:'Job Keywords Match',
        LABEL_MANDATORY_MATCH: 'Must Have Requirements',
        LABEL_OPTIONAL_MATCH: 'Nice-to-Have Requirements',
        LABEL_IMPACT_HIGH: 'High Impact',
        LABEL_IMPACT_MEDIUM: 'Medium Impact',
        LABEL_IMPACT_LOW: 'Low Impact',
        // Messages
        QUESTION_SCORE_BREAKDOWN: 'How did you calculate my ATS and recruiter selection chances?',
        QUESTION_REQUIREMENTS_MATCH: 'How well does my resume match the job requirements?',
        QUESTION_KEYWORDS_MATCH: 'Which job-specific keywords are present in my resume?',
        MESSAGE_ATS_LOW:'There\'s a high chance an Applicant Tracking System (ATS) might reject your resume',
        MESSAGE_ATS_MEDIUM:'There\'s a moderate risk of an Applicant Tracking System (ATS) rejecting your resume',
        MESSAGE_ATS_HIGH:'Your resume has the necessary keywords and phrases to pass the Applicant Tracking System (ATS) screening',
        MESSAGE_RECRUITER_ROUND:'After clearing the ATS, your resume reaches human reviewers. Here\'s how recruiters or hiring managers might evaluate your application:',
        MESSAGE_ATS_ROUND:'An Applicant Tracking System (ATS) evaluates your resume by scanning its contents and comparing them to job-specific keywords. Here\'s how your resume scored on key dimensions:',
        MESSAGE_MATCH_LOW:'Based on your current profile, this job might not be the best fit for you',
        MESSAGE_MATCH_MEDIUM:'Your profile shows a moderate potential for this job',
        MESSAGE_MATCH_HIGH:'Your profile is a strong match for this job',
        MESSAGE_MATCH_V_HIGH:'Your profile is an excellent fit for this job',
        MESSAGE_HIGH_BOOST:'Tailoring your resume with job specific keywords and phrases can greatly improve your chances of getting past the ATS screening',
        MESSAGE_MEDIUM_BOOST:'Tailoring your resume with job specific keywords and phrases can further increase your chances of getting past the ATS screening',
        MESSAGE_LOW_BOOST:'Tailoring your resume with job specific keywords and phrases can slightly increase your chances of getting past the ATS screening',
        MESSAGE_NO_BOOST:'Tailoring your resume with job specific keywords and phrases wouldn\'t have much of an impact on your chances of getting past the ATS screening',
        MESSAGE_EVALUATION_SUMMARY:'We reviewed your resume against the job details you provided and found the following:',
        MESSAGE_SCORE_DETAILS:'When you apply for a job, your resume must clear two hurdles before you can expect an interview: first the ATS screening, then the recruiter screening. Understanding these stages can help you optimize your application.',
        MESSAGE_JOB_REQUIREMENTS:'Here are the job requirements and their relative importance to a recruiter. The ones found in your resume are indicated with a checkmark.',
        MESSAGE_KEYWORDS:'Here are the top keywords that an ATS would likely scan for in your resume. The ones found in your resume are highlighted in green.',
        // Tooltips
        TOOLTIP_SCROLL_TOP: 'Scroll to Top',
        // Empty State Messages
        EMPTY_STATE_MESSAGE_LINE1: 'Ready to launch your career?',
        EMPTY_STATE_MESSAGE_LINE2: 'Just provide the position you are interested in and your resume to get started',
      };
    },
    created () {
      console.log("JobMatchPreview created with current tab: "+this.current_tab);  
    },
    computed: {
      showJobPreview() {
        return (this.job_description!='');
      },
      showMatchPreview() { //We use the double-negation operator !! to convert the result of the condition to a boolean value
        return !!(this.job_title!='' && this.hasMandatoryRequirements); //the job position should have been entered
      },
      //Returns the computed classes for the job tab
      jobPreviewClass() {
        let classList =[];
        if(this.showJobPreview ){
          // If job preview can be shown
          if (this.current_tab=='job_preview') {
            classList=['jobFull'];
          } else {
            classList=['jobFull', 'swipeLeft'];
          }
        } else {
          //If job preview can't be shown
          if (this.previous_tab=='job_preview') {
            classList=['jobEmpty'];
          } else {
            classList=['jobEmpty', 'swipeLeft'];
          }
        } 
        return classList;
      },
      //Returns the computed classes for the job tab
      matchPreviewClass() {
        let classList =[];
        if(this.showMatchPreview){
          // If match preview can be shown
          if (this.current_tab=='match_preview') {
            classList=['jobFull'];
          } else {
            classList=['jobFull', 'swipeRight'];
          }
        }  else {
          //If job preview can't be shown
          if (this.previous_tab=='match_preview') {
            classList=['jobEmpty'];
          } else {
            classList=['jobEmpty', 'swipeRight'];
          }
        } 
        return classList;
      },
      // Checks if the mandatory match requirements can be shown
      hasMandatoryRequirements() {
        // Check if application_json is not blank and has match key with mandatory_requirements
        return (
          this.application_json &&
          this.application_json.match &&
          this.application_json.match.mandatory_requirements &&
          this.application_json.match.mandatory_requirements.length > 0
        );
      },
      // Checks if the optional match requirements can be shown
      hasOptionalRequirements() {
        // Check if application_json is not blank and has match key with mandatory_requirements
        return (
          this.application_json &&
          this.application_json.match &&
          this.application_json.match.optional_requirements &&
          this.application_json.match.optional_requirements.length > 0
        );
      },
      //The message to show for qualitative match against job requirements
      messageQualitativeMatch () {
        if (this.application_json.match.overall_match_score < 40) {
          return this.MESSAGE_MATCH_LOW;
        } else if (this.application_json.match.overall_match_score < 70) {
            return this.MESSAGE_MATCH_MEDIUM;
        } else if (this.application_json.match.overall_match_score < 90) {
            return this.MESSAGE_MATCH_HIGH;
        } else {
            return this.MESSAGE_MATCH_V_HIGH;
        }
      },
      //The message to show for how the resume would fare against an ATS based on keywords/ phrases
      messageATSMatch () {
        if (this.application_json.match.keyword_frequency_score < 40) {
          return this.MESSAGE_ATS_LOW;
        } else if (this.application_json.match.keyword_frequency_score < 70) {
            return this.MESSAGE_ATS_MEDIUM;
        } else {
            return this.MESSAGE_ATS_HIGH;
        }
      },
      //The message to show for how much of a boost the candidate would get from tailoring the resume
      keywordBoost () {
        const ats = this.application_json.match.keyword_frequency_score;
        const match = this.application_json.match.overall_match_score;
        if ((ats >= 40 && match < 40 ) || (ats >=70 && match <70)) {
          return {score: 20, message: this.MESSAGE_NO_BOOST};
        } else if (match >= 70 && ats < 70) {
          return {score: 80, message: this.MESSAGE_HIGH_BOOST};
        } else if (match >= 70) {
          return {score: 55, message: this.MESSAGE_MEDIUM_BOOST};
        }else {
          return {score: 40, message: this.MESSAGE_LOW_BOOST};
        }
      },
      //combined ATS score consisting of keyword frequency score and resume readability
      combinedATSScore () {
        const match = this.application_json.match;
        if (match && match.hasOwnProperty('keyword_frequency_score') && match.hasOwnProperty('resume_readability')) {
          return Math.round(match.keyword_frequency_score * match.resume_readability/100.0);
        } else if (match && match.hasOwnProperty('keyword_frequency_score')) {
          return match.keyword_frequency_score;
        } else {
          return 0;
        }
      }
    },
    watch: {
      current_tab(newTab, oldTab) {
        console.log('JobMatchPreview: Preview tab changed from '+oldTab+' to:'+ newTab);
        this.previous_tab = oldTab;
        this.$refs.tabContainer.scrollTop = 0; //scroll to top
      },
    },
    methods: {
      //scroll to focus on the evaulation summary card
      scrollToEvaluationSummary(){
        if (this.$refs.evaluationSummary) {
          console.log('Scrolling to evaluation summary');
          const container = this.$refs.evaluationSummary.closest('.PreviewScaffold'); // Limit scrolling to the 'PreviewScaffold' container
          const targetY = this.$refs.evaluationSummary.offsetTop - container.offsetTop;
          container.scrollTo({ top: targetY, behavior: 'smooth' });
        }
      },
      scrollToScoreDetails(){
        if (this.$refs.scoreDetails) {
          console.log('Scrolling to score details');
          const container = this.$refs.scoreDetails.closest('.PreviewScaffold'); // Limit scrolling to the 'PreviewScaffold' container
          const targetY = this.$refs.scoreDetails.offsetTop - container.offsetTop;
          container.scrollTo({ top: targetY, behavior: 'smooth' });
          // this.$refs.scoreDetails.scrollIntoView({ behavior: 'smooth', block: 'start' }); 
        }
      },
      //scroll to focus on the job requirements card
      scrollToJobRequirements(){
        if (this.$refs.jobRequirements) {
          console.log('Scrolling to job requirements');
          const container = this.$refs.jobRequirements.closest('.PreviewScaffold'); // Limit scrolling to the 'PreviewScaffold' container
          const targetY = this.$refs.jobRequirements.offsetTop - container.offsetTop;
          container.scrollTo({ top: targetY, behavior: 'smooth' });
          // this.$refs.jobRequirements.scrollIntoView({ behavior: 'smooth', block: 'start' }); 
        }
      },
      //scroll to focus on the keywords card
      scrollToKeywords(){
        if (this.$refs.keywords) {
          console.log('Scrolling to keywords');
          const container = this.$refs.keywords.closest('.PreviewScaffold'); // Limit scrolling to the 'PreviewScaffold' container
          const targetY = this.$refs.keywords.offsetTop - container.offsetTop;
          container.scrollTo({ top: targetY, behavior: 'smooth' });
          // this.$refs.keywords.scrollIntoView({ behavior: 'smooth', block: 'start' }); 
        }
      },
      handleClick(componentId) {
        console.log('JobMatchPreview: Clicked on tab component: ' + componentId);
        this.$emit('buttonClick', componentId);
      }
    }
  };
  </script>
  
  <style scoped>
    .JobPanelContainer {
      width: 100%;
      height: calc(100% - 8.0625rem);
      /* top: 5.0625rem; */
      position: relative;
      padding: 0.625rem 1.25rem 0 1.25rem;
      overflow-y: hidden;
      overflow-x: auto;
    }
    .skinnyHeight{
      height: calc(100% - 5.8125rem);
    }
    .PrintPreview.jobEmpty {
      opacity: 0;
    }
    .PrintPreview.jobFull {
      opacity: 1;
      padding: 2rem;
    }
    .PrintPreview.swipeLeft {
      transform: translateX(-200%);
      height: 0;
    }
    .PrintPreview.swipeRight {
      transform: translateX(200%);
      height: 0;
    }
    .PreviewEmptyState.jobEmpty {
      opacity: 1;
      padding: 2rem;
    }
    .PreviewEmptyState.jobFull {
      opacity: 0;
    }
    .QuestionsContainer {
      border: solid var(--border-thickness-normal) var(--color-input-selected);
      border-radius: var(--button-border-radius);
      color: var(--color-input-selected);
      padding: 0.5rem 1.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
    .QuestionsIcon {
      height: 1.5rem;
      width: 1.5rem;
      color: var(--color-input-selected);
    }
    .MatchSummaryRow {
      display: flex;
      align-items: center;
      padding: 0 0.625rem;
      gap: 0.625rem;
      width: 100%;
    }
    .MatchIndicator {
      width: 3.25rem;
    }
    .ScoreValue{
      font-size: var(--size-xx-small);
      text-align: center;
      min-width: 3.25rem;
      align-self: flex-start;
    }
    .FullScoreIndicator {
      width: 100%;
    }
    .ImpactPill {
      display: inline-block;
      vertical-align: middle;
      background-color: var(--color-input-disabled);
      padding: 0.125rem 0.5rem;
      border-radius: var(--pill-border-radius);
      font-size: var(--size-xx-small);
      font-weight: var(--weight-normal);
      color: var(--color-text-paragraph);
      text-align: center;
      margin-left: 0.25rem;
    }
    .KeywordsContainer {
      text-align: left;
      display: inline-block;
    }
    .KeywordPresent{
      background-color: var(--success-100);
    }
    .InputProgressScaffold {
      flex: 10;
    }
    .ProgressIndicator {
      flex: 5;
    }
    .VerticalSpacing {
      gap: 0.5rem;
    }
    .MatchText{
      text-align: left;
      font-size: var(--size-normal);
      white-space: normal;
      word-wrap: break-word;  
    }
    .MatchTextSmall{
      text-align: left;
      font-size: var(--size-x-small);
      color: var(--color-text-description);
      overflow-x: hidden;
    }
    .BoldText {
      color: var(--color-text-paragraph);
      font-weight: var(--weight-bold);
    }
    .AlignRight {
      text-align: right;
    }
    .MatchCard{
      border: 1px solid var(--gray-200);
      border-radius: var(--card-border-radius);
      padding: 1.25rem 0.625rem;
      margin: 0.625rem 0 1.25rem 0;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
    .MatchList {
      list-style-type: none; /* Remove default bullet point */
      margin-top: 0.25rem;
      width: 33.875rem;
      padding: 0 0.625rem;
      align-self: flex-start;
    }
    .MatchListWidth {
      width: calc( 100% - 2.5rem);
      display: flex;
      gap: 0.625rem;
    }
    .MatchRow{
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.5rem 0;
        width: 100%;
    }
    .MatchIconColor {
      color: var(--gray-400);
      align-self: flex-start;
    }
    .ChartContainer{
      height: 10rem;
      width: 10rem;
      align-self: flex-start;
    }
  </style>