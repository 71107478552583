export const scoreUtils = {
    methods: {
        scoreToColor(value) {
            if (value < 35) {
                return 'var(--danger-700)';
            } else if (value < 65) {
                return 'var(--warning-600)';
            } else if (value < 90) {
                return 'var(--success-500)';
            } else {
                return 'var(--success-700)';
            }
        },

        scoreToText(value) {
            if (value < 35) {
                return 'LOW';
            } else if (value < 65) {
                return 'MEDIUM';
            } else if (value < 90) {
                return 'HIGH';
            } else {
                return 'SUPERB';
            }
        },

        scoreKeyToImpact(scoreType) {
            switch (scoreType) {
                case 'soft_skills':
                    return this.LABEL_IMPACT_MEDIUM;
                default:
                    return this.LABEL_IMPACT_HIGH;
            }
        }
    }
};