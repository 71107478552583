<template>
    <!-- Icon button -->
      <!--  Button type = icon requires an icon string -->
      <!-- Button type = icon AND rotate = true will rotate rotate the icon every time the button is clicked -->
    <div v-if="buttonType =='icon' && icon" class="icon-button-container" :style="{'color': color}">
      <button class="icon-button" :class="buttonClasses" :aria-disabled=isDisabled :disabled=isDisabled role="button"
        @mouseover="handleMouseOver" @mouseout="handleMouseOut" @keydown="handleKeyDown" @click="handleClick"
        @blur="handleBlur" @focus="handleFocus">
        <icon :iconName="icon"/> 
      </button>
      <div class="tooltip" :class="tooltipClasses">{{ tooltipText }}</div> 
    </div>
    <!-- Switch (On/Off) Button -->
    <div v-else-if="buttonType =='switch'" class="icon-button-container" :style="{'color': color}">
      <button class="switch-button" :class="buttonClasses" aria-disabled=isDisabled :disabled=isDisabled role="button"
        @mouseover="handleMouseOver" @mouseout="handleMouseOut" @keydown="handleKeyDown" @click="handleClick"
        @blur="handleBlur" @focus="handleFocus">
        <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 48 48">
          <g class="nc-icon-wrapper">
            <!-- Fill Area -->
            <rect :fill="switchFillColor" height="26" rx="13" ry="13" width="44" x="2" y="11"></rect>
            <!-- Outline -->
            <rect fill="none" :stroke="switchOutlineColor" stroke-width="2" height="26" rx="13" ry="13" width="44" x="2" y="11"></rect>
            <!-- Circle -->
            <circle :class="toggleOn?'slide-right':'slide-left'" cx="15" cy="24" fill="#fff" r="11"></circle>
          </g>
        </svg>
      </button>
      <div class="tooltip" :class="tooltipClasses">{{ tooltipText }}</div> 
    </div>
    <!-- Primary button -->
    <div v-else-if="buttonType == 'primary'" class="text-button-container" :class="buttonClasses">
      <button class="primary-button" :class="buttonClasses" 
        :aria-disabled=isDisabled :disabled=isDisabled role="button"
        @mouseover="handleMouseOver" @mouseout="handleMouseOut"  @click="handleClick" @keydown="handleKeyDown"
        @blur="handleBlur" @focus="handleFocus">
        <div class="text-button-icon" v-if="icon">
          <icon :iconName="icon"/> 
        </div>
        <div>{{ label?label:'' }}</div>
      </button>
      <div class="tooltip-text-button" :class="tooltipClasses">{{ tooltipText }}</div>
    </div>
    <!-- Secondary button -->
    <div v-else-if="buttonType == 'secondary'" class="text-button-container" :class="buttonClasses"> 
      <button class="secondary-button" :class="buttonClasses" :aria-disabled=isDisabled :disabled=isDisabled role="button"
        @mouseover="handleMouseOver" @mouseout="handleMouseOut" @keydown="handleKeyDown" @click="handleClick"
        @blur="handleBlur" @focus="handleFocus">
        <div class="text-button-icon" v-if="icon">
          <icon :iconName="icon"/> 
        </div>
        <div class="TextTruncate">{{ label?label:'' }}</div>
      </button>
      <div class="tooltip-text-button" :class="tooltipClasses">{{ tooltipText }}</div>
    </div>
</template>
  
<script>
import Icon from './Icon.vue';  //Import the icon component

  export default {
    name: 'themed-button',
    emits: ['buttonClick'],
    props: {
      // ----- Common props for all buttons -----------
      componentId: {
        type: String,
        required: true,
      },
      buttonType: {
        type: String,
        default: 'secondary', //options = primary, secondary, icon, switch
        // primary and secondary buttons can have text along with icon
        // icon button can only have an icon
        // switch is a special type toggle button with a predefined icon = switch
      },
      align: {
        type: String,  //Whether the tooltip is left or right aligned
        default: 'right', //options: left or right
      },
      label: {
        type: String,  //the button label, required for buttonType= primary and secondary; ignored for other button types
      },
      tooltipText: {    //tooltip text to show
        type: String,
      },
      isDisabled: {  //whether the button is disabled
        type: Boolean,
        default: false,
      },
      // -------- Primary and Secondary button props ----------
      width: {
        type: String, //options: full (=occupies the entire available width) or fit (=fits the content within)
        default: 'fit'
      },
      // ------- Icon only button props -----------
      icon: {   //icon name, required for buttonType= icon; ignored for other button types
        type: String,
      },
      // ------- Icon + Switch button props ------------
      color:{   //Icon color, required for buttonType= icon and switch; ignored for other button types
        type: String,
        default: 'var(--gray-700)'
      },
      rotate: {
        type: Boolean, //Indicates whether the icon should be rotated every time its state is toggled
        default: false,
      },
      toggleState: {
        type: Boolean, //Indicates the toggle state of the button (switch or icon + rotate)
      },
    },
    components: {
      Icon,
    },
    data() {
      return {
        tooltipVisible: false,
        isHoverState: false,
        isFocusState: false,
        isActiveState: false,
        pressedReturnKey: false, //did the user press the return key?
        toggleOn: false, //whether the button's been toggled on
      };
    },
    computed: {
      buttonClasses() {
        // console.log('Button Class: Activated? '+this.isActiveState + ', Focused? '+this.isFocusState);
        let classList=[];
        if (this.isFocusState) classList.push('focused');
        else if (this.isActiveState) classList.push('activated');
        if ((this.buttonType === 'primary' || this.buttonType ==='secondary') && this.width=='full') classList.push ('full');
        else if ((this.buttonType === 'primary' || this.buttonType ==='secondary') && this.width=='fit') classList.push ('fit-button');
        if (this.buttonType==='icon' && this.rotate && !this.toggleOn) classList.push('rotate');
        if(this.label) classList.push('text');
        else classList.push('icon-text');
        return classList;
      },
      // Change the switch outline color
      switchOutlineColor() {
        if (this.isDisabled) return 'var(--color-outline-disabled)';
        else if (this.isHoverState || this.isFocusState || this.isActiveState) return 'var(--color-input-normal)';
        else if (this.toggleOn) return 'var(--color-input-selected)';
        else return 'currentColor';
      },
      // Change the switch fill color
      switchFillColor() {
        if (this.isDisabled) return 'var(--color-outline-disabled)';
        else if (this.toggleOn) return 'var(--color-input-selected)';
        else return 'currentColor';
      },
      tooltipClasses() {
        let classList=[];
        if (this.tooltipVisible) classList.push('visible');
        else classList.push('hidden');
        if (this.align==='left') classList.push('left');
        if (this.buttonType==='switch') classList.push('switch');
        return classList;
      },
    },
    watch: {
      // Watch for changes to the following fields and call the corresponding methods
      toggleState(newValue) {
        this.toggleOn=newValue;
      },
    },
    created() {
      this.toggleOn = this.toggleState;
      // console.log('Button id: '+this.componentId+ ', Toggle State: '+this.toggleState);
    },
    methods: {
      handleMouseOver() {
        this.isHoverState = true;
        this.pressedReturnKey=false;
        if (this.tooltipText) {
          this.tooltipVisible = true;
        }
        // console.log('Mouse over, tooltip visible: '+this.tooltipVisible);
      },
      handleMouseOut() {
        this.isHoverState = false;
        this.isFocusState = false;
        this.tooltipVisible = false;
        this.pressedReturnKey=false;
        // console.log('Mouse out, tooltip visible: '+this.tooltipVisible);
      },
      handleKeyDown(event) { //When a user enters a key on the button
        console.log('Key Down');
        if (this.isDisabled) {
          return;
        }
        // console.log('Entered key: '+event.key);
        if (event.key === 'Enter' || event.key === ' ') {
          this.pressedReturnKey=true;
        } else {
          this.pressedReturnKey=false;
        }
      },
      handleFocus() { //when a user tabs in using a keyboard
        // console.log('Focus State');
        this.isFocusState = true;
        if (this.tooltipText) {
          this.tooltipVisible = true;
        }
        // console.log('Focus, tooltip visible: '+this.tooltipVisible);
      },
      handleBlur() { //when a user tabs out using a keyboard
        this.isFocusState = false;
        this.tooltipVisible=false;
      },
      handleClick() {
        console.log('Button '+this.componentId+' clicked'+', toggleState:'+this.toggleState);
        if (this.icon) console.log('Icon present');
        this.$emit('buttonClick', this.componentId);
        // console.log( 'Return key? '+ this.pressedReturnKey+',Focus state: '+this.isFocusState + ', Active State: '+ this.isActiveState);
        this.isActiveState = true;
        if (this.rotate) {
          console.log('Toggling button state from '+this.toggleOn+' to '+!this.toggleOn);
          this.toggleOn = !this.toggleOn; //flip the toggle state
        }
        // this.isFocusState = true; //clear the focus state

        setTimeout(() => {         // Set a timeout to reset isActiveState after 200ms
          this.isActiveState = false;
          // console.log( 'After timer...Return key? '+ this.pressedReturnKey+',Focus state: '+this.isFocusState + ', Active State: '+ this.isActiveState);
        }, 100);

      },
    },
  };
</script>

<style scoped>
  /* Tooltip text */
  .text-button-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .text-button-container.full {
    width: 100%;
  }
  .text-button-container.fit-button {
    width: fit-content;
  }

  .icon-button-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .switch-button,
  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: inherit;
    border: var(--border-thickness-selected) solid  transparent;
    border-radius: 9999px;
    outline: none;
    box-shadow: 0 0 0 var(--shadow-thickness) transparent;
    background-color: transparent;
    transition: transform var(--transition-time) ease;
  }

.icon-button:hover {
  color: var(--color-input-normal);
  border: var(--border-thickness-selected) solid  var(--color-background-primary);
  outline: none;
  box-shadow: 0 0 0 var(--shadow-thickness) var(--color-background-primary);
  background-color: var(--color-background-primary);
}

.icon-button.focused {
  color: var(--color-input-selected);
  border: var(--border-thickness-selected) solid  var(--color-outline-hover);
  outline: none;
  box-shadow: 0 0 0 var(--shadow-thickness) var(--color-outline-hover);
  background-color: var(--color-outline-hover);
}

.icon-button:focus { /* disable the default button focus state */
  outline: none;
}
.icon-button.activated {
  color: var(--color-input-secondary);
  border: var(--border-thickness-selected) solid  transparent;
  outline: none;
  box-shadow: 0 0 0 var(--shadow-thickness) transparent;
  background-color: transparent;
}

.switch-button:disabled,
.icon-button:disabled {
  color: var(--color-outline-disabled);
  border: var(--border-thickness-selected) solid  transparent;
  outline: none;
  box-shadow: 0 0 0 var(--shadow-thickness) transparent;
  background-color: transparent;
  cursor: not-allowed;
}

.icon-button.rotate {
  transform: rotate(180deg);
}

.slide-right {
  transition: transform var(--transition-time) ease-in-out;
  transform: translateX(40%);
}
.slide-left {
  transition: transform var(--transition-time) ease-in-out;
}
.tooltip {
  position: relative;
  width: fit-content;
  max-height: 1.25rem;
  margin-top: 0.375rem;
  align-self: flex-end;
  background-color: var(--color-input-secondary);
  color: var(--color-background-canvas);
  padding: 0 0.25rem;
  border-radius: var(--button-border-radius);
  font-size: var(--size-x-small);
  white-space: nowrap;
  display: flex;
  align-items: center;
  z-index: var(--z-index-tooltip);
}

.tooltip.switch {
  top: -0.625rem;
}
.tooltip.left {
  align-self: flex-start;
}

.tooltip.visible {
  visibility: visible;
}

.tooltip.hidden {
  visibility: hidden;
}

.tooltip-text-button {
  position: absolute;
  width: fit-content;
  top: 120%;
  right: 0;
  left: unset;
  background-color: var(--color-input-secondary);
  color: var(--color-background-canvas);
  padding: 0 0.25rem;
  border-radius: var(--button-border-radius);
  font-size: var(--size-x-small);
  white-space: nowrap;
  z-index: var(--z-index-tooltip);
}
.tooltip-text-button.left {
  left: 0;
  right: unset;
}

.tooltip-text-button.visible {
  visibility: visible;
}

.tooltip-text-button.hidden {
  visibility: hidden;
}

/* -------- Primary Button Styles ------------*/
.primary-button {
  color: var(--color-background-canvas); /* Font color */
  font-weight: var(--weight-bold);
  background: linear-gradient(135deg, var(--color-input-normal) 0%,  var(--secondary-400) 100%);
  border: none;
  height: 100%;
  cursor: pointer;
}
.primary-button.text {
  padding: 0.25rem 0.85rem;
}
.primary-button.icon-text {
  padding: 0.25rem;
}

.primary-button.full{
  width: 100%;
}

.primary-button:hover {
  color: var(--color-background-canvas);
  background: linear-gradient(135deg, var(--color-input-selected), var(--secondary-700));
  border: none;
  box-shadow: none;
}
 
.primary-button.focused{
  color: var(--color-background-canvas);
  background: linear-gradient(135deg, var(--color-input-selected), var(--secondary-700));
  border: none;
  box-shadow: 0 0 0 var(--shadow-thickness) var(--color-outline-hover);
}

.primary-button:focus { /* disable the default button focus state */
  outline: none;
}

.primary-button.activated {
  color: var(--color-background-canvas);
  background: var(--color-input-secondary);
  border: none;
  box-shadow: none;
  outline: none;
}

.primary-button:disabled {
  cursor: default;
  color: var(--color-outline-disabled);
  background: var(--color-input-disabled);
  box-shadow: none;
  border: none;
  cursor: not-allowed;
  outline: none;
}

.text-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
}
/* -------- Secondary Button Styles ------------*/
.secondary-button {
  color: var(--color-input-selected); /* Font color */
  background-color: var(--color-background-canvas);
  border: solid var(--border-thickness-selected) var(--color-input-selected);
  box-shadow: none;
  cursor: pointer;
  outline: none;
  font-weight: var(--weight-normal);
}

.secondary-button.text {
  padding: 0.25rem 0.85rem;
}
.secondary-button.icon-text {
  padding: 0.25rem;
}
.secondary-button.full{
  width: 100%;
}
.secondary-button:hover{
  color: var(--color-input-normal);
  background-color: var(--color-background-primary);
  border: solid var(--border-thickness-selected)  var(--color-input-normal);
  box-shadow: none;
  outline: none;
}
.secondary-button.focused{
  color: var(--color-input-normal);
  background-color: var(--color-background-primary);
  border: var(--border-thickness-selected) solid var(--color-input-normal);
  box-shadow: 0 0 0 var(--shadow-thickness) var(--color-outline-hover);
  outline: none;
}
.icon-button:focus { /* disable the default button focus state */
  outline: none;
}

.secondary-button.activated {
  color: var(--color-input-secondary);
  background-color: var(--secondary-50);
  border: var(--border-thickness-selected) solid var(--secondary-50);
  box-shadow: none;
  outline: none;

}

.secondary-button:disabled {
  cursor: default;
  color: var(--color-outline-disabled);
  background-color: var(--color-input-disabled);
  box-shadow: none;
  border: solid var(--border-thickness-selected) var(--color-input-disabled);
  cursor: not-allowed;
  outline: none;
}
</style>
  